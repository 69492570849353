import React, { useEffect, useState } from "react";
import { ref, get } from "firebase/database";
import { rt } from "../../../../config/firebase";
import {
  Box,
  Typography,
  CircularProgress,
  Stack,
  Divider,
  TextField,
  FormGroup,
  FormControlLabel,
  MenuItem,
  Checkbox,
  Slider,
  useTheme,
} from "@mui/material";
import { tokens } from "../../../../styles/theme";
import { getPlanIcon } from "../Components/helper";

import { ResponsiveLine } from "@nivo/line";

const AdminComponent = () => {
  const [auditData, setAuditData] = useState([]);
  const [latest, setLatest] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedKPI, setSelectedKPI] = useState("users");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [dateRange, setDateRange] = useState([0, 0]);

  const [selectedPlans, setSelectedPlans] = useState([
    "NumberFreeUsers",
    "NumberBasicUsers",
    "NumberProUsers",
    "NumberVipUsers",
  ]);
  const [granularity, setGranularity] = useState("day");

  const dateMarks = auditData.map((_, index) => ({
    value: index, // ✅ No label field
  }));

  function getGranularityKey(timestamp, granularity) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = `${date.getMonth() + 1}`.padStart(2, "0");
    const day = `${date.getDate()}`.padStart(2, "0");

    if (granularity === "day") {
      return `${year}-${month}-${day}`;
    }

    if (granularity === "week") {
      const dayOfWeek = date.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
      const offset = dayOfWeek; // week starts on Sunday
      const weekStartDate = new Date(date);
      weekStartDate.setDate(date.getDate() - offset);
      const y = weekStartDate.getFullYear();
      const m = `${weekStartDate.getMonth() + 1}`.padStart(2, "0");
      const d = `${weekStartDate.getDate()}`.padStart(2, "0");
      return `${y}-${m}-${d}`;
    }

    if (granularity === "month") {
      return `${year}-${month}-01`; // first day of the month
    }

    return `${year}-${month}-${day}`; // default to day
  }

  const filterAuditData = (auditData, filters) => {
    const { dateRange, granularity, selectedPlans, selectedKPI } = filters;

    const start = Math.max(0, dateRange[0] || 0);
    const end = Math.min(
      auditData.length - 1,
      dateRange[1] || auditData.length - 1
    );
    const sliced = auditData.slice(start, end + 1);

    const grouped = {};
    sliced.forEach((entry) => {
      const key = getGranularityKey(entry.TimeStamp, granularity);
      if (!grouped[key]) {
        grouped[key] = [];
      }
      grouped[key].push(entry);
    });

    const aggregated = Object.entries(grouped).map(([key, entries]) => {
      const sum = (field) =>
        entries.reduce((acc, e) => acc + (e[field] || 0), 0);

      if (selectedKPI === "users") {
        const planMap = {
          NumberFreeUsers: "Free",
          NumberBasicUsers: "Basic",
          NumberProUsers: "Pro",
          NumberVipUsers: "VIP",
        };

        const result = { x: key };
        selectedPlans.forEach((planKey) => {
          const label = planMap[planKey];
          result[label] = sum(planKey);
        });

        return result;
      }

      const kpiKey =
        selectedKPI === "moves"
          ? "NumberMoves"
          : selectedKPI === "games"
          ? "NumberGames"
          : "NumberRepertoires";

      const label =
        selectedKPI === "moves"
          ? "Moves"
          : selectedKPI === "games"
          ? "Games"
          : "Repertoires";

      return {
        x: key,
        [label]: sum(kpiKey),
      };
    });

    return aggregated;
  };

  const filtered = filterAuditData(auditData, {
    dateRange,
    granularity,
    selectedPlans,
    selectedKPI,
  });

  const buildChartData = (filtered) => {
    if (selectedKPI === "users") {
      const datasets = [];

      const addSeries = (key, label) => {
        if (selectedPlans.includes(key)) {
          const series = {
            id: label,
            data: filtered.map((f) => ({
              x: f.x,
              y: f[label] ?? 0,
            })),
          };
          datasets.push(series);
        }
      };

      addSeries("NumberFreeUsers", "Free");
      addSeries("NumberBasicUsers", "Basic");
      addSeries("NumberProUsers", "Pro");
      addSeries("NumberVipUsers", "VIP");

      return datasets;
    } else {
      const label =
        selectedKPI === "moves"
          ? "Moves"
          : selectedKPI === "games"
          ? "Games"
          : "Repertoires";

      const data = [
        {
          id: label,
          data: filtered.map((f) => ({ x: f.x, y: f[label] })),
        },
      ];

      return data;
    }
  };

  const chartData = buildChartData(filtered);

  const renderChart = () => {
    if (!chartData || chartData.length === 0) {
      return (
        <Box
          sx={{
            height: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>No data available for selected filters.</Typography>
        </Box>
      );
    }

    if (selectedKPI === "users") {
      return (
        <Box sx={{ height: "500px" }}>
          <ResponsiveLine
            data={chartData}
            enableArea
            areaOpacity={0.7}
            curve="monotoneX"
            margin={{ top: 60, right: 80, bottom: 60, left: 60 }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              stacked: true,
              min: 0,
              max: "auto",
            }}
            axisBottom={{
              tickSize: 0,
              legendOffset: 40,
              legendPosition: "middle",
              format: (value) => {
                const date = new Date(value);
                return date.toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "short",
                });
              },
            }}
            axisLeft={{
              tickSize: 0,
              legendOffset: -40,
              legendPosition: "middle",
              format: (value) => (Number.isInteger(value) ? value : ""),
            }}
            colors={{ scheme: "category10" }}
            lineWidth={3}
            pointSize={8}
            pointBorderWidth={2}
            useMesh={true}
            legends={[
              {
                anchor: "bottom-right",
                direction: "column",
                translateX: 100,
                itemWidth: 80,
                itemHeight: 20,
                symbolSize: 12,
                symbolShape: "circle",
              },
            ]}
            tooltip={({ point }) => (
              <div
                style={{
                  background: colors.background[100],
                  color: colors.black[900],
                  padding: "6px 12px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              >
                <strong>{point.serieId}</strong>
                <div>Date: {point.data.xFormatted}</div>
                <div>
                  {point.serieId}: {point.data.yFormatted}
                </div>
              </div>
            )}
            theme={{
              grid: {
                line: {
                  stroke: colors.background[200],
                  strokeWidth: 1,
                },
              },
              axis: {
                ticks: {
                  text: {
                    fill: colors.black[500],
                  },
                },
              },
            }}
          />
        </Box>
      );
    }

    // Other KPIs (games, moves, repertoires)
    return (
      <Box sx={{ height: "500px" }}>
        <ResponsiveLine
          data={chartData}
          margin={{ top: 60, right: 60, bottom: 60, left: 60 }}
          xScale={{ type: "point" }}
          yScale={{ type: "linear", min: "auto", max: "auto" }}
          axisBottom={{
            tickSize: 0,
            legendOffset: 36,
            legendPosition: "middle",
            format: (value) => {
              const date = new Date(value);
              return date.toLocaleDateString("en-GB", {
                day: "numeric",
                month: "short",
              });
            },
          }}
          axisLeft={{
            legendOffset: -40,
            legendPosition: "middle",
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            format: (value) => (Number.isInteger(value) ? value : ""),
          }}
          tooltip={({ point }) => (
            <div
              style={{
                background: colors.background[100],
                color: colors.black[900],
                padding: "6px 12px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            >
              <strong>{point.serieId}</strong>
              <div>Date: {point.data.xFormatted}</div>
              <div>
                {point.serieId}: {point.data.yFormatted}
              </div>
            </div>
          )}
          colors={[colors.green[100]]}
          pointSize={8}
          pointBorderWidth={2}
          curve="monotoneX"
          useMesh={true}
          legends={[]}
          theme={{
            grid: {
              line: {
                stroke: colors.background[200],
                strokeWidth: 1,
              },
            },
            axis: {
              ticks: {
                text: {
                  fill: colors.black[500],
                },
              },
            },
          }}
        />
      </Box>
    );
  };

  useEffect(() => {
    if (auditData.length > 0) {
      setDateRange([0, auditData.length - 1]);
    }
  }, [auditData]);

  useEffect(() => {
    const fetchAuditData = async () => {
      try {
        const snapshot = await get(ref(rt, "admin/audit"));
        if (snapshot.exists()) {
          const data = snapshot.val();
          const auditArray = Object.entries(data).map(([id, record]) => ({
            id,
            ...record,
          }));

          // ✅ Find the entry with the latest TimeStamp
          const latestEntry = auditArray.reduce((latest, current) =>
            current.TimeStamp > latest.TimeStamp ? current : latest
          );

          setAuditData(auditArray);
          setLatest(latestEntry);
        } else {
          setAuditData([]);
        }
      } catch (error) {
        console.error("Failed to fetch audit data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAuditData();
  }, []);

  if (loading) {
    return (
      <Box sx={{ p: 4, textAlign: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", p: 2, gap: 2 }}>
      {/* Left Column - Sidebar Stats */}
      <Box sx={{ width: "250px" }}>
        <Stack spacing={2}>
          <Box
            onClick={() => setSelectedKPI("users")}
            sx={{
              position: "relative", // Required for pseudo-element positioning
              backgroundColor:
                selectedKPI === "users"
                  ? colors.black[200]
                  : colors.background[100],
              color: colors.black[900], // Text color
              backgroundImage: "none !important",
              cursor: "pointer",
              clipPath:
                "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
              "::before": {
                content: '""', // Required for pseudo-element
                position: "absolute", // Position relative to the parent
                top: 0, // Align with the top of the parent
                left: 0, // Align with the left of the parent
                width: "100%", // Match the width of the parent
                height: "100%", // Match the height of the parent
                backgroundColor: "transparent", // Transparent background to show the card
                border: "1px solid rgba(0, 0, 0, 0.2)", // Red outline
                clipPath:
                  "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
                zIndex: -1, // Place behind the card content
                pointerEvents: "none", // Ensure the outline does not interfere with interactions
              },
              p: 2, // Padding for the card content
            }}
          >
            <Typography variant="h7" sx={{ fontWeight: "bold" }}>
              Total Users
            </Typography>
            <Divider
              sx={{
                backgroundColor: colors.green[100],
                height: "1.5px",
                mt: 1,
                mb: 1,
              }}
            />
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, p: 1 }}>
              {getPlanIcon("free")}
              <Typography variant="body2">
                Free:{" "}
                <strong>
                  {latest?.NumberFreeUsers?.toLocaleString() || 0}
                </strong>
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, p: 1 }}>
              {getPlanIcon("basic")}
              <Typography variant="body2">
                Basic:{" "}
                <strong>
                  {latest?.NumberBasicUsers?.toLocaleString() || 0}
                </strong>
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, p: 1 }}>
              {getPlanIcon("pro")}
              <Typography variant="body2">
                Pro:{" "}
                <strong>{latest?.NumberProUsers?.toLocaleString() || 0}</strong>
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, p: 1 }}>
              {getPlanIcon("vip")}
              <Typography variant="body2">
                VIP:{" "}
                <strong>{latest?.NumberVipUsers?.toLocaleString() || 0}</strong>
              </Typography>
            </Box>

            <Divider
              sx={{
                height: "1.5px",
                mt: 1,
                mb: 1,
              }}
            />
            <Typography variant="body1" fontWeight="bold">
              Total: {latest?.NumberUser?.toLocaleString()}
            </Typography>
          </Box>

          <Box
            onClick={() => setSelectedKPI("repertoires")}
            sx={{
              position: "relative", // Required for pseudo-element positioning
              backgroundColor:
                selectedKPI === "repertoires"
                  ? colors.black[200]
                  : colors.background[100],
              color: colors.black[900], // Text color
              backgroundImage: "none !important",
              cursor: "pointer",
              clipPath:
                "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
              "::before": {
                content: '""', // Required for pseudo-element
                position: "absolute", // Position relative to the parent
                top: 0, // Align with the top of the parent
                left: 0, // Align with the left of the parent
                width: "100%", // Match the width of the parent
                height: "100%", // Match the height of the parent
                backgroundColor: "transparent", // Transparent background to show the card
                border: "1px solid rgba(0, 0, 0, 0.2)", // Red outline
                clipPath:
                  "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
                zIndex: -1, // Place behind the card content
                pointerEvents: "none", // Ensure the outline does not interfere with interactions
              },
              p: 2, // Padding for the card content
            }}
          >
            <Typography variant="h7" sx={{ fontWeight: "bold" }}>
              Total Repertoires
            </Typography>
            <Divider
              sx={{
                backgroundColor: colors.green[100],
                height: "1.5px",
                mt: 1,
                mb: 1,
              }}
            />
            <Typography variant="body1" fontWeight="bold">
              {latest?.NumberRepertoires?.toLocaleString()} /{" "}
              {(latest?.NumberRepertoires / latest?.NumberUser).toFixed(1)} per
              User
            </Typography>
          </Box>

          <Box
            onClick={() => setSelectedKPI("moves")}
            sx={{
              position: "relative", // Required for pseudo-element positioning
              backgroundColor:
                selectedKPI === "moves"
                  ? colors.black[200]
                  : colors.background[100],
              color: colors.black[900], // Text color
              backgroundImage: "none !important",
              cursor: "pointer",
              clipPath:
                "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
              "::before": {
                content: '""', // Required for pseudo-element
                position: "absolute", // Position relative to the parent
                top: 0, // Align with the top of the parent
                left: 0, // Align with the left of the parent
                width: "100%", // Match the width of the parent
                height: "100%", // Match the height of the parent
                backgroundColor: "transparent", // Transparent background to show the card
                border: "1px solid rgba(0, 0, 0, 0.2)", // Red outline
                clipPath:
                  "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
                zIndex: -1, // Place behind the card content
                pointerEvents: "none", // Ensure the outline does not interfere with interactions
              },
              p: 2, // Padding for the card content
            }}
          >
            <Typography variant="h7" sx={{ fontWeight: "bold" }}>
              Total Moves
            </Typography>
            <Divider
              sx={{
                backgroundColor: colors.green[100],
                height: "1.5px",
                mt: 1,
                mb: 1,
              }}
            />
            <Typography variant="body1" fontWeight="bold">
              {latest?.NumberMoves?.toLocaleString()} /{" "}
              {Number(
                (latest?.NumberMoves / latest?.NumberUser).toFixed(1)
              ).toLocaleString()}{" "}
              Per User
            </Typography>
          </Box>

          <Box
            onClick={() => setSelectedKPI("games")}
            sx={{
              position: "relative", // Required for pseudo-element positioning
              backgroundColor:
                selectedKPI === "games"
                  ? colors.black[200]
                  : colors.background[100],
              color: colors.black[900], // Text color
              cursor: "pointer",
              backgroundImage: "none !important",
              clipPath:
                "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
              "::before": {
                content: '""', // Required for pseudo-element
                position: "absolute", // Position relative to the parent
                top: 0, // Align with the top of the parent
                left: 0, // Align with the left of the parent
                width: "100%", // Match the width of the parent
                height: "100%", // Match the height of the parent
                backgroundColor: "transparent", // Transparent background to show the card
                border: "1px solid rgba(0, 0, 0, 0.2)", // Red outline
                clipPath:
                  "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
                zIndex: -1, // Place behind the card content
                pointerEvents: "none", // Ensure the outline does not interfere with interactions
              },
              p: 2, // Padding for the card content
            }}
          >
            <Typography variant="h7" sx={{ fontWeight: "bold" }}>
              Total Games
            </Typography>
            <Divider
              sx={{
                backgroundColor: colors.green[100],
                height: "1.5px",
                mt: 1,
                mb: 1,
              }}
            />
            <Typography variant="body1" fontWeight="bold">
              {latest?.NumberGames?.toLocaleString()} /{" "}
              {Number(
                (latest?.NumberGames / latest?.NumberUser).toFixed(1)
              ).toLocaleString()}{" "}
              Per User
            </Typography>
          </Box>
          <Box sx={{ pl: 1 }}>
            <Typography variant="caption" sx={{ color: colors.black[900] }}>
              Last Update:{" "}
              {latest?.TimeStamp
                ? new Date(latest.TimeStamp).toLocaleString("en-GB", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })
                : "N/A"}
            </Typography>
          </Box>
        </Stack>
      </Box>

      {/* Right Column - Placeholder */}
      <Box
        sx={{
          position: "relative", // Required for pseudo-element positioning
          backgroundColor: colors.background[100], // Card background color
          color: colors.black[900], // Text color
          backgroundImage: "none !important",
          clipPath:
            "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
          "::before": {
            content: '""', // Required for pseudo-element
            position: "absolute", // Position relative to the parent
            top: 0, // Align with the top of the parent
            left: 0, // Align with the left of the parent
            width: "100%", // Match the width of the parent
            height: "100%", // Match the height of the parent
            backgroundColor: "transparent", // Transparent background to show the card
            border: "1px solid rgba(0, 0, 0, 0.2)", // Red outline
            clipPath:
              "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
            zIndex: -1, // Place behind the card content
            pointerEvents: "none", // Ensure the outline does not interfere with interactions
          },
          flex: 1,
          p: 2, // Padding for the card content
        }}
      >
        <Typography variant="h7" sx={{ fontWeight: "bold" }}>
          {selectedKPI.charAt(0).toUpperCase() + selectedKPI.slice(1)} Platform
          Trends
        </Typography>
        <Divider
          sx={{
            backgroundColor: colors.green[100],
            height: "1.5px",
            mt: 1,
            mb: 1,
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 5,
            mb: 0,
            height: "50px",
            alignItems: "center",
            p: 2,
          }}
        >
          <Box
            sx={{
              width: "150px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center", // center label and slider horizontally
              justifyContent: "center",
              gap: 0.5, // reduce vertical space between label and slider
            }}
          >
            <Typography sx={{ fontSize: "12px", textAlign: "center" }}>
              Date Range
            </Typography>
            <Slider
              value={dateRange}
              onChange={(e, newValue) => setDateRange(newValue)}
              valueLabelDisplay="off"
              min={0}
              max={auditData.length - 1}
              marks={dateMarks.length <= 15 ? dateMarks : false}
              sx={{
                mt: -1.5,
                color: colors.background[200], // primary blue in light, soft blue in dark
                height: 6,
                "& .MuiSlider-track": {
                  border: "none",
                },
                "& .MuiSlider-rail": {
                  opacity: 0.3,
                  backgroundColor: colors.background[200],
                },
              }}
            />
          </Box>

          {/* Granularity Selector */}
          <TextField
            select
            label="Granularity"
            value={granularity}
            onChange={(e) => setGranularity(e.target.value)}
            size="small"
            sx={{ minWidth: 120 }}
          >
            <MenuItem value="day">Day</MenuItem>
            <MenuItem value="week">Week</MenuItem>
            <MenuItem value="month">Month</MenuItem>
          </TextField>

          {/* Plan Type Checklist (only if users KPI selected) */}
          {selectedKPI === "users" && (
            <FormGroup row>
              {[
                { key: "NumberFreeUsers", label: "Free" },
                { key: "NumberBasicUsers", label: "Basic" },
                { key: "NumberProUsers", label: "Pro" },
                { key: "NumberVipUsers", label: "VIP" },
              ].map(({ key, label }) => (
                <FormControlLabel
                  key={key}
                  control={
                    <Checkbox
                      checked={selectedPlans.includes(key)}
                      onChange={() =>
                        setSelectedPlans((prev) =>
                          prev.includes(key)
                            ? prev.filter((p) => p !== key)
                            : [...prev, key]
                        )
                      }
                      sx={{
                        color: colors.background[200], // Border color when unchecked
                        "&.Mui-checked": {
                          color: colors.green[100], // Fill color when checked
                        },
                      }}
                    />
                  }
                  label={label}
                />
              ))}
            </FormGroup>
          )}
        </Box>

        {renderChart()}
      </Box>
    </Box>
  );
};

export default AdminComponent;
