import React, { useCallback, useState, useEffect } from "react";
import { Chessboard } from "react-chessboard";
import {
  Card,
  CardContent,
  Box,
  Button,
  Typography,
  Stack,
  TextField,
  MenuItem,
  Pagination,
  useTheme,
} from "@mui/material";
import { ref, get, set } from "firebase/database";
import { rt } from "../../../../config/firebase";

import ImportUserGamesDialog from "../Components/importUserGamesDialog";

import { themeColors } from "../../../../styles/boardtheme";
import { tokens } from "../../../../styles/theme";

import {
  getTimeControlIcon,
  formatDate,
  getResultIcon,
} from "../Components/helper";

const UserGamesList = ({
  userData,
  userGames,
  setUserGames,
  selectedUserGameId,
  setSelectedUserGameId,
  setSelectedUserGameMoveId,
  selectedUserGameRepertoireId,
  selectedUserGamesRepertoireGameIds,
  settings,
  totalGames,
  setTotalGames,
}) => {
  const userId = userData?.uid || "";
  const [filteredGames, setFilteredGames] = useState([]);
  const [availableSources, setAvailableSources] = useState([]);
  const [availableTimeControls, setAvailableTimeControls] = useState([]);
  const [availableTimeControlLabel, setAvailableTimeControlLabels] = useState(
    []
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [gamesPerPage, setGamesPerPage] = useState(5);
  const [isImportUserGamesDialogOpen, setImportUserGamesDialogOpen] =
    useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [filters, setFilters] = useState({
    fromDate: "",
    toDate: "",
    eco: "",
    opening: "",
    timeControlLabel: "",
    source: "",
    timeControl: "",
  });

  const fetchGames = useCallback(async () => {
    try {
      const gamesRef = ref(rt, `users/${userId}/games`);
      const snapshot = await get(gamesRef);
      if (snapshot.exists()) {
        const fetchedGames = Object.values(snapshot.val());

        // ✅ Extract unique Source and TimeControl values
        const uniqueSources = [
          ...new Set(fetchedGames.map((g) => g.source)),
        ].filter(Boolean);
        const uniqueTimeControls = [
          ...new Set(fetchedGames.map((g) => g.timeControl)),
        ].filter(Boolean);
        const uniqueTimeControlLabels = [
          ...new Set(fetchedGames.map((g) => g.timeControlLabel)),
        ].filter(Boolean);

        setAvailableSources(uniqueSources);
        setAvailableTimeControls(uniqueTimeControls);
        setAvailableTimeControlLabels(uniqueTimeControlLabels);

        setUserGames(fetchedGames);
        setFilteredGames(fetchedGames);
      }
    } catch (error) {}
  }, [userId, setUserGames]);

  useEffect(() => {
    if (userGames.length === 0) {
      fetchGames();
    }
  }, [userGames, fetchGames]);

  const filterGames = useCallback(() => {
    let filtered = userGames?.filter((game) => {
      const gameDate = new Date(game.date); // Convert to Date object
      const fromDate = filters.fromDate ? new Date(filters.fromDate) : null;
      const toDate = filters.toDate ? new Date(filters.toDate) : null;

      // ✅ Only filter by game IDs if the list is not empty
      const matchesRepertoireGameIds =
        !selectedUserGamesRepertoireGameIds ||
        selectedUserGamesRepertoireGameIds.length === 0 ||
        selectedUserGamesRepertoireGameIds.includes(game.id);

      return (
        matchesRepertoireGameIds && // ✅ Only filter if game IDs are provided
        (!fromDate || gameDate >= fromDate) && // ✅ Date filtering
        (!toDate || gameDate <= toDate) &&
        (!filters.eco || game.eco.includes(filters.eco.toUpperCase())) &&
        (!filters.opening ||
          game.openingName
            .toLowerCase()
            .includes(filters.opening.toLowerCase())) &&
        (!filters.timeControlLabel ||
          game.timeControlLabel === filters.timeControlLabel) &&
        (!filters.source || game.source === filters.source) && // ✅ Filter by source
        (!filters.timeControl || game.timeControl === filters.timeControl)
      );
    });

    // ✅ Sort by date in descending order (latest first)
    filtered.sort((a, b) => new Date(b.date) - new Date(a.date));

    setFilteredGames(filtered);
    setCurrentPage(1);
  }, [
    userGames,
    filters,
    selectedUserGamesRepertoireGameIds, // ✅ Now filtering only based on game IDs
    setFilteredGames,
    setCurrentPage,
  ]);

  const handleFilterChange = (field, value) => {
    setFilters((prev) => ({ ...prev, [field]: value }));
  };

  /** Delete All Games for the User */
  const deleteGames = async () => {
    if (!userData) {
      return;
    }

    const userGamesRef = ref(rt, `users/${userId}/games`);

    try {
      await set(userGamesRef, null);

      // Optionally, update state to reflect deletion
      setUserGames([]);
      setTotalGames(0);
    } catch (error) {}
  };

  const indexOfLastGame = currentPage * gamesPerPage;
  const indexOfFirstGame = indexOfLastGame - gamesPerPage;
  const currentGames = filteredGames.slice(indexOfFirstGame, indexOfLastGame);
  const totalPages = Math.ceil(filteredGames.length / gamesPerPage);

  useEffect(() => {
    filterGames();
  }, [filters, filterGames]); // ✅ Runs only when filters change

  useEffect(() => {
    setFilteredGames(userGames);
  }, [userGames, setFilteredGames]);

  return (
    <Stack direction="row" spacing={2} sx={{ pt: 2, pb: 2, pl: 2 }}>
      {/* Filters Section (Left Side) */}
      <Stack spacing={2} sx={{ width: "30%", minWidth: "300px" }}>
        <Card
          id="games"
          sx={{
            position: "relative",
            backgroundColor: colors.background[100], // Card background color
            color: colors.black[900], // Text color
            backgroundImage: "none",
            width: "100%",
            clipPath:
              "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
            "::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "transparent",
              border: "1px solid rgba(0, 0, 0, 0.2)",
              clipPath:
                "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
              zIndex: -1,
              pointerEvents: "none",
            },
            pt: 1,
            pl: 1,
          }}
        >
          <CardContent>
            <Typography sx={{ pb: 2 }}>
              <b>{userGames.length}</b> Games in Library {selectedUserGameId}
            </Typography>
            <Stack spacing={1} direction={"row"}>
              <Button
                id="games-import"
                variant="contained"
                onClick={() => {
                  setImportUserGamesDialogOpen(true);
                }}
                sx={{
                  color: "white",
                  textTransform: "none",
                  backgroundColor: colors.green[100],
                  "&:hover": {
                    backgroundColor: colors.green[200],
                  },
                }}
              >
                Import Games
              </Button>
              <Button
                id="games-delete"
                variant="contained"
                onClick={() => {
                  deleteGames();
                }}
                sx={{
                  color: "white",
                  textTransform: "none",
                  backgroundColor: colors.red[500],
                  "&:hover": {
                    backgroundColor: colors.red[600],
                  },
                }}
              >
                Delete Games
              </Button>
            </Stack>
          </CardContent>
        </Card>
        <Card
          id="games-filters"
          sx={{
            position: "relative",
            backgroundColor: colors.background[100], // Card background color
            color: colors.black[900], // Text color
            backgroundImage: "none",
            width: "100%",
            minWidth: "300px",
            height: "fit-content",
            clipPath:
              "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
            "::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "transparent",
              border: "1px solid rgba(0, 0, 0, 0.2)",
              clipPath:
                "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
              zIndex: -1,
              pointerEvents: "none",
            },
            p: 2,
          }}
        >
          <Stack spacing={2}>
            <Typography variant="h6">Filter Games</Typography>
            <TextField
              label="From Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={filters.fromDate}
              onChange={(e) => handleFilterChange("fromDate", e.target.value)}
              fullWidth
              sx={{
                "& input[type=date]::-webkit-calendar-picker-indicator": {
                  filter: "invert(1)",
                },
              }}
            />
            <TextField
              label="To Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={filters.toDate}
              onChange={(e) => handleFilterChange("toDate", e.target.value)}
              fullWidth
              sx={{
                "& input[type=date]::-webkit-calendar-picker-indicator": {
                  filter: "invert(1)",
                },
              }}
            />
            <TextField
              label="ECO"
              value={filters.eco}
              onChange={(e) => handleFilterChange("eco", e.target.value)}
              fullWidth
              autoComplete="off"
            />
            <TextField
              label="Opening Name"
              value={filters.opening}
              onChange={(e) => handleFilterChange("opening", e.target.value)}
              fullWidth
              autoComplete="off"
            />
            <TextField
              select
              label="Time Control"
              value={filters.timeControlLabel}
              onChange={(e) =>
                handleFilterChange("timeControlLabel", e.target.value)
              }
              fullWidth
            >
              <MenuItem value="">All</MenuItem>
              {availableTimeControlLabel.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              select
              label="Time Control Details"
              value={filters.timeControl}
              onChange={(e) =>
                handleFilterChange("timeControl", e.target.value)
              }
              fullWidth
            >
              <MenuItem value="">All</MenuItem>
              {availableTimeControls.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              label="Source"
              value={filters.source}
              onChange={(e) => handleFilterChange("source", e.target.value)}
              fullWidth
            >
              <MenuItem value="">All</MenuItem>
              {availableSources.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </Card>
      </Stack>
      <ImportUserGamesDialog
        open={isImportUserGamesDialogOpen}
        onClose={() => setImportUserGamesDialogOpen(false)}
        userData={userData}
        fetchGames={fetchGames}
        settings={settings}
        totalGames={totalGames}
        setTotalGames={setTotalGames}
        setUserGames={setUserGames}
      />

      {/* Games List Section (Right Side) */}
      <Stack spacing={2} sx={{ width: "50%", minWidth: "700px" }}>
        {/* Pagination */}
        <Card
          id="games-pagination"
          sx={{
            position: "relative",
            backgroundColor: colors.background[100], // Card background color
            color: colors.black[900], // Text color
            backgroundImage: "none",
            width: "100%",
            clipPath:
              "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
            "::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "transparent",
              border: "1px solid rgba(0, 0, 0, 0.2)",
              clipPath:
                "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
              zIndex: -1,
              pointerEvents: "none",
            },
            p: 2,
          }}
        >
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            spacing={2}
            sx={{ width: "100%" }}
          >
            {/* Pagination Component */}
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(event, value) => setCurrentPage(value)}
              color="standard" // Keeps default color, styling will handle selection
              sx={{
                "& .MuiPaginationItem-root": {
                  borderRadius: "1px", // Slight rounding for consistency
                },
                "& .Mui-selected": {
                  border: `0.5px solid #999999`, // ✅ White border around selected page
                  backgroundColor: colors.background[100], // ✅ No background change
                },
              }}
            />{" "}
            {/* Items Per Page Selection */}
            <Stack direction="row" alignItems="center" spacing={1}>
              <TextField
                select
                value={gamesPerPage}
                onChange={(e) => setGamesPerPage(Number(e.target.value))}
                size="small"
                sx={{ width: "80px" }}
              >
                {[5, 10, 20, 50].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <Typography variant="body2">Games per page</Typography>
            </Stack>
          </Stack>
        </Card>
        {/* Game List */}
        {currentGames.map((game) => (
          <Card
            key={game.id}
            sx={{
              position: "relative",
              backgroundColor: colors.background[100], // Card background color
              color: colors.black[900], // Text color
              backgroundImage: "none",
              width: "700px",
              cursor: "pointer",
              transition: "background-color 0.3s ease-in-out",
              border: "1px solid rgba(0, 0, 0, 0.2)",
              clipPath:
                "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
              "&:hover": {
                backgroundColor: colors.black[200],
              },
              "::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "transparent",
                clipPath:
                  "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
                zIndex: -1,
                pointerEvents: "none",
              },

              pt: 1,
              pl: 1,
            }}
          >
            <CardContent
              onClick={() => {
                setSelectedUserGameId(game.id);
                setSelectedUserGameMoveId("root");
              }}
            >
              <Box display="flex" alignItems="flex-start" gap={2}>
                {/* Left Side: Chessboard */}
                <Box sx={{ width: "120px", flexShrink: 0 }}>
                  <Chessboard
                    position={game.fen}
                    arePiecesDraggable={false}
                    customLightSquareStyle={{
                      backgroundColor:
                        themeColors[userData?.theme || "White Stripe Theme"]
                          .lightSquare,
                    }}
                    customDarkSquareStyle={{
                      backgroundColor:
                        themeColors[userData?.theme || "White Stripe Theme"]
                          .darkSquare,
                    }}
                  />
                </Box>

                {/* Right Side: Game Details (Aligned to Top) */}
                <Box sx={{ flex: 1 }}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    {/* Left: Icon */}
                    <Box sx={{ p: 0 }}>
                      {getTimeControlIcon(game.timeControlLabel)}
                    </Box>

                    {/* Right: Time Control & Date (Column Layout) */}
                    <Stack>
                      <Typography variant="h6">
                        {game.timeControl} ⦁ {game.timeControlLabel}
                      </Typography>
                      <Typography variant="body2" sx={{ color: "grey" }}>
                        {formatDate(game.date)}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Typography>
                    {game.white} ({game.whiteElo}){" "}
                    <span
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        verticalAlign: "middle",
                        paddingBottom: "2px",
                      }}
                    >
                      {getResultIcon("white", game?.result)}
                    </span>{" "}
                    vs {game.black} ({game.blackElo}){" "}
                    <span
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        verticalAlign: "middle",
                        paddingBottom: "2px",
                      }}
                    >
                      {getResultIcon("black", game?.result)}
                    </span>
                  </Typography>

                  <Typography>
                    {game.openingName} ({game.eco})
                  </Typography>
                  <Typography variant="body2" sx={{ color: "grey" }}>
                    {game.startingLine} ...
                    {game.numberOfMoves < 10
                      ? ""
                      : " (" + game.numberOfMoves + " moves)"}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Stack>
    </Stack>
  );
};

export default UserGamesList;
