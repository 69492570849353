import { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
  useTheme,
} from "@mui/material";
import { tokens } from "../../../../styles/theme";

import { ref, set } from "firebase/database";
import { rt } from "../../../../config/firebase";
import { addRepertoire } from "../../../../config/firebaseDB";

import { useUser } from "../../../../context/UserContext";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SettingsIcon from "@mui/icons-material/Settings";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import SubjectIcon from "@mui/icons-material/Subject";
import UploadSharpIcon from "@mui/icons-material/UploadSharp";
import AutoAwesomeSharpIcon from "@mui/icons-material/AutoAwesomeSharp";
import LibraryAddSharpIcon from "@mui/icons-material/LibraryAddSharp";

import ImportPGNDialog from "../Components/importPGNDialog"; // Import the PGN dialog

import { v4 as uuidv4 } from "uuid";
import RepertoireWizardDialog from "./builderRepertoireWizardDialog";
import ImportTemplateDialog from "../Components/importTemplateDialog";
import { useAlert } from "../../../../context/AlertProvider";

function RepertoireCard({
  repertoires,
  setRepertoires,
  selectedRepertoireId,
  setSelectedRepertoireId,
  setRepertoireDetails,
  setMoves,
  setSelectedMoveId,
  setIsModalOpen,
  settings,
  totalRepertoires,
  setTotalRepertoires,
  totalMoves,
  setTotalMoves,
}) {
  const [isImportDialogOpen, setImportDialogOpen] = useState(false);
  const [isTemplateDialogOpen, setTemplateDialogOpen] = useState(false);
  const [isWizardDialogOpen, setWizardDialogOpen] = useState(false);

  const { userData } = useUser();
  const userId = userData?.uid || "";

  const showAlert = useAlert();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  /** Handles selecting a repertoire */
  const handleRepertoireClick = (id) => {
    setSelectedRepertoireId(id);
    setSelectedMoveId("root");

    const selectedRepertoire = repertoires[id];

    if (selectedRepertoire) {
      setRepertoireDetails({
        title: selectedRepertoire.title || "",
        description: selectedRepertoire.description || "",
        author: selectedRepertoire.author || "",
        boardOrientation: selectedRepertoire.boardOrientation || "white",
        visibility: selectedRepertoire.visibility || "Private",
        allowCopy: selectedRepertoire.allowCopy || "No",
      });

      // ✅ Update moves from selected repertoire
      setMoves(
        selectedRepertoire.moves || {
          root: { san: "", next: null, variations: [], parent: null },
        }
      );
    }
  };

  /** Handles adding a new repertoire */
  const handleAddRepertoire = async () => {
    if (totalRepertoires >= settings.limitRepertoires) {
      showAlert(
        `Maximum Number of Repertoires Exceeded (${settings.limitRepertoires}). Please upgrade to have more repertoires.`,
        "error"
      );

      return;
    }

    const newRepertoireId = uuidv4();
    const newRepertoire = {
      id: newRepertoireId,
      title: `Repertoire ${Object.keys(repertoires).length + 1}`,
      description: "",
      author: userData?.username,
      boardOrientation: "white",
      visibility: "Private",
      allowCopy: "No",
      createdAt: Date.now(),
      order: -9999,
      moves: {
        root: { san: "", next: null, variations: [], parent: null },
      },
    };

    try {
      await addRepertoire(userId, newRepertoire);

      setTotalRepertoires((prevCount) => prevCount + 1);

      // Update state and ensure the correct repertoire is selected
      setRepertoires((prev) => {
        return {
          ...prev,
          [newRepertoireId]: newRepertoire,
        };
      });
    } catch (error) {}
  };

  /** Handles sorting the repertoires */
  const reorderItem = (id, direction) => {
    setRepertoires((prev) => {
      const items = Object.entries(prev).sort(
        ([, a], [, b]) => a.order - b.order
      );
      const currentIndex = items.findIndex(([key]) => key === id);
      const newIndex = currentIndex + direction;

      if (newIndex >= 0 && newIndex < items.length) {
        const updatedItems = items.map(([key, value], index) => {
          if (index === currentIndex)
            return [key, { ...value, order: newIndex }];
          if (index === newIndex)
            return [key, { ...value, order: currentIndex }];
          return [key, value];
        });

        const updatedRepertoires = Object.fromEntries(updatedItems);
        Object.entries(updatedRepertoires).forEach(([key, rep]) => {
          set(ref(rt, `users/${userId}/repertoires/${key}`), rep);
        });

        return updatedRepertoires;
      }
      return prev;
    });
  };

  return (
    <Card
      id="builder-repertoirecard"
      sx={{
        position: "relative", // Required for pseudo-element positioning
        backgroundColor: colors.background[100], // Card background color
        color: colors.black[900], // Text color
        backgroundImage: "none !important",
        width: "100%",
        clipPath:
          "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
        "::before": {
          content: '""', // Required for pseudo-element
          position: "absolute", // Position relative to the parent
          top: 0, // Align with the top of the parent
          left: 0, // Align with the left of the parent
          width: "100%", // Match the width of the parent
          height: "100%", // Match the height of the parent
          backgroundColor: "transparent", // Transparent background to show the card
          border: "1px solid rgba(0, 0, 0, 0.2)", // Red outline
          clipPath:
            "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
          zIndex: -1, // Place behind the card content
          pointerEvents: "none", // Ensure the outline does not interfere with interactions
        },
        p: 1, // Padding for the card content
      }}
    >
      <CardHeader
        avatar={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <SubjectIcon />
          </Box>
        }
        title={
          <Typography variant="h7" sx={{ fontWeight: "bold" }}>
            Repertoires
          </Typography>
        }
        action={
          <>
            <Tooltip title="Add New Repertoire">
              <IconButton
                id="builder-repertoirecard-add"
                color="primary"
                onClick={handleAddRepertoire}
              >
                <ControlPointIcon sx={{ color: colors.black[900] }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Import Repertoire">
              <IconButton
                id="builder-repertoirecard-import"
                color="primary"
                onClick={() => setImportDialogOpen(true)}
              >
                <UploadSharpIcon sx={{ color: colors.black[900] }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Import Template">
              <IconButton
                id="builder-repertoirecard-template"
                color="primary"
                onClick={() => setTemplateDialogOpen(true)}
              >
                <LibraryAddSharpIcon sx={{ color: colors.black[900] }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Open Repertoire Wizard">
              <IconButton
                id="builder-repertoirecard-wizard"
                color="primary"
                onClick={() => setWizardDialogOpen(true)}
              >
                <AutoAwesomeSharpIcon sx={{ color: colors.black[900] }} />
              </IconButton>
            </Tooltip>
            <ImportPGNDialog
              open={isImportDialogOpen}
              onClose={() => setImportDialogOpen(false)}
              setRepertoires={setRepertoires}
              setSelectedRepertoireId={setSelectedRepertoireId}
              setMoves={setMoves}
              setSelectedMoveId={setSelectedMoveId}
              repertoires={repertoires}
              userData={userData}
              settings={settings}
              totalRepertoires={totalRepertoires}
              setTotalRepertoires={setTotalRepertoires}
              totalMoves={totalMoves}
              setTotalMoves={setTotalMoves}
            />

            <ImportTemplateDialog
              open={isTemplateDialogOpen}
              onClose={() => setTemplateDialogOpen(false)}
              setRepertoires={setRepertoires}
              setSelectedRepertoireId={setSelectedRepertoireId}
              setMoves={setMoves}
              setSelectedMoveId={setSelectedMoveId}
              repertoires={repertoires}
              userData={userData}
              settings={settings}
              totalRepertoires={totalRepertoires}
              setTotalRepertoires={setTotalRepertoires}
              totalMoves={totalMoves}
              setTotalMoves={setTotalMoves}
            />

            <RepertoireWizardDialog
              open={isWizardDialogOpen}
              onClose={() => setWizardDialogOpen(false)}
              userData={userData}
              setRepertoires={setRepertoires}
              settings={settings}
              totalRepertoires={totalRepertoires}
              setTotalRepertoires={setTotalRepertoires}
              totalMoves={totalMoves}
              setTotalMoves={setTotalMoves}
            />
          </>
        }
        sx={{ pt: 1, pb: 1 }}
      />
      <Divider sx={{ backgroundColor: colors.green[100], height: "1.5px" }} />
      <CardContent
        sx={{
          mt: 1,
          ml: 0,
          mb: 2,
          mr: 1,
          overflowY: "auto",
          maxHeight: "300px",
        }}
      >
        <List sx={{ p: 0 }}>
          {Object.entries(repertoires)
            .sort(([, a], [, b]) => a.order - b.order)
            .map(([id, repertoire], index, array) => (
              <ListItem
                key={id}
                onClick={() => handleRepertoireClick(id)}
                sx={{
                  mb: 1,
                  backgroundColor:
                    selectedRepertoireId === id
                      ? colors.background[200]
                      : "transparent",
                  border: `1px solid ${colors.background[200]}`,
                  clipPath:
                    "polygon(10px 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%, 0 10px)",
                  "&:hover": { backgroundColor: colors.background[200] },
                }}
              >
                <ListItemText
                  primary={repertoire.title}
                  primaryTypographyProps={{
                    variant: "body",
                  }}
                  sx={{ p: 0, maxWidth: "calc(100% - 100px)" }} // Adjust width to prevent overflow into icons
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    disabled={index === 0}
                    onClick={() => reorderItem(id, -1)}
                  >
                    <ArrowUpwardIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    disabled={index === array.length - 1}
                    onClick={() => reorderItem(id, 1)}
                  >
                    <ArrowDownwardIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    onClick={() => {
                      handleRepertoireClick(id); // Ensure the correct repertoire is selected
                      setIsModalOpen(true); // Open settings modal
                    }}
                  >
                    <SettingsIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </List>
      </CardContent>
    </Card>
  );
}

export default RepertoireCard;
