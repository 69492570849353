import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Card,
  Chip,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { ref, update, get } from "firebase/database";
import { rt } from "../../../../config/firebase";
import { ResponsiveBar } from "@nivo/bar";

import { tokens } from "../../../../styles/theme";

const RepertoireCardAnalysis = ({
  userData,
  userGames,
  repertoires,
  selectedRepertoireId,
  setSelectedRepertoireId,
  setSelectedUserGamesRepertoireGameIds,
}) => {
  const [bestMatches, setBestMatches] = useState([]);
  const [usernames, setUsernames] = useState([]); // Store entered usernames
  const [inputValue, setInputValue] = useState(""); // Input field state

  const [repertoireStats, setRepertoireStats] = useState([]);

  const [matchThreshold] = useState(4); // Default threshold

  const userId = userData?.uid || "";
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Handle adding a username when Enter is pressed
  const handleAddUsername = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      const trimmedUsername = inputValue.trim();

      if (usernames.includes(trimmedUsername)) {
        return; // Prevent adding duplicates
      }

      setUsernames((prev) => [...prev, trimmedUsername]);
      setInputValue(""); // Clear input field
    }
  };

  // Handle deleting a username
  const handleDeleteUsername = (usernameToDelete) => {
    setUsernames((prev) =>
      prev.filter((username) => username !== usernameToDelete)
    );
  };

  // Function to extract the move sequence from game.moves
  const getMoveSequence = (gameMoves, depth = null) => {
    if (!gameMoves || !gameMoves["root"]?.next) {
      return []; // ✅ Return early if no moves exist
    }

    let sequence = [];
    let moveId = gameMoves["root"].next; // Start from the first move
    let count = 0;

    while (moveId && gameMoves[moveId] && (depth === null || count < depth)) {
      const move = gameMoves[moveId];
      sequence.push(move.san); // Store SAN notation
      moveId = move.next; // Move to the next move in sequence
      count++;
    }

    return sequence;
  };

  const findBestMatches = useCallback(() => {
    let matches = [];
    let repertoirePerformance = {}; // Store wins/losses/draws per repertoire

    userGames.forEach((game, gameIndex) => {
      const sequence = getMoveSequence(game.moves);

      if (sequence.length === 0) {
        return;
      }

      let bestMatch = null;
      let bestMatchLength = 0;

      Object.entries(repertoires).forEach(([repertoireId, repertoire]) => {
        let repMoveId = repertoire.moves["root"]?.next;
        let moveIndex = 0;
        let matchedMoves = 0; // Track number of matched moves

        while (repMoveId && repertoire.moves[repMoveId]) {
          const repMove = repertoire.moves[repMoveId];

          if (repMove.san === sequence[moveIndex]) {
            matchedMoves++;
            moveIndex++;

            if (matchedMoves > bestMatchLength) {
              bestMatch = repertoireId;
              bestMatchLength = matchedMoves;
            }
          } else if (repMove.variations) {
            for (const variationId of repMove.variations) {
              const variationMove = repertoire.moves[variationId];
              if (variationMove && variationMove.san === sequence[moveIndex]) {
                matchedMoves++;
                moveIndex++;

                if (matchedMoves > bestMatchLength) {
                  bestMatch = repertoireId;
                  bestMatchLength = matchedMoves;
                }
                break; // Stop checking other variations once matched
              }
            }
          } else {
            break; // Mismatch detected, stop searching
          }

          repMoveId = repMove.next;
        }
      });

      // Assign "N/A" if no match meets the threshold
      const finalRepertoire =
        bestMatchLength >= matchThreshold ? bestMatch : "N/A";
      matches.push({
        repertoireId: finalRepertoire,
        userGameId: game.id,
        lastMatchedMoveIndex: bestMatchLength, // Store last matched move index
      });

      // Initialize performance tracking for this repertoire
      if (!repertoirePerformance[finalRepertoire]) {
        repertoirePerformance[finalRepertoire] = {
          wins: 0,
          losses: 0,
          draws: 0,
        };
      }

      // Determine win/loss/draw stats
      const gameResult = game.result;

      // Convert all usernames to lowercase for case-insensitive comparison
      const normalizedUsernames = usernames.map((name) => name.toLowerCase());

      const isUserWhite = normalizedUsernames.includes(
        game.white?.toLowerCase()
      );
      const isUserBlack = normalizedUsernames.includes(
        game.black?.toLowerCase()
      );

      if (isUserWhite) {
        if (gameResult === "1-0") {
          repertoirePerformance[finalRepertoire].wins++;
        } else if (gameResult === "0-1") {
          repertoirePerformance[finalRepertoire].losses++;
        } else {
          repertoirePerformance[finalRepertoire].draws++;
        }
      } else if (isUserBlack) {
        if (gameResult === "1-0") {
          repertoirePerformance[finalRepertoire].losses++;
        } else if (gameResult === "0-1") {
          repertoirePerformance[finalRepertoire].wins++;
        } else {
          repertoirePerformance[finalRepertoire].draws++;
        }
      }

      // Initialize totalPerformanceRating if not already present
      if (
        repertoirePerformance[finalRepertoire].totalPerformanceRating ===
        undefined
      ) {
        repertoirePerformance[finalRepertoire].totalPerformanceRating = 0;
      }

      // Parse opponent rating based on user color
      let opponentRating = 0;

      if (isUserWhite && game.blackElo) {
        opponentRating = parseInt(game.blackElo, 10);
      } else if (isUserBlack && game.whiteElo) {
        opponentRating = parseInt(game.whiteElo, 10);
      }

      // Validate and calculate performance rating
      if (!isNaN(opponentRating)) {
        let performanceRating = opponentRating;

        if (gameResult === "1-0") {
          performanceRating += isUserWhite ? 200 : -200;
        } else if (gameResult === "0-1") {
          performanceRating += isUserBlack ? 200 : -200;
        }

        repertoirePerformance[finalRepertoire].totalPerformanceRating +=
          performanceRating;
      }
    });

    setBestMatches(matches);
    setRepertoireStats(repertoirePerformance);
  }, [repertoires, userGames, usernames, matchThreshold]);

  const formatMoves = (moves) => {
    let pgn = "";

    for (let i = 0; i < moves.length; i += 2) {
      const moveNumber = Math.floor(i / 2) + 1; // Move number
      const whiteMove = moves[i] || ""; // White move
      const blackMove = moves[i + 1] || ""; // Black move (might be empty)

      pgn += `${moveNumber}. ${whiteMove} ${blackMove} `;
    }

    return pgn.trim(); // Remove any trailing space
  };

  // Load existing usernames from Firebase on mount
  useEffect(() => {
    if (!userId) return;

    const userRef = ref(rt, `users/${userId}/usernames`);
    get(userRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          setUsernames(snapshot.val()); // Load existing usernames
        } else {
          setUsernames([]); // Initialize empty if not found
        }
      })
      .catch((error) => {});
  }, [userId]);

  // Call function inside useEffect
  useEffect(() => {
    findBestMatches();
  }, [findBestMatches, usernames]);

  // Update Firebase when usernames change
  useEffect(() => {
    if (!userId) return;

    const userRef = ref(rt, `users/${userId}`);
    update(userRef, { usernames }) // Save usernames array
      .catch((error) => console.error("Error updating usernames:", error));
  }, [usernames, userId]);

  return (
    <Box sx={{ width: "100%", pt: 2, pb: 2, pl: 0 }}>
      {/* Username Input Field */}

      <Stack direction="column" spacing={2}>
        <Card
          id="games-usernames"
          sx={{
            position: "relative",
            backgroundColor: colors.background[100], // Card background color
            color: colors.black[900], // Text color
            backgroundImage: "none",
            width: "100%",
            minWidth: "300px",
            maxWidth: "350px",
            cursor: "pointer",
            transition: "background-color 0.3s ease-in-out",
            clipPath:
              "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",

            "::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              minWidth: "300px",
              maxWidth: "350px",
              height: "100%",
              backgroundColor: "transparent",
              border: "1px solid rgba(0, 0, 0, 0.2)", // Keep original outline
              clipPath:
                "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
              zIndex: -1,
              pointerEvents: "none",
            },
            p: 2,
          }}
        >
          <Stack direction="column" spacing={1}>
            {/* Display message if no usernames are added */}
            {usernames.length === 0 && (
              <Typography variant="body2" color="error">
                Please have at least 1 username
              </Typography>
            )}
            {/* Text Field */}
            <TextField
              label="Add Username"
              variant="outlined"
              size="small"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={handleAddUsername}
              placeholder="Type a username and press Enter"
              autoComplete="off"
              sx={{ width: "100%" }} // Set the fixed width
            />

            {/* Chips Container with Wrapping */}
            <Box
              sx={{
                width: "250px",
                display: "flex",
                flexWrap: "wrap",
                gap: "4px",
              }}
            >
              {usernames.map((username) => (
                <Chip
                  variant="outlined"
                  key={username}
                  label={username}
                  onDelete={() => handleDeleteUsername(username)}
                />
              ))}
            </Box>
          </Stack>
        </Card>
        {Object.entries(repertoireStats)
          .sort(([repIdA, statsA], [repIdB, statsB]) => {
            // Move "N/A" to the bottom
            if (repIdA === "N/A") return 1;
            if (repIdB === "N/A") return -1;

            // Sort by the total number of games played (wins + losses + draws)
            const totalGamesA = statsA.wins + statsA.losses + statsA.draws;
            const totalGamesB = statsB.wins + statsB.losses + statsB.draws;

            return totalGamesB - totalGamesA; // Sort in descending order
          })
          .map(([repId, stats]) => {
            const totalGames = stats.wins + stats.losses + stats.draws;

            // Avoid division by zero (in case of empty data)
            const winPercentage =
              totalGames > 0 ? (stats.wins / totalGames) * 100 : 0;
            const lossPercentage =
              totalGames > 0 ? (stats.losses / totalGames) * 100 : 0;
            const drawPercentage =
              totalGames > 0 ? (stats.draws / totalGames) * 100 : 0;

            // Dynamically compute average match length for this repertoire
            const matchedGames = bestMatches.filter(
              (match) => match.repertoireId === repId
            );
            const avgMatchLength =
              matchedGames.length > 0
                ? matchedGames.reduce(
                    (sum, match) => sum + match.lastMatchedMoveIndex,
                    0
                  ) / matchedGames.length
                : 0;

            const performanceRating = (
              stats.totalPerformanceRating / totalGames
            ).toFixed(1);

            const chartData = [
              {
                move:
                  repId === "N/A" ? "No Repertoire" : repertoires[repId].title,
                wins: winPercentage,
                draws: drawPercentage,
                losses: lossPercentage,
                winsLabel: `${winPercentage.toFixed(0)}%`,
                drawsLabel: `${drawPercentage.toFixed(0)}%`,
                lossesLabel: `${lossPercentage.toFixed(0)}%`,
              },
            ];

            return (
              <Card
                onClick={() => {
                  if (selectedRepertoireId === repId) {
                    // If the same card is clicked again, deselect it and reset game IDs
                    setSelectedRepertoireId(null);
                    setSelectedUserGamesRepertoireGameIds(null);
                  } else {
                    // Otherwise, select it and set game IDs
                    setSelectedRepertoireId(repId);

                    // Extract game IDs that match the selected repertoire
                    const gameIds = bestMatches
                      .filter((match) => match.repertoireId === repId)
                      .map((match) => match.userGameId); // ✅ Only storing game IDs

                    setSelectedUserGamesRepertoireGameIds(gameIds);
                  }
                }}
                key={repId}
                sx={{
                  position: "relative",
                  backgroundColor:
                    repId === selectedRepertoireId
                      ? colors.black[200]
                      : colors.background[100],
                  color: colors.black[900], // Text color
                  backgroundImage: "none",
                  width: "100%",
                  minWidth: "300px",
                  maxWidth: "350px",
                  cursor: "pointer",
                  transition: "background-color 0.3s ease-in-out",
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  clipPath:
                    "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
                  "&:hover": {
                    backgroundColor: colors.black[200],
                  },
                  "::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                    clipPath:
                      "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
                    zIndex: -1,
                    pointerEvents: "none",
                  },
                  p: 2,
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  {repId === "N/A" ? "No Repertoire" : repertoires[repId].title}{" "}
                </Typography>
                <Typography variant="body2" sx={{ color: "grey" }}>
                  {repertoires[repId] && repertoires[repId].moves
                    ? formatMoves(getMoveSequence(repertoires[repId].moves, 8))
                    : ""}
                </Typography>
                <Typography variant="body2" sx={{ pt: 1 }}>
                  <strong></strong>
                  {totalGames} Game{totalGames > 1 ? "s" : ""}:{" "}
                  <strong>{stats.wins}W</strong>,{" "}
                  <strong>{stats.draws}D</strong>,{" "}
                  <strong>{stats.losses}L</strong>
                </Typography>

                <Typography variant="body2">
                  {repId === "N/A" ? (
                    ""
                  ) : (
                    <>
                      Avg. Repertoire Match:{" "}
                      <strong>{avgMatchLength.toFixed(1)} moves</strong>
                    </>
                  )}
                </Typography>
                <Typography variant="body2">
                  Performance Rating: <strong>{performanceRating}</strong>
                </Typography>

                <Typography></Typography>

                {/* Bar Chart Representation */}
                <Box height={40} width={"100%"}>
                  <ResponsiveBar
                    data={chartData}
                    keys={["wins", "draws", "losses"]}
                    indexBy="move"
                    layout="horizontal"
                    margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                    padding={0.2}
                    colors={["#4caf50", "#9e9e9e", "#f44336"]} // Green for wins, grey for draws, red for losses
                    borderColor={{
                      from: "color",
                      modifiers: [["darker", 1.6]],
                    }}
                    axisLeft={null}
                    axisBottom={null}
                    enableGridX={false}
                    enableLabel={true}
                    labelSkipWidth={1}
                    labelSkipHeight={1}
                    label={({ id, value, data }) =>
                      id === "wins"
                        ? data.winsLabel
                        : id === "draws"
                        ? data.drawsLabel
                        : id === "losses"
                        ? data.lossesLabel
                        : ""
                    }
                    labelTextColor={(bar) =>
                      bar.color === "#000000" ? "#ffffff" : "#000000"
                    }
                    tooltip={() => null}
                    theme={{
                      labels: { text: { fontSize: 10 } },
                    }}
                  />
                </Box>
              </Card>
            );
          })}
      </Stack>
    </Box>
  );
};

export default RepertoireCardAnalysis;
