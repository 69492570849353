import React, { useCallback, useEffect, useRef, useState } from "react";
import { Chessboard } from "react-chessboard";
import { Chess } from "chess.js";
import {
  Box,
  Button,
  Card,
  MenuItem,
  Select,
  Tooltip,
  useTheme,
  Fade,
  Alert,
} from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import SwapVertRoundedIcon from "@mui/icons-material/SwapVertRounded";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Arrow from "../Components/arrow";
import { themeColors } from "../../../../styles/boardtheme";
import { pieceSets } from "../../../../styles/pieceset";
import useCategories from "../Components/useCategories";
import useBoardHighlight from "../Components/useBoardHighlights";
import { tokens } from "../../../../styles/theme";

import TrainingMovesCard from "./trainerMovesCard";

const PositionTrainerCard = ({
  moves,
  selectedMoveId,
  setSelectedMoveId,
  userData,
  repertoires,
  selectedRepertoireId,
  setSelectedRepertoireId,
  activePage,
  result,
  setResult,
  trainingMode,
  setTrainingMode,
  quit,
  setQuit,
}) => {
  const chessboardRef = useRef(null);
  const [game, setGame] = useState(new Chess());
  const categories = useCategories();
  const highlightColors = useBoardHighlight(); // Get color mapping
  const [showAlert, setShowAlert] = useState(false);
  const [showCard, setShowCard] = useState(false);

  const [selectedRepertoireName, setSelectedRepertoireName] = useState(
    selectedRepertoireId ? repertoires[selectedRepertoireId]?.title : ""
  );
  const [boardOrientation, setBoardOrientation] = useState(
    repertoires[selectedRepertoireId]?.boardOrientation
  );

  const [highlights, setHighlights] = useState([]);
  const [lastMove, setLastMove] = useState({ from: null, to: null });

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const getCategoryColorBySymbol = useCallback(
    (symbol) => {
      const allItems = Object.values(categories).flat(); // Flatten all category items
      const item = allItems.find((item) => item.symbol === symbol); // Find the matching symbol
      return item ? item.color : null; // Return the color if found, else null
    },
    [categories]
  );

  const getLastMoveSquare = useCallback(() => {
    const history = game.history({ verbose: true }); // Retrieve detailed move history
    if (history.length === 0) return null; // Return null if no moves exist
    return history[history.length - 1].to; // Return the destination square of the last move
  }, [game]);

  const getSquareStyles = () => {
    const styles = {}; // Initialize an empty object for square styles

    if (lastMove.from) {
      styles[lastMove.from] = { backgroundColor: "rgba(173, 216, 230, 0.5)" }; // Light blue for from-square
    }
    if (lastMove.to) {
      styles[lastMove.to] = { backgroundColor: "rgba(173, 216, 230, 0.5)" }; // Light green for to-square
    }

    return styles; // Return the styles object
  };

  const renderAnnotation = useCallback(() => {
    if (!game || !chessboardRef?.current || !selectedMoveId) {
      return null;
    }

    const selectedMove = moves[selectedMoveId];

    if (!selectedMove || !selectedMove.annotations) {
      return null; // If no annotation, render nothing
    }

    const chessboardSize = chessboardRef.current.getBoundingClientRect(); // Get chessboard dimensions
    const squareSize = chessboardSize.width / 8; // Calculate square size

    const lastMoveSquare = getLastMoveSquare(); // Get the square of the last move
    if (!lastMoveSquare) return null;

    const { x, y } = getSquarePosition(lastMoveSquare);

    // Adjust coordinates based on board orientation
    const adjustedX = boardOrientation === "white" ? x : 1 - x;
    const adjustedY = boardOrientation === "white" ? y : 1 - y;

    const cx =
      chessboardSize.left + adjustedX * chessboardSize.width + squareSize / 2; // Circle x-coordinate
    const cy =
      chessboardSize.top + adjustedY * chessboardSize.height - squareSize / 2; // Circle y-coordinate

    return (
      <>
        <defs>
          <filter id="drop-shadow" x="-50%" y="-50%" width="200%" height="200%">
            <feDropShadow
              dx="2"
              dy="2"
              stdDeviation="2"
              floodColor="black"
              floodOpacity="0.5"
            />
          </filter>
        </defs>
        <circle
          cx={`${((cx - chessboardSize.left) / chessboardSize.width) * 100}%`}
          cy={`${((cy - chessboardSize.top) / chessboardSize.height) * 100}%`}
          r="20"
          stroke="white"
          strokeWidth="1.5"
          renderAnnotation
          fill={getCategoryColorBySymbol(moves[selectedMoveId].annotations)}
          fillOpacity="0.7"
          filter="url(#drop-shadow)"
        />
        <text
          x={`${((cx - chessboardSize.left) / chessboardSize.width) * 100}%`}
          y={`${((cy - chessboardSize.top) / chessboardSize.height) * 100}%`}
          fill="white"
          fontSize="26px"
          textAnchor="middle"
          fontWeight="bold"
          dominantBaseline="middle"
        >
          {selectedMove.annotations}
        </text>
      </>
    );
  }, [
    moves,
    selectedMoveId,
    game,
    chessboardRef,
    boardOrientation,
    getLastMoveSquare,
    getCategoryColorBySymbol,
  ]);

  const [arrows, setArrows] = useState([]);

  const getMoveSequence = useCallback(() => {
    let currentMoveId = selectedMoveId;
    let movePath = [];

    while (currentMoveId && moves[currentMoveId]) {
      const san = moves[currentMoveId].san;
      if (san && san.trim() !== "") {
        movePath.unshift(san); // Insert at the beginning to maintain move order
      }
      currentMoveId = moves[currentMoveId].parent; // Move backwards to the parent
    }

    return movePath; // Return the sequence from root to selectedMoveId
  }, [selectedMoveId, moves]);

  const handleMove = (sourceSquare, targetSquare) => {
    try {
      const newGame = new Chess();
      newGame.loadPgn(game.pgn());

      const move = newGame.move({
        from: sourceSquare,
        to: targetSquare,
        promotion: "q",
      });

      if (!move) throw new Error("Illegal move");

      const currentMove = moves[selectedMoveId];
      if (!currentMove) return false;

      const nextMoveId = currentMove.next;
      const validMoves = [
        moves[nextMoveId]?.san,
        ...(moves[nextMoveId]?.variations || []).map((v) => moves[v]?.san),
      ].filter(Boolean);

      if (result === "CorrectML" || result === "CorrectV") {
        if (!validMoves.includes(move.san)) {
          setGame(new Chess(game.fen())); // Snap back to previous state
          return false;
        }

        // Allow user to continue playing valid moves
        const nextMoveKey = Object.keys(moves).find(
          (key) =>
            moves[key].san === move.san && moves[key].parent === selectedMoveId
        );
        if (nextMoveKey) {
          setSelectedMoveId(nextMoveKey);
        }
        setGame(newGame);
        return true;
      }

      if (!validMoves.includes(move.san)) {
        setResult("incorrect");
        setGame(new Chess(game.fen())); // Snap back
        return false;
      }

      if (move.san === moves[nextMoveId]?.san) {
        setResult("CorrectML");
        setShowCard(true);
        setSelectedMoveId(nextMoveId);
      } else {
        setResult("CorrectV");
        setShowCard(true);
        const variationId = Object.keys(moves).find(
          (key) =>
            moves[key].san === move.san && moves[key].parent === selectedMoveId
        );
        if (variationId) setSelectedMoveId(variationId);
      }

      setQuit(false);
      setGame(newGame);

      return true;
    } catch (error) {
      setResult("incorrect");
      return false;
    }
  };

  const handleNext = useCallback(() => {
    if (result === "incorrect" || result === null) return;

    if (!selectedMoveId) return; // No move selected

    const currentMove = moves[selectedMoveId];
    if (!currentMove || !currentMove.next) return; // No next move available

    const nextMoveId = currentMove.next;
    const nextMove = moves[nextMoveId];

    if (!nextMove) return; // Defensive check

    setGame((prevGame) => {
      const newGame = new Chess();
      newGame.loadPgn(prevGame.pgn()); // Load existing moves
      newGame.move(nextMove.san); // Play the next move
      return newGame;
    });

    setSelectedMoveId(nextMoveId); // Update selected move
  }, [selectedMoveId, result, moves, setGame, setSelectedMoveId]);

  const handleQuit = () => {
    setResult("quit");
    setQuit(false);
    setShowAlert(false);
    setShowCard(true);
    setSelectedMoveId(moves[selectedMoveId].next);
  };

  const handleBack = useCallback(() => {
    if (result === "incorrect" || result === null) return;
    if (!selectedMoveId || selectedMoveId === "root") return; // Do nothing if at the root

    const currentMove = moves[selectedMoveId];
    if (!currentMove || !currentMove.parent) return; // No parent = already at the start

    setGame((prevGame) => {
      const newGame = new Chess();
      newGame.loadPgn(prevGame.pgn()); // Load existing moves
      newGame.undo(); // Undo the last move
      return newGame;
    });

    setSelectedMoveId(currentMove.parent); // Move back to the parent move
  }, [selectedMoveId, result, moves, setGame, setSelectedMoveId]);

  const getAllMovesForColor = (moves, color) => {
    const moveList = [];
    const traverseMoves = (moveId, moveColor) => {
      const move = moves[moveId];
      if (!move) return;

      if (moveColor === color && move.next) {
        moveList.push({ id: moveId, color });
      }

      if (move.next) {
        traverseMoves(move.next, moveColor === "white" ? "black" : "white");
      }

      if (Array.isArray(move.variations)) {
        move.variations.forEach((variationId) => {
          traverseMoves(variationId, moveColor);
        });
      }
    };

    traverseMoves("root", "white");
    return moveList;
  };

  const handleTrainClick = () => {
    if (Object.keys(moves).length === 0) return; // Moves are now already loaded

    const validMoves = getAllMovesForColor(moves, boardOrientation);
    if (validMoves.length === 0) return;

    const randomMove =
      validMoves[Math.floor(Math.random() * validMoves.length)];
    setSelectedMoveId(randomMove.id);
    setResult(null);
    setShowCard(false);
    setShowAlert(false);
    setQuit(true);
  };

  const handleRepertoireChange = (event) => {
    const selectedId = event.target.value;

    setSelectedRepertoireId(selectedId);
    setSelectedRepertoireName(repertoires[selectedId].title);
    setResult(null);
    setQuit(false);

    const repertoire = repertoires[selectedId];
    if (repertoire) {
      setBoardOrientation(repertoire.boardOrientation || "white");
    }
    setSelectedMoveId("root");
  };

  const getSquarePosition = (square) => {
    const file = square.charCodeAt(0) - 97; // Convert file (a-h) to 0-7
    const rank = 8 - parseInt(square[1], 10); // Convert rank (1-8) to 7-0
    return { x: (file + 0.5) / 8, y: (rank + 0.5) / 8 }; // Normalize to percentages
  };

  // useEffect to update the game when selectedMoveId changes
  useEffect(() => {
    if (!selectedMoveId || !moves[selectedMoveId]) return;

    setGame(() => {
      const newGame = new Chess();
      const moveSequence = getMoveSequence();

      moveSequence.forEach((san) => {
        newGame.move(san); // Apply each move to reconstruct the game state
      });

      // Capture the last move (from & to squares)
      const history = newGame.history({ verbose: true });
      if (history.length > 0) {
        const lastMove = history.slice(-1)[0]; // Get the last move
        setLastMove({ from: lastMove.from, to: lastMove.to });
      } else {
        setLastMove({ from: null, to: null }); // Reset when no moves exist
      }

      return newGame;
    });
  }, [selectedMoveId, moves, getMoveSequence]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (activePage !== "Trainer") return; // Only handle events when activePage is "Trainer"

      switch (event.key) {
        case "ArrowLeft":
          handleBack();
          break;
        case "ArrowRight":
          handleNext();
          break;
        default:
          break;
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleBack, handleNext, activePage]); // Add activePage as a dependency

  useEffect(() => {
    if (selectedMoveId) {
      setArrows(moves[selectedMoveId]?.arrows || []);
      setHighlights(moves[selectedMoveId]?.highlightSquares || []);
    } else {
      setArrows([]);
      setHighlights([]);
    }
  }, [moves, selectedMoveId]);

  useEffect(() => {
    if (
      result === "CorrectML" ||
      result === "CorrectV" ||
      result === "incorrect"
    ) {
      setShowAlert(true);

      const timer = setTimeout(() => {
        if (result === "incorrect") {
          setResult(null);
        }
        setShowAlert(false); // ✅ Allow moves after alert disappears
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [result, setResult, setShowAlert]);

  useEffect(() => {
    setGame(new Chess());
    setLastMove({ from: null, to: null });
  }, []);

  return (
    <Box display="flex" flexDirection="row" sx={{ p: 2, gap: 2 }}>
      <Box
        sx={{
          p: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "35%",
          minWidth: "400px",
          mb: 10,
        }}
      >
        <Box sx={{ width: "100%", position: "relative" }}>
          {/* Trainer Buttons */}

          <Card
            sx={{
              position: "relative",
              backgroundColor: colors.background[100], // Card background color
              color: colors.black[900], // Text color
              backgroundImage: "none",
              width: "100%",
              clipPath:
                "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
              "::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "transparent",
                border: "1px solid rgba(0, 0, 0, 0.2)",
                clipPath:
                  "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
                zIndex: -1,
                pointerEvents: "none",
              },
              p: 2,
            }}
          >
            {/* Chessboard */}
            <Box ref={chessboardRef}>
              <Chessboard
                position={game.fen()}
                onPieceDrop={handleMove}
                areArrowsAllowed={true}
                boardOrientation={boardOrientation}
                customSquareStyles={getSquareStyles()}
                customLightSquareStyle={{
                  backgroundColor:
                    themeColors[userData?.theme || "White Stripe Theme"]
                      .lightSquare,
                  backgroundImage:
                    themeColors[userData?.theme || "White Stripe Theme"]
                      .lightSquarePattern,
                }}
                customDarkSquareStyle={{
                  backgroundColor:
                    themeColors[userData?.theme || "White Stripe Theme"]
                      .darkSquare,
                  backgroundImage:
                    themeColors[userData?.theme || "White Stripe Theme"]
                      .darkSquarePattern,
                }}
                customPieces={pieceSets[userData?.pieceset || "Wikipedia"]}
              />
            </Box>
            {result !== "incorrect" && result !== null && (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                justifyContent="flex-start"
                textAlign="left"
                sx={{ pt: 2, width: "100%" }}
              >
                <Tooltip title="Reset the game">
                  <span>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setSelectedMoveId("root");
                        setGame(new Chess());
                      }}
                      style={{ marginRight: 10 }}
                      disabled={selectedMoveId === "root"}
                      sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                    >
                      <ReplayIcon />
                    </Button>
                  </span>
                </Tooltip>

                <Tooltip title="Go to previous move">
                  <span>
                    <Button
                      variant="contained"
                      onClick={handleBack}
                      disabled={selectedMoveId === "root"}
                      style={{ marginRight: 10 }}
                      sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                    >
                      <KeyboardDoubleArrowLeftIcon />
                    </Button>
                  </span>
                </Tooltip>

                <Tooltip title="Go to next move">
                  <span>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      disabled={moves[selectedMoveId]?.next === null}
                      style={{ marginRight: 10 }}
                      sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                    >
                      <KeyboardDoubleArrowRightIcon />
                    </Button>
                  </span>
                </Tooltip>

                <Tooltip title="Toggle board orientation">
                  <span>
                    <Button
                      variant="contained"
                      onClick={() =>
                        setBoardOrientation((prev) =>
                          prev === "white" ? "black" : "white"
                        )
                      }
                      sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                    >
                      <SwapVertRoundedIcon />
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            )}
          </Card>
          {/* SVG Layer for Arrows & Highlights */}
          {result !== "incorrect" && result !== null && (
            <svg
              style={{
                position: "absolute",
                top: chessboardRef.current?.offsetTop || 0,
                left: chessboardRef.current?.offsetLeft || 0,
                width: chessboardRef.current?.offsetWidth || "100%",
                height: chessboardRef.current?.offsetHeight || "100%",
                pointerEvents: "none",
                overflow: "visible",
                zIndex: 500,
              }}
            >
              <defs>
                {arrows.map(([start, end, type], index) => (
                  <marker
                    key={`arrowhead-${index}`}
                    id={`arrowhead-${index}`}
                    markerWidth="10"
                    markerHeight="7"
                    refX="10"
                    refY="3.5"
                    orient="auto"
                  >
                    <polygon
                      points="0 0, 10 3.5, 0 7"
                      fill={highlightColors[type]}
                    />
                  </marker>
                ))}
              </defs>
              {arrows.map(([start, end, type], index) => (
                <Arrow
                  key={index}
                  start={start}
                  end={end}
                  color={highlightColors[type]}
                  markerId={`arrowhead-${index}`}
                />
              ))}
              {highlights.map(([square, type], index) => (
                <rect
                  key={`highlight-${type}-${index}`}
                  x={`${(square.charCodeAt(0) - 97) * 12.5}%`}
                  y={`${(8 - parseInt(square[1], 10)) * 12.5}%`}
                  width="12.5%"
                  height="12.5%"
                  rx="1%"
                  ry="1%"
                  stroke={highlightColors[type]}
                  strokeWidth="3"
                  fill="none"
                  opacity="0.9"
                />
              ))}
              {renderAnnotation()}
            </svg>
          )}
          <Box
            sx={{
              position: "fixed",
              top: 20,
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 1000,
            }}
          ></Box>
          {/* Chessboard Buttons */}
        </Box>

        <Card
          id="trainer"
          sx={{
            position: "relative",
            backgroundColor: colors.background[100], // Card background color
            color: colors.black[900], // Text color
            backgroundImage: "none",
            width: "100%",
            clipPath:
              "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
            "::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "transparent",
              border: "1px solid rgba(0, 0, 0, 0.2)",
              clipPath:
                "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
              zIndex: -1,
              pointerEvents: "none",
            },
            p: 2,
            mt: 2,
          }}
        >
          <Box sx={{ width: "100%" }}>
            {/* First row - Select elements */}
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: 1, pb: 1 }}
            >
              <Select
                id="trainer-type"
                value={trainingMode}
                onChange={(e) => {
                  setTrainingMode(e.target.value);
                  setResult(null);
                }}
                fullWidth
                variant="outlined"
              >
                <MenuItem value="positionTrainer">Position Trainer</MenuItem>
                <MenuItem value="repertoireTrainer">
                  Repertoire Trainer
                </MenuItem>
              </Select>
              <Select
                id="trainer-repertoire"
                sx={{ width: "100%" }}
                value={selectedRepertoireId}
                onChange={handleRepertoireChange}
              >
                {Object.entries(repertoires)
                  .sort(([, a], [, b]) => (a.order || 0) - (b.order || 0)) // Sort by order field
                  .map(([repId, repData]) => (
                    <MenuItem key={repId} value={repId}>
                      {repData.title}
                    </MenuItem>
                  ))}
              </Select>
            </Box>

            {/* Second row - Buttons */}
            <Box sx={{ display: "flex", gap: 1, pb: 1 }}>
              <Select
                id="trainer-color"
                value={boardOrientation}
                onChange={(e) => setBoardOrientation(e.target.value)}
              >
                <MenuItem value="white">White</MenuItem>
                <MenuItem value="black">Black</MenuItem>
              </Select>
              <Button
                id="trainer-start"
                variant="contained"
                color="secondary"
                onClick={handleTrainClick}
                disabled={!selectedRepertoireName}
                startIcon={<FitnessCenterIcon />}
                sx={{
                  backgroundColor: colors.green[100],
                  "&:hover": {
                    backgroundColor: colors.green[200],
                  },
                }}
              >
                Train
              </Button>

              {quit && (
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleQuit}
                  startIcon={<CancelOutlinedIcon />}
                  sx={{
                    backgroundColor: colors.red[500],
                    "&:hover": {
                      backgroundColor: colors.red[600],
                    },
                  }}
                >
                  Quit
                </Button>
              )}
            </Box>
          </Box>
        </Card>
      </Box>
      <Box
        sx={{
          p: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "65%",
          mb: 10,
        }}
      >
        {showAlert && !showCard && (
          <Fade in={showAlert} timeout={500} mountOnEnter unmountOnExit>
            <Alert
              variant="outlined"
              sx={{ width: "100%", mt: 2 }}
              severity={
                result === "CorrectML" || result === "CorrectV"
                  ? "success"
                  : "error"
              }
              onClose={() => setShowAlert(false)}
            >
              {result === "CorrectML"
                ? "Correct Move - Main Line!"
                : result === "CorrectV"
                ? "Correct Move - Variation!"
                : "Incorrect Move!"}
            </Alert>
          </Fade>
        )}
        <TrainingMovesCard
          moves={moves}
          showCard={showCard}
          repertoires={repertoires}
          selectedRepertoireId={selectedRepertoireId}
          selectedMoveId={selectedMoveId}
          setSelectedMoveId={setSelectedMoveId}
        />
      </Box>
    </Box>
  );
};

export default PositionTrainerCard;
