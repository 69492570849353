import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Stack,
  Tabs,
  Tab,
  Typography,
  TextField,
  useTheme,
  CircularProgress,
} from "@mui/material";
import Dropzone from "react-dropzone";
import { Chess } from "chess.js";
import { ref, set } from "firebase/database";
import { rt } from "../../../../config/firebase";
import { v4 as uuidv4 } from "uuid";
import { tokens } from "../../../../styles/theme";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { openings } from "../../../../data/eco/eco";

import useDialogFocused from "./useDialogFocused";
import { useAlert } from "../../../../context/AlertProvider";

const ImportUserGamesDialog = ({
  open,
  onClose,
  userData,
  settings,
  totalGames,
  setTotalGames,
  setUserGames,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [pgnText, setPgnText] = useState("");
  const [pastedPgnTextGames, setPastedPgnTextGames] = useState(0);
  const [filePgnText, setFilePgnText] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filePgnGames, setFilePgnGames] = useState(null);

  // Chess.com & Lichess Inputs
  const [chesscomUsername, setChesscomUsername] = useState("");
  const [chesscomYear, setChesscomYear] = useState("");
  const [chesscomMonth, setChesscomMonth] = useState("");

  const [lichessUsername, setLichessUsername] = useState("");
  const [lichessNumGames, setLichessNumGames] = useState(10);
  const [lichessPerfType, setLichessPerfType] = useState("All");

  const userId = userData?.uid || "";

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const showAlert = useAlert();

  /** Handle Tab Change */
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  /** Handle File Drop */
  const handleFileDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setSelectedFile(file);
      setFilePgnGames(null);

      // Read file content
      const reader = new FileReader();
      reader.onload = (e) => {
        setFilePgnText(e.target.result);
        const numberOfGames = e.target.result
          .trim()
          .split(/\n\s*\n(?=\[Event)/g) // Matches double newlines with optional spaces before a new game
          .filter((game) => game.trim().length > 0).length;
        setFilePgnGames(numberOfGames);
      };
      reader.readAsText(file);
    }
  };

  /** Generate Final FEN and Move History */
  const getHistory = (pgn) => {
    const chess = new Chess();
    chess.loadPgn(pgn);

    return {
      fen: chess.fen(), // Final position FEN
      history: chess.history(), // Move history
    };
  };

  const colorMap = {
    R: "shift", // Red
    G: "ctrl", // Green
    Y: "alt", // Yellow
    B: "default", // Blue
  };

  /**
   * 🎯 Parses [%cal ...] into a structured array of arrows
   * @param {string} calString - The raw [%cal ...] annotation
   * @returns {Array} - Array of formatted arrows
   */
  const formatArrows = (calString) => {
    if (!calString.startsWith("[%cal")) return [];

    const arrowAnnotationsRaw = calString
      .replace("[%cal", "")
      .replace("]", "")
      .trim();

    const arrowAnnotations = arrowAnnotationsRaw.includes(",")
      ? arrowAnnotationsRaw.split(",")
      : [arrowAnnotationsRaw];

    return arrowAnnotations
      .map((arrow, index) => {
        if (arrow.length < 5) {
          return null;
        }

        const colorCode = arrow[0]; // First character is the color
        const startSquare = arrow.slice(1, 3); // Next two chars are start square
        const endSquare = arrow.slice(3, 5); // Next two chars are end square

        return [startSquare, endSquare, colorMap[colorCode] || "default"];
      })
      .filter(Boolean); // Remove any null values
  };

  /**
   * 🔳 Parses [%csl ...] into a structured array of highlighted squares
   * @param {string} cslString - The raw [%csl ...] annotation
   * @returns {Array} - Array of formatted highlight squares
   */
  const formatHighlights = (cslString) => {
    if (!cslString.startsWith("[%csl")) return [];

    const highlightAnnotationsRaw = cslString
      .replace("[%csl", "")
      .replace("]", "")
      .trim();

    const highlightAnnotations = highlightAnnotationsRaw.includes(",")
      ? highlightAnnotationsRaw.split(",")
      : [highlightAnnotationsRaw];

    return highlightAnnotations
      .map((highlight, index) => {
        if (highlight.length < 3) {
          return null;
        }

        const colorCode = highlight[0]; // First character is the color
        const square = highlight.slice(1, 3); // Next two chars are the square

        return [square, colorMap[colorCode] || "default"];
      })
      .filter(Boolean); // Remove any null values
  };

  const formatTimeControl = (timeControl) => {
    if (!timeControl || timeControl === "-") {
      return "No Time Control";
    }

    // Check for correspondence format (e.g., "1/86400")
    if (timeControl.includes("/")) {
      const parts = timeControl.split("/");
      const movesPerPeriod = parseInt(parts[0], 10);
      const daysPerMove = Math.round(parseInt(parts[1], 10) / 86400); // Convert seconds to whole days

      return movesPerPeriod === 1
        ? `1 Move per ${daysPerMove} Day${daysPerMove > 1 ? "s" : ""}`
        : `${movesPerPeriod} Moves per ${daysPerMove} Day${
            daysPerMove > 1 ? "s" : ""
          }`;
    }

    // Standard format (e.g., "600+10")
    const parts = timeControl.split("+");
    const baseTime = parseInt(parts[0], 10) || 0; // Base time in seconds
    const increment = parts.length > 1 ? parseInt(parts[1], 10) : 0; // Increment in seconds

    // Convert base time to human-readable format
    let baseTimeStr;
    if (baseTime >= 3600) {
      baseTimeStr = `${Math.round(baseTime / 3600)} Hour${
        baseTime >= 7200 ? "s" : ""
      }`;
    } else if (baseTime >= 60) {
      baseTimeStr = `${Math.round(baseTime / 60)} Min${
        baseTime >= 120 ? "s" : ""
      }`;
    } else {
      baseTimeStr = `${baseTime} Sec${baseTime !== 1 ? "s" : ""}`;
    }

    // Ensure increment is always displayed
    const incrementStr = ` + ${increment} Sec${increment !== 1 ? "s" : ""}`;

    return `${baseTimeStr}${incrementStr}`;
  };

  const getTimeControlLabel = (timeControl) => {
    if (!timeControl || timeControl === "-") {
      return "No Time Control";
    }

    if (timeControl.includes("/")) {
      return "Correspondence";
    }

    const parts = timeControl.split("+");
    const baseTime = parseInt(parts[0], 10);
    const increment = parts.length > 1 ? parseInt(parts[1], 10) : 0;

    const estimatedTotalTime = baseTime + increment * 40;

    if (estimatedTotalTime < 30) return "UltraBullet";
    if (estimatedTotalTime < 150) return "Bullet";
    if (estimatedTotalTime < 480) return "Blitz";
    if (estimatedTotalTime < 1500) return "Rapid";
    return "Classical";
  };

  const processPGN = (pgn) => {
    // Extract metadata fields before removing them
    const metadata = {};

    // Extract only the header section (before moves)
    const headerSection = pgn.split("\n\n")[0]; // PGN headers are separated from moves by a blank line

    const metadataRegex = /^\[(\w+)\s+"([^"]*)"\]/gm;
    let match;

    while ((match = metadataRegex.exec(headerSection)) !== null) {
      metadata[match[1]] = match[2]; // Store key-value pair
    }

    pgn = pgn.replace(/\[(?!%cal|%csl)[^\]]*\]\s*/g, ""); // Remove metadata, but keep [%cal] and [%csl]

    let commentMap = {};
    let commentIndex = 0;

    // ✅ Extract and group comments by move
    pgn = pgn.replace(/\{([^{}]*)\}/g, (match, comment) => {
      let key = `COMMENT_${commentIndex++}`;
      if (!commentMap[key]) {
        commentMap[key] = [];
      }
      commentMap[key].push(comment.trim()); // Store all comments as an array
      return key; // Replace comment with a reference key
    });

    pgn = pgn.replace(/\$\d+/g, ""); // Remove NAGs
    pgn = pgn.replace(/\d+\.\s*/g, ""); // Remove move numbers
    pgn = pgn.replace(/[^a-zA-Z0-9+#\-()_ \s]/g, ""); // Remove unwanted characters

    const tokens = pgn
      .replace(/([()])/g, " $1 ") // Add spaces around parentheses
      .trim()
      .split(/\s+/) // Split by whitespace
      .filter((token) => !/^(1-0|0-1|1\/2-1\/2|\*|12-12)$/.test(token)); // Remove results

    // ✅ Build move tree with multiple comments, arrows & highlights
    const buildMoveTree = (moves, index = 0, parent = null) => {
      let moveTree = {};
      let currentMoveId = parent || "root";
      let lastMainlineMove = parent;
      let variationParent = parent;
      let lastMoveId = null; // Track the last processed move
      let accumulatedComments = []; // Collect comments for the last move

      while (index < moves.length) {
        let move = moves[index];

        if (move === "(") {
          let depth = 1;
          let variationMoves = [];
          index++;

          while (index < moves.length && depth > 0) {
            if (moves[index] === "(") depth++;
            else if (moves[index] === ")") depth--;

            if (depth > 0) variationMoves.push(moves[index]);
            index++;
          }

          if (variationMoves.length > 0) {
            let variationTree = buildMoveTree(
              variationMoves,
              0,
              variationParent
            );
            let variationRootId = Object.keys(variationTree)[0];

            if (variationRootId && moveTree[lastMainlineMove]) {
              moveTree[lastMainlineMove].variations.push(variationRootId);
            }

            moveTree = { ...moveTree, ...variationTree };
          }
          continue;
        }

        if (move.startsWith("COMMENT_")) {
          if (commentMap[move]) {
            accumulatedComments.push(...commentMap[move]); // Store comments for the next move
          }
          index++;
          continue;
        }

        if (!move.trim() || move === "..") {
          index++;
          continue;
        }

        // ✅ If there's a last move, assign its comments *before creating the next move*
        if (lastMoveId && accumulatedComments.length > 0) {
          let firstComment = null;
          let arrowsData = null;
          let highlightsData = null;

          accumulatedComments.forEach((comment) => {
            // Check if the comment contains [%cal] (arrows)
            const calMatch = comment.match(/\[%cal[^\]]*\]/);
            if (calMatch) {
              arrowsData = formatArrows(calMatch[0]); // Convert to structured data
              comment = comment.replace(calMatch[0], "").trim(); // Remove [%cal] from comment
            }

            // Check if the comment contains [%csl] (highlight squares)
            const cslMatch = comment.match(/\[%csl[^\]]*\]/);
            if (cslMatch) {
              highlightsData = formatHighlights(cslMatch[0]); // Convert to structured data
              comment = comment.replace(cslMatch[0], "").trim(); // Remove [%csl] from comment
            }

            // If it's pure text (not empty after cleanup), treat it as a regular comment
            if (comment.length > 0) {
              firstComment = firstComment
                ? firstComment + " " + comment
                : comment;
            }
          });

          // Assign processed values to moveTree
          moveTree[lastMoveId].comment = firstComment || null;
          moveTree[lastMoveId].arrows = arrowsData || null;
          moveTree[lastMoveId].highlightSquares = highlightsData || null;

          // Reset for the next move
          accumulatedComments = [];
        }

        const moveId = uuidv4();
        let moveData = {
          id: moveId,
          san: move,
          next: null,
          variations: [],
          parent: currentMoveId,
          comments: null, // Will be set before processing next move
          arrows: null,
          highlightSquares: [],
        };

        if (currentMoveId !== "root" && moveTree[currentMoveId]) {
          moveTree[currentMoveId].next = moveId;
        }

        moveTree[moveId] = moveData;
        lastMoveId = moveId; // Update last processed move
        variationParent = lastMainlineMove;
        currentMoveId = moveId;
        lastMainlineMove = moveId;

        index++;
      }

      // ✅ Assign any remaining comments to the last move
      if (lastMoveId && accumulatedComments.length > 0) {
        let firstComment = null;
        let arrowsData = null;
        let highlightsData = null;

        accumulatedComments.forEach((comment) => {
          // Check if the comment contains [%cal] (arrows)
          const calMatch = comment.match(/\[%cal[^\]]*\]/);
          if (calMatch) {
            arrowsData = formatArrows(calMatch[0]); // Convert to structured data
            comment = comment.replace(calMatch[0], "").trim(); // Remove [%cal] from comment
          }

          // Check if the comment contains [%csl] (highlight squares)
          const cslMatch = comment.match(/\[%csl[^\]]*\]/);
          if (cslMatch) {
            highlightsData = formatHighlights(cslMatch[0]); // Convert to structured data
            comment = comment.replace(cslMatch[0], "").trim(); // Remove [%csl] from comment
          }

          // If it's pure text (not empty after cleanup), treat it as a regular comment
          if (comment.length > 0) {
            firstComment = firstComment
              ? firstComment + " " + comment
              : comment;
          }
        });

        // Assign processed values to moveTree
        moveTree[lastMoveId].comment = firstComment || null;
        moveTree[lastMoveId].arrows = arrowsData || null;
        moveTree[lastMoveId].highlightSquares = highlightsData || null;
      }

      return moveTree;
    };

    const moves = buildMoveTree(tokens);

    return {
      id: uuidv4(),
      boardOrientation: "white",
      createdAt: Date.now(),
      event: metadata["Event"] || "Unknown Event",
      site: metadata["Site"] || "Unknown Site",
      date: metadata["Date"] || "Unknown Date",
      round: metadata["Round"] || "-",
      white: metadata["White"] || "Unknown",
      whiteElo: metadata["WhiteElo"] || "0",
      black: metadata["Black"] || "Unknown",
      blackElo: metadata["BlackElo"] || "0",
      result: metadata["Result"] || "*",
      eco: metadata["ECO"] || "Unknown ECO",
      opening: metadata["Opening"] || "Unknown Opening",
      timeControl: metadata["TimeControl"] || "Unknown Time Control",
      termination: metadata["Termination"] || "Unknown Termination",
      link: metadata["Link"] || "Unknown Link",
      moves: {
        root: {
          san: "",
          next: Object.keys(moves)[0] || null,
          variations: [],
          parent: null,
        },
        ...moves,
      },
    };
  };

  /**
   * Finds the chess opening name based on the game's move history.
   *
   * @param {Array} gameHistory - An array of SAN (Standard Algebraic Notation) strings representing the game's move history.
   * @returns {Object|null} An object representing the matched opening, including its name, PGN, FEN, and transposition status, or null if no match is found.
   */
  function findOpening(gameHistory) {
    const maxMoves = 15; // Limit the search to the first 15 moves to reduce computation
    const chess = new Chess(); // Create a new instance of the Chess.js library

    // Variable to store the last matched opening
    let lastMatchedOpening = null;

    /**
     * Builds a properly formatted PGN (Portable Game Notation) string from a history of moves.
     *
     * @param {Array} history - An array of SAN strings representing a sequence of moves.
     * @returns {string} A formatted PGN string.
     */
    const buildPGN = (history) => {
      let pgn = ""; // Initialize an empty PGN string

      for (let i = 0; i < history.length; i++) {
        if (i % 2 === 0) {
          // Add the move number for White's turn
          pgn += `${Math.floor(i / 2) + 1}. `;
        }
        pgn += `${history[i]} `; // Append the move to the PGN string
      }
      return pgn.trim(); // Remove any trailing whitespace
    };

    /**
     * Extracts the position part of a FEN (Forsyth-Edwards Notation) string.
     * The position part includes only the piece placement on the board.
     *
     * @param {string} fen - A full FEN string.
     * @returns {string} The position part of the FEN string.
     */
    const extractPositionFromFEN = (fen) => fen.split(" ")[0]; // Split by space and return the first part

    // Iterate through the moves in the game's history, up to the maximum allowed moves
    for (let i = 0; i < Math.min(gameHistory.length, maxMoves); i++) {
      const currentMoves = buildPGN(gameHistory.slice(0, i + 1)); // Build PGN for the current move sequence
      chess.reset(); // Reset the board to the starting position
      chess.loadPgn(currentMoves); // Load the PGN string into the chess instance

      // Check for a direct PGN match in the openings dataset
      const pgnMatch = openings.find((opening) => opening.pgn === currentMoves);

      if (pgnMatch) {
        lastMatchedOpening = {
          ...pgnMatch, // Include all details of the matched opening
          transposition: false, // Indicates a direct match with no transposition
        };
      } else {
        // If no PGN match, check for a FEN match (possible transposition)
        const currentFEN = extractPositionFromFEN(chess.fen()); // Get the FEN of the current position
        const fenMatch = openings.find(
          (opening) => extractPositionFromFEN(opening.fen) === currentFEN
        );
        if (fenMatch) {
          lastMatchedOpening = {
            ...fenMatch, // Include all details of the matched opening
            transposition: true, // Indicates a match via transposition
          };
        }
      }
    }

    // Return the last matched opening or null if no match was found
    return lastMatchedOpening;
  }

  const formatMovesToPGN = (moves) => {
    if (!moves || moves.length === 0) return "";

    let pgn = "";
    for (let i = 0; i < moves.length; i += 2) {
      const moveNumber = Math.floor(i / 2) + 1; // Move numbers start from 1
      const whiteMove = moves[i];
      const blackMove = moves[i + 1] || ""; // Black move might be missing if odd length

      pgn += `${moveNumber}. ${whiteMove} ${blackMove} `;
    }

    return pgn.trim(); // Remove trailing space
  };

  /** Save Game to Firebase */
  const savePGN = async (pgn, source) => {
    try {
      const { fen, history } = getHistory(pgn);
      const processedPGN = processPGN(pgn); // Extract metadata & moves
      const opening = findOpening(history);

      let gameId = processedPGN.id; // Default to processed PGN's generated ID

      // If source is Lichess, extract the game ID from the Site field
      if (source === "Lichess" && processedPGN.site) {
        const siteParts = processedPGN.site.split("/");
        gameId = siteParts[siteParts.length - 1]; // Extract last token (game ID)
      }

      // If source is Lichess, extract the game ID from the Site field
      if (source === "Chess.com" && processedPGN.link) {
        const linkParts = processedPGN.link.split("/");
        gameId = linkParts[linkParts.length - 1]; // Extract last token (game ID)
      }

      const newGame = {
        id: gameId, // Use the generated ID from processPGN
        source: source,
        fen: fen,
        eco: opening.eco,
        openingName: opening.name,
        createdAt: processedPGN.createdAt, // Use the same timestamp

        // PGN Metadata
        event: processedPGN.event,
        site: processedPGN.site,
        date: processedPGN.date,
        round: processedPGN.round,
        white: processedPGN.white,
        whiteElo: processedPGN.whiteElo,
        black: processedPGN.black,
        blackElo: processedPGN.blackElo,
        result: processedPGN.result,
        startingLine: formatMovesToPGN(history.slice(0, 10)),
        numberOfMoves: history.length,
        timeControl: formatTimeControl(processedPGN.timeControl),
        timeControlLabel: getTimeControlLabel(processedPGN.timeControl),
        termination: processedPGN.termination,

        // Moves data
        boardOrientation: processedPGN.boardOrientation,
        moves: processedPGN.moves, // Store the parsed move tree
      };

      const gameRef = ref(rt, `users/${userId}/games/${newGame.id}`);

      await set(gameRef, newGame);
      // ✅ Add the new game to `setUserGames`
      setUserGames((prevGames) => [...prevGames, newGame]); // ✅ Add game to the end

      setTotalGames((prevCount) => prevCount + 1);
    } catch (error) {}
  };

  /** Handle PGN Import */
  const handlePgnImport = async () => {
    if (!pgnText || pgnText.trim().length === 0) {
      showAlert("Invalid PGN format. Please paste a valid PGN text.", "error");
      return;
    }

    // Use the same logic to split pasted PGNs
    const games = pgnText
      .trim()
      .split(/\n\s*\n(?=\[Event)/g) // Matches double newlines with optional spaces before a new game
      .filter((game) => game.trim().length > 0); // Remove any empty entries

    if (games.length === 0) {
      showAlert("No valid PGN games found.", "error");
      return;
    }

    try {
      for (const pgn of games) {
        if (totalGames >= settings.limitGames) {
          showAlert(
            `Games have been added up to the limit (${settings.limitGames}). Any games beyond the threshold were skipped. Please upgrade to add more games.`,
            "warning"
          );
          onClose();
          return;
        }
        await savePGN(pgn, "Pasted PGN"); // Import each PGN separately
      }

      onClose(); // Close modal/dialog after successful import
    } catch (error) {
      showAlert("Failed to import PGN text.", "error");
    }
  };

  const handleFileImport = async () => {
    if (!filePgnText || filePgnText.trim().length === 0) {
      showAlert("PGN file is empty.", "error");
      return;
    }

    // Use a more reliable way to split PGNs while handling spacing variations
    const games = filePgnText
      .trim()
      .split(/\n\s*\n(?=\[Event)/g) // Matches double newlines with optional spaces before a new game
      .filter((game) => game.trim().length > 0); // Remove any empty entries

    if (games.length === 0) {
      showAlert("No valid PGN games found.", "error");
      return;
    }

    try {
      for (const pgnText of games) {
        if (totalGames >= settings.limitGames) {
          showAlert(
            `Games have been added up to the limit (${settings.limitGames}). Any games beyond the threshold were skipped. Please upgrade to add more games.`,
            "warning"
          );
          onClose();
          return;
        }
        await savePGN(pgnText, "PGN File Import"); // Import each PGN separately
      }
      onClose(); // Close modal/dialog after successful import
    } catch (error) {
      showAlert("Failed to import PGN file.", "error");
    }
  };

  /** Fetch Chess.com Games */
  const fetchChesscomGames = async () => {
    setLoading(true);

    try {
      const url = `https://api.chess.com/pub/player/${chesscomUsername}/games/${chesscomYear}/${chesscomMonth}`;

      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(
          `Failed to fetch games. HTTP Status: ${response.status}`
        );
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = "";

      let shouldStop = false;
      while (!shouldStop) {
        const { value, done } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        buffer += chunk;

        try {
          const parsedData = JSON.parse(buffer);

          let localTotalGames = totalGames;

          if (parsedData.games?.length) {
            for (const game of parsedData.games) {
              if (game.pgn) {
                if (localTotalGames >= settings.limitGames) {
                  showAlert(
                    `Games have been added up to the limit (${settings.limitGames}). Any games beyond the threshold were skipped. Please upgrade to add more games.`,
                    "warning"
                  );
                  onClose();
                  shouldStop = true; // ✅ Prevents further execution
                  break;
                }
                await savePGN(game.pgn, "Chess.com");
                localTotalGames = localTotalGames + 1;
              }
            }
          } else {
            showAlert("No games found in response.", "error");
          }

          break; // Chess.com API returns all games in one JSON object, so we break after processing.
        } catch (err) {}
      }

      onClose();
    } catch (error) {
      showAlert(
        "Failed to fetch Chess.com games. Check username & date.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const convertLichessGameToPGN = (gameData) => {
    if (!gameData.moves) {
      return "";
    }

    const metadata = {
      Event: "Lichess Game",
      Site: "https://lichess.org/" + gameData.id,
      Date: gameData.createdAt
        ? new Date(gameData.createdAt).toISOString().split("T")[0]
        : "????.??.??",
      Round: "-",
      White: gameData.players.white?.user?.name || "Unknown",
      Black: gameData.players.black?.user?.name || "Unknown",
      WhiteElo: gameData.players.white?.rating || "?",
      BlackElo: gameData.players.black?.rating || "?",
      Result:
        gameData.winner === "white"
          ? "1-0"
          : gameData.winner === "black"
          ? "0-1"
          : "1/2-1/2",
      ECO: gameData.eco || "Unknown ECO",
      Opening: gameData.opening || "Unknown Opening",
      TimeControl: gameData.clock?.initial + "+" + gameData.clock?.increment,
      Termination: gameData.status || "Unknown Termination",
    };

    // Build PGN Header
    let pgn = Object.entries(metadata)
      .map(([key, value]) => `[${key} "${value}"]`)
      .join("\n");

    // Add Moves
    const moveList = gameData.moves.split(" ");
    let moveNumber = 1;
    let pgnMoves = "";
    for (let i = 0; i < moveList.length; i++) {
      if (i % 2 === 0) pgnMoves += `${moveNumber}. `;
      pgnMoves += moveList[i] + " ";
      if (i % 2 === 1) moveNumber++;
    }

    pgn += `\n\n${pgnMoves.trim()} ${metadata.Result}`;

    return pgn;
  };

  const fetchLichessGames = async () => {
    setLoading(true);

    try {
      const url =
        `https://lichess.org/api/games/user/${lichessUsername}?max=${lichessNumGames}&format=ndjson` +
        (lichessPerfType !== "All" ? `&perfType=${lichessPerfType}` : "");

      const response = await fetch(url, {
        headers: { Accept: "application/x-ndjson" },
      });

      if (!response.ok) {
        throw new Error(
          `Failed to fetch games. HTTP Status: ${response.status}`
        );
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = "";

      let shouldStop = false;
      let localTotalGames = totalGames;

      while (!shouldStop) {
        const { value, done } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });

        buffer += chunk;
        let lines = buffer.split("\n");
        buffer = lines.pop(); // Keep last incomplete line in buffer

        for (const line of lines) {
          if (localTotalGames >= settings.limitGames) {
            showAlert(
              `Games have been added up to the limit (${settings.limitGames}). Any games beyond the threshold were skipped. Please upgrade to add more games.`,
              "warning"
            );
            onClose();
            shouldStop = true; // ✅ Prevents further execution
            break;
          }
          if (line.trim()) {
            try {
              const gameData = JSON.parse(line);

              // ✅ Convert Lichess JSON to a PGN format
              const reconstructedPGN = convertLichessGameToPGN(gameData);

              savePGN(reconstructedPGN, "Lichess");
              localTotalGames = localTotalGames + 1;
            } catch (err) {}
          }
        }
      }

      onClose();
    } catch (error) {
      showAlert(
        "Failed to fetch Lichess games. Check username or network.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.8)", // Darker background with 80% opacity
        },
        "& .MuiPaper-root": {
          position: "relative", // Required for pseudo-element positioning
          backgroundColor: colors.background[100], // Card background color
          backgroundImage: "none",
          color: colors.black[900], // Text color
          clipPath:
            "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
          "::before": {
            content: '""', // Required for pseudo-element
            position: "absolute", // Position relative to the parent
            top: 0, // Align with the top of the parent
            left: 0, // Align with the left of the parent
            width: "100%", // Match the width of the parent
            height: "100%", // Match the height of the parent
            backgroundColor: "transparent", // Transparent background to show the card
            border: "1px solid rgba(0, 0, 0, 0.2)", // Red outline
            clipPath:
              "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
            zIndex: -1, // Place behind the card content
            pointerEvents: "none", // Ensure the outline does not interfere with interactions
          },
          p: 1, // Padding for the card content
        },
      }}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      {useDialogFocused(open)}
      <DialogTitle>
        <Stack direction="row" alignItems="center" spacing={1}>
          <FileUploadOutlinedIcon />
          <Typography variant="h6">Import Games</Typography>
        </Stack>
      </DialogTitle>

      <Divider sx={{ backgroundColor: colors.green[100], height: "1.5px" }} />
      <DialogContent>
        <Tabs
          sx={{
            height: "20px",
            "& .MuiTab-root": {
              color: colors.black[900], // Text color for unselected tabs
              transition: "color 0.3s, border-color 0.3s", // Smooth transition for hover/focus effects
              minWidth: "50px", // Set tab width
              textAlign: "center", // Center align text or icon
              "&:hover": {
                color: colors.black[700], // Slightly lighter on hover
              },
            },
            "& .Mui-selected": {
              color: colors.black[900], // ✅ Fix: Sets active tab text color
              fontWeight: "bold", // ✅ Optional: Make selected text bold
            },
            "& .MuiTab-root.Mui-selected": {
              color: colors.black[900], // ✅ Ensure selected tab text color overrides MUI default
            },
            "& .MuiTabs-indicator": {
              backgroundColor: colors.green[900], // ✅ Active tab underline color
              height: "1.5px",
            },
            mt: 1,
            mb: 1,
          }}
          value={tabIndex}
          onChange={handleTabChange}
        >
          <Tab label="Paste PGN" />
          <Tab label="PGN File" />
          <Tab label="Chess.com" />
          <Tab label="Lichess.org" />
        </Tabs>

        {tabIndex === 0 && (
          <TextField
            label="Paste PGN"
            multiline
            rows={6}
            fullWidth
            margin="normal"
            value={pgnText}
            onChange={(e) => {
              const text = e.target.value;
              setPgnText(text);
              const gameCount = (text.match(/\[Event/g) || []).length;
              setPastedPgnTextGames(gameCount);
            }}
          />
        )}

        {tabIndex === 1 && (
          <Dropzone
            onDrop={handleFileDrop}
            accept={{ "application/x-chess-pgn": [".pgn"] }}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps()}
                style={{
                  border: "2px dashed #ccc",
                  padding: "20px",
                  textAlign: "center",
                  marginTop: "20px",
                  cursor: "pointer",
                }}
              >
                <input {...getInputProps()} />
                {selectedFile ? (
                  <p>{selectedFile.name}</p>
                ) : (
                  <p>Drag & drop a PGN file here, or click to select a file</p>
                )}
              </div>
            )}
          </Dropzone>
        )}

        {tabIndex === 2 && (
          <Stack spacing={2} sx={{ mt: 2 }}>
            <TextField
              label="Chess.com Username"
              value={chesscomUsername}
              onChange={(e) => setChesscomUsername(e.target.value)}
              fullWidth
            />
            <TextField
              label="Year (YYYY)"
              value={chesscomYear}
              onChange={(e) => setChesscomYear(e.target.value)}
              fullWidth
            />
            <TextField
              label="Month (MM)"
              value={chesscomMonth}
              onChange={(e) => setChesscomMonth(e.target.value)}
              fullWidth
            />
          </Stack>
        )}

        {tabIndex === 3 && (
          <Stack spacing={2} sx={{ mt: 2 }}>
            <TextField
              label="Lichess Username"
              value={lichessUsername}
              onChange={(e) => setLichessUsername(e.target.value)}
              fullWidth
              autoComplete="off"
            />
            <TextField
              label="Number of Games"
              value={lichessNumGames}
              type="number"
              onChange={(e) => setLichessNumGames(e.target.value)}
              fullWidth
            />
            <FormControl fullWidth>
              <InputLabel>Time Control</InputLabel>
              <Select
                value={lichessPerfType}
                onChange={(e) => setLichessPerfType(e.target.value)}
                sx={{ height: "50px" }}
              >
                {[
                  "All",
                  "ultraBullet",
                  "bullet",
                  "blitz",
                  "rapid",
                  "classical",
                  "correspondence",
                ].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option.charAt(0).toUpperCase() + option.slice(1)}{" "}
                    {/* Capitalizes first letter */}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        )}
      </DialogContent>
      <Divider />
      <DialogActions sx={{ mt: 1 }}>
        {tabIndex === 0 && (
          <>
            {pastedPgnTextGames > 0 ? (
              <Typography>{pastedPgnTextGames} Games to be Imported</Typography>
            ) : null}
            <Button
              onClick={handlePgnImport}
              variant="contained"
              sx={{
                color: "white",
                backgroundColor: colors.green[100],
                "&:hover": {
                  backgroundColor: colors.green[200],
                },
              }}
              startIcon={<FileUploadOutlinedIcon />}
            >
              Import
            </Button>
          </>
        )}
        {tabIndex === 1 && (
          <>
            {filePgnGames > 0 ? (
              <Typography>{filePgnGames} Games to be Imported</Typography>
            ) : null}

            <Button
              onClick={handleFileImport}
              variant="contained"
              sx={{
                color: "white",
                backgroundColor: colors.green[100],
                "&:hover": {
                  backgroundColor: colors.green[200],
                },
              }}
              startIcon={<FileUploadOutlinedIcon />}
            >
              Import
            </Button>
          </>
        )}

        {tabIndex === 2 && (
          <Button
            onClick={fetchChesscomGames}
            disabled={loading}
            variant="contained"
            sx={{
              color: "white",
              backgroundColor: colors.green[100],
              "&:hover": {
                backgroundColor: colors.green[200],
              },
            }}
            startIcon={<FileUploadOutlinedIcon />}
          >
            {loading ? <CircularProgress size={20} /> : "Import"}
          </Button>
        )}

        {tabIndex === 3 && (
          <Button
            onClick={fetchLichessGames}
            disabled={loading}
            variant="contained"
            sx={{
              color: "white",
              backgroundColor: colors.green[100],
              "&:hover": {
                backgroundColor: colors.green[200],
              },
            }}
            startIcon={<FileUploadOutlinedIcon />}
          >
            {loading ? <CircularProgress size={20} /> : "Import"}
          </Button>
        )}

        <Button onClick={onClose} variant="contained">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportUserGamesDialog;
