import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  ToggleButton,
  ToggleButtonGroup,
  DialogActions,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../../../styles/theme";

import { useState } from "react";

import { deleteRepertoire } from "../../../../config/firebaseDB";
import { ref, set } from "firebase/database";
import { rt } from "../../../../config/firebase";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

import { useUser } from "../../../../context/UserContext";

function RepertoireDialog({
  repertoires,
  setRepertoires,
  moves,
  setMoves,
  setSelectedMoveId,
  selectedRepertoireId,
  setSelectedRepertoireId,
  isModalOpen,
  setIsModalOpen,
  repertoireDetails,
  setRepertoireDetails,
  setTotalRepertoires,
  totalMoves,
  setTotalMoves,
}) {
  // Theme and Color Management
  /**
   * Retrieve the current theme (light or dark) using Material-UI's `useTheme` hook.
   */
  const theme = useTheme();

  /**
   * Get color tokens based on the current theme mode (light or dark).
   */
  const colors = tokens(theme.palette.mode);

  /**
   * Access user data using a custom `useUser` hook.
   */
  const { userData } = useUser();

  /**
   * Extract the user's unique ID or default to an empty string if unavailable.
   */
  const userId = userData?.uid || "";

  const username = userData?.username || "";

  /**
   * Track whether the user is confirming a repertoire deletion.
   */
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

  /**
   * Manage error states, such as invalid inputs or failed operations.
   */
  const [showError, setShowError] = useState(false);

  /**
   * Store the input value for naming new repertoires.
   */
  const [inputRepertoireName, setInputRepertoireName] = useState("");

  /**
   * Opens the delete confirmation dialog.
   */
  const handleDeleteClick = () => {
    setInputRepertoireName("");
    setIsConfirmingDelete(true);
  };

  /**
   * Cancels the delete confirmation dialog.
   */
  const handleCancelDelete = () => {
    setIsConfirmingDelete(false);
    setInputRepertoireName(""); // Clear the input field
  };

  /**
   * Confirms deletion of the repertoire if the input matches the title.
   */
  const handleConfirmDelete = () => {
    if (inputRepertoireName === repertoireDetails.title) {
      handleDeleteRepertoire(); // Proceed with deletion
      setIsConfirmingDelete(false);
      setShowError(false);
    } else {
      setShowError(true); // Show error if names do not match
    }
  };

  /**
   * Saves the current repertoire details to Firebase.
   * Adds a new repertoire or updates an existing one.
   */
  const handleSaveRepertoire = async () => {
    const updatedRepertoire = {
      ...repertoireDetails, // Use consolidated details
      moves: moves,
      createdAt: selectedRepertoireId
        ? repertoires[selectedRepertoireId]?.createdAt
        : Date.now(),
      order: selectedRepertoireId
        ? repertoires[selectedRepertoireId]?.order
        : Object.keys(repertoires).length,
    };

    try {
      if (selectedRepertoireId) {
        // Update an existing repertoire
        await set(
          ref(rt, `users/${userId}/repertoires/${selectedRepertoireId}`),
          updatedRepertoire
        );
      }

      // Update the state with the new or updated repertoire
      setRepertoires((prev) => ({
        ...prev,
        [selectedRepertoireId || `rep-${Date.now()}`]: updatedRepertoire,
      }));

      setIsModalOpen(false); // Close the modal dialog
    } catch (error) {}
  };

  /**
   * Deletes the selected repertoire from the list and Firebase.
   */
  const handleDeleteRepertoire = async () => {
    if (!selectedRepertoireId) {
      return;
    }

    const moves = repertoires[selectedRepertoireId].moves;
    const moveCount = Object.keys(moves).length;

    try {
      setRepertoires((prevRepertoires) => {
        const updatedRepertoires = { ...prevRepertoires };
        delete updatedRepertoires[selectedRepertoireId];

        const remainingRepertoires = Object.entries(updatedRepertoires).sort(
          ([, a], [, b]) => a.order - b.order
        );

        if (remainingRepertoires.length > 0) {
          // ✅ Select the next available repertoire
          const [nextRepertoireId, nextRepertoire] = remainingRepertoires[0];

          setSelectedRepertoireId(nextRepertoireId);
          setRepertoireDetails({
            title: nextRepertoire.title || "",
            description: nextRepertoire.description || "",
            author: nextRepertoire.author || "",
            boardOrientation: nextRepertoire.boardOrientation || "white",
            visibility: nextRepertoire.visibility || "Private",
            allowCopy: nextRepertoire.allowCopy || "No",
          });

          setMoves(
            nextRepertoire.moves || {
              root: { san: "", next: null, variations: [], parent: null },
            }
          );
          setSelectedMoveId("root");
        } else {
          // ✅ If this is the last repertoire, RESET instead of deleting
          const resetRepertoire = {
            id: selectedRepertoireId, // Keep the same ID
            title: "New Repertoire",
            description: "",
            author: username,
            boardOrientation: "white",
            visibility: "Private",
            allowCopy: "No",
            moves: {
              root: { san: "", next: null, variations: [], parent: null },
            },
            createdAt:
              repertoires[selectedRepertoireId]?.createdAt || Date.now(),
            order: repertoires[selectedRepertoireId]?.order || 0,
          };

          // ✅ Save to Firebase
          set(
            ref(rt, `users/${userId}/repertoires/${selectedRepertoireId}`),
            resetRepertoire
          );

          // ✅ Update local state
          updatedRepertoires[selectedRepertoireId] = resetRepertoire;
          setSelectedRepertoireId(selectedRepertoireId);
          setRepertoireDetails(resetRepertoire);
          setMoves(resetRepertoire.moves);
          setSelectedMoveId("root");
        }

        return updatedRepertoires;
      });

      setTotalRepertoires((prevCount) => prevCount - 1);
      setTotalMoves((prevCount) => prevCount - moveCount);

      // ✅ Delete from Firebase only if other repertoires exist
      if (Object.keys(repertoires).length > 1) {
        await deleteRepertoire(userId, selectedRepertoireId);
      }

      setIsModalOpen(false); // Close the modal dialog
    } catch (error) {
      alert("Failed to delete repertoire. Please try again.");
    }
  };

  return (
    <Dialog
      open={isModalOpen}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.8)", // Darker background with 80% opacity
        },
        "& .MuiPaper-root": {
          position: "relative", // Required for pseudo-element positioning
          backgroundColor: colors.background[100], // Card background color
          backgroundImage: "none",
          color: colors.black[900],
          border: `0.5px solid #222222`, // Red outline
          clipPath:
            "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
          "::before": {
            content: '""', // Required for pseudo-element
            position: "absolute", // Position relative to the parent
            top: 0, // Align with the top of the parent
            left: 0, // Align with the left of the parent
            width: "100%", // Match the width of the parent
            height: "100%", // Match the height of the parent
            backgroundColor: "transparent", // Transparent background to show the card
            clipPath:
              "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
            zIndex: -1, // Place behind the card content
            pointerEvents: "none", // Ensure the outline does not interfere with interactions
          },
          p: 1, // Padding for the card content
        },
      }}
      onClose={() => setIsModalOpen(false)}
    >
      <DialogTitle sx={{ pt: 1, pb: 1 }}>Edit Repertoire</DialogTitle>
      <Divider sx={{ backgroundColor: colors.green[100], height: "1.5px" }} />
      <DialogContent sx={{ p: 2, mb: 1, mt: 1 }}>
        <TextField
          fullWidth
          label="Name"
          value={repertoireDetails.title}
          onChange={(e) =>
            setRepertoireDetails({
              ...repertoireDetails,
              title: e.target.value,
            })
          }
          variant="outlined"
          autoComplete="off"
          sx={{ mb: 2 }}
        />

        <TextField
          fullWidth
          label="Description"
          value={repertoireDetails.description}
          onChange={(e) =>
            setRepertoireDetails({
              ...repertoireDetails,
              description: e.target.value,
            })
          }
          variant="outlined"
          multiline
          rows={5} // Set default height to 5 rows
          inputProps={{ style: { overflow: "auto", maxHeight: "200px" } }} // Adds scrollbar if needed
          autoComplete="off"
          sx={{ mb: 2 }}
        />

        <TextField
          fullWidth
          label="Author"
          value={repertoireDetails.author}
          onChange={(e) =>
            setRepertoireDetails({
              ...repertoireDetails,
              author: e.target.value,
            })
          }
          variant="outlined"
          autoComplete="off"
          sx={{ mb: 2 }}
        />

        <Box
          sx={{
            display: "flex", // Arrange items in a row
            gap: 2, // Add spacing between groups
            alignItems: "center", // Vertically align items
            flexWrap: "wrap", // Allow wrapping for responsiveness
          }}
        >
          {/* Board Orientation */}
          <FormControl component="fieldset" sx={{ flex: 1 }}>
            <Typography
              variant="subtitle2"
              sx={{ mb: 0.5, fontSize: "0.8rem" }}
            >
              Orientation
            </Typography>
            <ToggleButtonGroup
              value={repertoireDetails.boardOrientation}
              exclusive // Ensures only one button can be selected
              onChange={(e, newValue) => {
                if (newValue !== null)
                  setRepertoireDetails({
                    ...repertoireDetails,
                    boardOrientation: newValue,
                  }); // Prevent null value on deselect
              }}
              sx={{
                height: "40px",
                "& .MuiToggleButton-root": {
                  textTransform: "none", // Ensure no underline or other decorations
                  "&.Mui-selected": {
                    color: "#FFFFFF", // Selected text color
                    backgroundColor: colors.green[100], // Selected background
                    "&:hover": {
                      backgroundColor: colors.green[100], // Ensure selected hover color applies
                    },
                  },
                  "&:hover": {
                    color: "#FFFFFF",
                    backgroundColor: colors.green[500], // Default hover color for non-selected
                  },
                },
              }}
            >
              <ToggleButton value="white">White</ToggleButton>
              <ToggleButton value="black">Black</ToggleButton>
            </ToggleButtonGroup>
          </FormControl>

          {/* Visibility */}
          <FormControl component="fieldset" sx={{ flex: 1 }}>
            <Typography
              variant="subtitle2"
              sx={{ mb: 0.5, fontSize: "0.8rem" }}
            >
              Visibility
            </Typography>
            <ToggleButtonGroup
              value={repertoireDetails.visibility}
              exclusive
              onChange={(e, newValue) => {
                if (newValue !== null)
                  if (newValue !== null)
                    setRepertoireDetails({
                      ...repertoireDetails,
                      visibility: newValue,
                    });
              }}
              sx={{
                height: "40px",
                "& .MuiToggleButton-root": {
                  textTransform: "none", // Ensure no underline or other decorations
                  "&.Mui-selected": {
                    color: "#FFFFFF", // Selected text color
                    backgroundColor: colors.green[100], // Selected background
                    "&:hover": {
                      backgroundColor: colors.green[100], // Ensure selected hover color applies
                    },
                  },
                  "&:hover": {
                    color: "#FFFFFF",
                    backgroundColor: colors.green[500], // Default hover color for non-selected
                  },
                },
              }}
            >
              <ToggleButton value="Private">Private</ToggleButton>{" "}
              <ToggleButton value="Public">Public</ToggleButton>
            </ToggleButtonGroup>
          </FormControl>

          {/* Allow Copy */}
          <FormControl component="fieldset" sx={{ flex: 1 }}>
            <Typography
              variant="subtitle2"
              sx={{ mb: 0.5, fontSize: "0.8rem" }}
            >
              Allow Copy
            </Typography>
            <ToggleButtonGroup
              value={repertoireDetails.allowCopy}
              exclusive
              onChange={(e, newValue) => {
                if (newValue !== null)
                  setRepertoireDetails({
                    ...repertoireDetails,
                    allowCopy: newValue,
                  });
              }}
              sx={{
                height: "40px",
                "& .MuiToggleButton-root": {
                  textTransform: "none", // Ensure no underline or other decorations
                  "&.Mui-selected": {
                    color: "#FFFFFF", // Selected text color
                    backgroundColor: colors.green[100], // Selected background
                    "&:hover": {
                      backgroundColor: colors.green[100], // Ensure selected hover color applies
                    },
                  },
                  "&:hover": {
                    color: "#FFFFFF",
                    backgroundColor: colors.green[500], // Default hover color for non-selected
                  },
                },
              }}
            >
              <ToggleButton value="No">No</ToggleButton>
              <ToggleButton value="Yes">Yes</ToggleButton>
            </ToggleButtonGroup>
          </FormControl>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions
        sx={{
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
        }}
      >
        {/* Conditional rendering based on delete confirmation */}
        {!isConfirmingDelete ? (
          <>
            {/* Delete Text */}
            {selectedRepertoireId && (
              <Typography
                variant="body2"
                sx={{
                  color: colors.red[500],
                  cursor: "pointer",
                  "&:hover": {
                    textDecoration: "underline",
                    color: colors.red[600],
                  },
                }}
                onClick={handleDeleteClick}
              >
                Delete this Repertoire
              </Typography>
            )}

            {/* Save and Cancel Buttons */}
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                variant="contained"
                onClick={handleSaveRepertoire}
                sx={{
                  color: "white",
                  backgroundColor: colors.green[100],
                  "&:hover": {
                    backgroundColor: colors.green[200],
                  },
                }}
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
              <Button
                variant="contained"
                onClick={() => setIsModalOpen(false)}
                startIcon={<CancelIcon />}
              >
                Cancel
              </Button>
            </Box>
          </>
        ) : (
          <>
            {/* Confirmation Input and Buttons */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                flexGrow: 1,
              }}
            >
              <Typography variant="body2">
                Please type{" "}
                <strong
                  style={{
                    backgroundColor: "rgba(255, 0, 0, 0.1)", // Light red with transparency (20%)
                    border: "1px solid rgba(255, 0, 0, 0.2)", // Slightly darker red with transparency (50%)
                    padding: "2px 4px", // Add some padding for better visual appearance
                    borderRadius: "2px", // Optional: round the edges
                    display: "inline-block", // Ensure the styling wraps properly
                  }}
                >
                  {repertoireDetails.title}
                </strong>{" "}
                to confirm deletion.
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                value={inputRepertoireName}
                onChange={(e) => setInputRepertoireName(e.target.value)}
                placeholder="Repertoire name"
                autoComplete="off"
              />
              {showError && (
                <Box mt={1}>
                  <Alert severity="error">
                    The repertoire name does not match. Please try again.
                  </Alert>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column", // Stacks the buttons vertically
                gap: 1, // Adds spacing between the buttons
                alignItems: "flex-start", // Aligns buttons to the top
              }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={handleConfirmDelete}
                startIcon={<DeleteForeverIcon />}
              >
                Delete
              </Button>
              <Button
                variant="contained"
                startIcon={<CancelIcon />}
                onClick={handleCancelDelete}
              >
                Cancel
              </Button>
            </Box>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default RepertoireDialog;
