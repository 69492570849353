import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Box,
  Typography,
  useTheme,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardOptionKeyIcon from "@mui/icons-material/KeyboardOptionKey";
import DoneIcon from "@mui/icons-material/Done";

import useCategories from "../Components/useCategories";
import useLevelColours from "../Components/useLevelColours";

import { tokens } from "../../../../styles/theme";

import { getEvalColor } from "../Components/helper";

const TrainingMovesCard = ({
  moves,
  selectedMoveId = "root",
  setSelectedMoveId,
  showCard,
  repertoires,
  selectedRepertoireId,
}) => {
  const categories = useCategories();
  const [showComments, setShowComments] = useState(true);
  const [linesMenuAnchor, setLinesMenuAnchor] = useState(null);

  const selectedMoveRef = useRef(null);

  const [collapsedLines, setCollapsedLines] = useState(new Set());

  const toggleLineExpansion = (moveId) => {
    setCollapsedLines((prev) => {
      const newCollapsed = new Set(prev);
      if (newCollapsed.has(moveId)) {
        newCollapsed.delete(moveId); // ✅ Expand the line
      } else {
        newCollapsed.add(moveId); // ✅ Collapse the line
      }
      return newCollapsed;
    });
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleShowCommentsChange = () => {
    setShowComments((prevShowComments) => !prevShowComments);
    handleLinesMenuClose();
  };

  const [showEvaluations, setShowEvaluations] = useState(false);
  const handleShowEvaluationsChange = () => {
    setShowEvaluations((prevShowEvaluations) => !prevShowEvaluations);
    handleLinesMenuClose();
  };

  const handleLinesMenuOpen = (event) => {
    setLinesMenuAnchor(event.currentTarget);
  };

  const handleLinesMenuClose = () => {
    setLinesMenuAnchor(null);
  };

  const [showAnnotations, setShowAnnotations] = useState(true);

  const handleShowAnnotationChange = () => {
    setShowAnnotations((prevShowAnnotations) => !prevShowAnnotations);
    handleLinesMenuClose();
  };

  const [displayMovesFormat, setDisplayMovesFormat] = useState("tree");
  const handleDisplayMoveFormatChange = (format) => {
    setDisplayMovesFormat(format);
    handleLinesMenuClose();
  };

  const getCategoryColorBySymbol = useCallback(
    (symbol) => {
      const allItems = Object.values(categories).flat(); // Flatten all category items
      const item = allItems.find((item) => item.symbol === symbol); // Find the matching symbol
      return item ? item.color : null; // Return the color if found, else null
    },
    [categories]
  );

  const handleMoveClick = (node) => {
    if (!node || !node.id) return;

    // Update the selected move ID
    setSelectedMoveId(node.id);
  };

  const buildMoveLines = (
    moveId = "root",
    level = 0,
    lines = [],
    currentLine = null,
    moveNumber = 1, // ✅ Ensure move numbers start from 1
    inheritedMoveNumber = null
  ) => {
    if (!moveId || !moves[moveId]) return lines;

    // ✅ Skip "root" and start from root.next
    if (moveId === "root" && moves[moveId].next) {
      return buildMoveLines(moves[moveId].next, level, lines, [], 1);
    }

    const node = moves[moveId];

    // ✅ Initialize `currentLine` if it's null (first move)
    if (!currentLine) {
      currentLine = [];
    }

    // ✅ Assign correct move number (inherit from parent for variations)
    const assignedMoveNumber = inheritedMoveNumber ?? moveNumber;

    // ✅ Store the move number in the move object
    const moveData = {
      ...node,
      id: moveId, // ✅ Ensure move ID is stored
      level,
      moveNumber: assignedMoveNumber, // ✅ Correct move numbering
    };

    currentLine.push(moveData);

    // ✅ If variations exist, push the current line and process variations separately
    if (node.variations && node.variations.length > 0) {
      lines.push([...currentLine]); // Save the current line before processing variations

      node.variations.forEach((variationId) => {
        buildMoveLines(
          variationId,
          level + 1,
          lines,
          [], // Start a new indented line
          moveNumber, // ✅ Maintain move number for variations
          assignedMoveNumber // ✅ Inherit move number for variations
        );
      });

      currentLine = []; // Reset current line after variations
    }

    // ✅ If the move has a `next`, continue in the same line and increment move number after a White move
    if (node.next) {
      return buildMoveLines(
        node.next,
        level,
        lines,
        currentLine,
        moveNumber + 1, // ✅ Increment after White moves only
        null // ✅ Reset inherited move number for mainline moves
      );
    }

    // ✅ If no more moves, push the last collected line (if not empty)
    if (currentLine.length > 0) {
      lines.push(currentLine);
    }

    return lines;
  };

  const levelColors = useLevelColours();

  const displayMoveLinesPGN = () => {
    const root = moves.root.next;
    if (!root) return null; // Empty move tree

    let moveNumber = 1;

    const processMove = (
      moveId,
      variation = false,
      variationMoveNumber = null
    ) => {
      let move = moves[moveId];
      if (!move) return null;
      move = { ...move, id: moveId };
      let elements = [];

      let currentMoveNumber =
        variationMoveNumber !== null ? variationMoveNumber : moveNumber;
      let pgnMoveNumber = Math.ceil(currentMoveNumber / 2);
      let isWhiteMove = currentMoveNumber % 2 !== 0; // Odd = White, Even = Black

      let movePrefix =
        isWhiteMove || variation
          ? `${pgnMoveNumber}${isWhiteMove ? ". " : "... "}`
          : "";

      elements.push(
        <React.Fragment key={move.id}>
          <span
            ref={selectedMoveId === move.id ? selectedMoveRef : null}
            style={{
              padding: "3px",
              cursor: "pointer",
              display: "inline",
              fontWeight: selectedMoveId === move.id ? "bold" : "normal",
              textDecoration: selectedMoveId === move.id ? "underline" : "none",
              color:
                showAnnotations && getCategoryColorBySymbol(move.annotations),
            }}
            onClick={() => handleMoveClick(move)}
            onContextMenu={(event) => {
              event.preventDefault();
              handleMoveClick(move);
            }}
          >
            {movePrefix}
            {move.san}
            {move.annotations && showAnnotations && (
              <span
                style={{
                  color: getCategoryColorBySymbol(move.annotations),
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {move.annotations}
              </span>
            )}
          </span>

          {move.transpositionParent && moves[move.transpositionParent] && (
            <span
              key={`${move.id}-transposition`}
              style={{
                padding: "3px",
                cursor: "pointer",
                display: "inline",
                color: "grey",
              }}
              onClick={() => {
                const transpositionMove = {
                  ...moves[move.transpositionParent],
                  id: move.transpositionParent, // ✅ Inject the ID manually
                };
                handleMoveClick(transpositionMove);
              }}
            >
              ↪T
            </span>
          )}

          {move.comment && showComments && (
            <span
              style={{
                padding: "3px",
                color: "gray",
                display: "inline-block",
                wordBreak: "break-word",
              }}
            >
              {move.comment}
            </span>
          )}
        </React.Fragment>
      );

      if (move.variations && move.variations.length > 0) {
        for (const [index, variationId] of move.variations.entries()) {
          elements.push(
            <React.Fragment key={`${move.id}-var-${index}`}>
              {" ("}
              {processMove(variationId, true, currentMoveNumber)}
              {") "}
            </React.Fragment>
          );
        }
      }

      if (move.next) {
        elements.push(
          <React.Fragment key={`${move.id}-next`}>
            {" "}
            {processMove(move.next, false, currentMoveNumber + 1)}
          </React.Fragment>
        );
      }

      return elements;
    };

    return <>{processMove(root)}</>;
  };

  const displayMoveLinesTree = () => {
    const lines = buildMoveLines(); // Get structured move lines
    let visible = true; // Track whether lines should be shown based on parents
    let lastLevel = 0;

    return (
      <div
        className="scrollable-container"
        style={{ height: "400px", overflowY: "auto" }}
      >
        {lines.map((line, lineIndex) => {
          const firstMove = line[0]; // First move in this line
          const nextLine = lines[lineIndex + 1]; // Look ahead to the next line
          const hasVariations = nextLine && nextLine[0].level > firstMove.level;
          const isCollapsed = collapsedLines.has(firstMove.id);

          // ** Visibility Logic **
          if (firstMove.level === 0) {
            visible = true; // Reset visibility when reaching a top-level move
          }

          if (firstMove.level <= lastLevel) {
            visible = true;
          }

          if (!visible) {
            return null; // Skip rendering if hidden by a collapsed parent
          }

          // ✅ Reverse the logic: Assume expanded unless explicitly collapsed
          if (hasVariations && isCollapsed) {
            visible = false;
          }

          lastLevel = firstMove.level;

          return (
            <div
              key={lineIndex}
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginLeft: `${firstMove.level * 20}px`,
                color: levelColors[firstMove.level % levelColors.length],
              }}
            >
              {/* ✅ Move Toggle */}
              <span
                style={{
                  width: "30px important!", // ✅ Fixed width to maintain alignment
                  textAlign: "center",
                  display: "inline-block",
                  cursor: hasVariations ? "pointer" : "default",
                  fontWeight: "normal",
                  marginRight: "6px",
                  fontFamily: "monospace",
                }}
                onClick={
                  hasVariations ? () => toggleLineExpansion(firstMove.id) : null
                }
              >
                {hasVariations
                  ? isCollapsed
                    ? "[+]"
                    : "[-]"
                  : "\u00A0\u00A0\u00A0"}
              </span>

              {/* ✅ Move List */}
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                {line.map((move, index) => {
                  const fullMoveNumber = Math.ceil(move.moveNumber / 2);
                  const isWhiteMove = move.moveNumber % 2 !== 0;
                  const isStartOfLine = index === 0;

                  let movePrefix = "";
                  if (isWhiteMove) {
                    movePrefix = `${fullMoveNumber}. `;
                  } else if (isStartOfLine) {
                    movePrefix = `${fullMoveNumber}.. `;
                  }

                  return (
                    <React.Fragment key={move.id}>
                      <span
                        ref={
                          selectedMoveId === move.id ? selectedMoveRef : null
                        }
                        style={{
                          paddingRight: "5px",
                          cursor: "pointer",
                          display: "inline",
                          fontWeight:
                            selectedMoveId === move.id ? "bold" : "normal",
                          textDecoration:
                            selectedMoveId === move.id ? "underline" : "none",
                          color:
                            showAnnotations &&
                            getCategoryColorBySymbol(move.annotations),
                        }}
                        onClick={() => handleMoveClick(move)}
                        onContextMenu={(event) => {
                          event.preventDefault();
                          handleMoveClick(move);
                        }}
                      >
                        {movePrefix}
                        {move.san}
                        {move.annotations && showAnnotations && (
                          <span
                            style={{
                              color: getCategoryColorBySymbol(move.annotations),
                              fontSize: "16px",
                              fontWeight: "bold",
                            }}
                          >
                            {move.annotations}
                          </span>
                        )}
                      </span>

                      {move.transpositionParent &&
                        moves[move.transpositionParent] && (
                          <span
                            style={{
                              paddingRight: "5px",
                              cursor: "pointer",
                              display: "inline",
                              color: "grey",
                            }}
                            onClick={() => {
                              const transpositionMove = {
                                ...moves[move.transpositionParent],
                                id: move.transpositionParent, // ✅ Inject the ID manually
                              };

                              handleMoveClick(transpositionMove);
                            }}
                          >
                            ↪T
                          </span>
                        )}
                      {move.eval && showEvaluations && (
                        <span
                          style={{
                            paddingLeft: "0px",
                            paddingRight: "4px",
                            color: "gray",
                            display: "inline-block",
                            wordBreak: "break-word",
                          }}
                        >
                          {move.eval}
                        </span>
                      )}
                      {move.comment && showComments && (
                        <span
                          style={{
                            paddingLeft: "0px",
                            paddingRight: "8px",
                            color: "gray",
                            display: "inline-block",
                            wordBreak: "break-word",
                          }}
                        >
                          {`${move.comment}`}
                        </span>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const displayMoveLinesTreeEngine = () => {
    const lines = buildMoveLines(); // Get structured move lines
    let visible = true; // Track whether lines should be shown based on parents
    let lastLevel = 0;

    return (
      <div
        className="scrollable-container"
        style={{ height: "400px", overflowY: "auto" }}
      >
        {lines.map((line, lineIndex) => {
          const firstMove = line[0]; // First move in this line
          const nextLine = lines[lineIndex + 1]; // Look ahead to the next line
          const hasVariations = nextLine && nextLine[0].level > firstMove.level;
          const isCollapsed = collapsedLines.has(firstMove.id);

          // ** Visibility Logic **
          if (firstMove.level === 0) {
            visible = true; // Reset visibility when reaching a top-level move
          }

          if (firstMove.level <= lastLevel) {
            visible = true;
          }

          if (!visible) {
            return null; // Skip rendering if hidden by a collapsed parent
          }

          // ✅ Reverse the logic: Assume expanded unless explicitly collapsed
          if (hasVariations && isCollapsed) {
            visible = false;
          }

          lastLevel = firstMove.level;

          return (
            <div
              key={lineIndex}
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginLeft: `${firstMove.level * 20}px`,
              }}
            >
              {/* ✅ Move Toggle */}
              <span
                style={{
                  width: "30px important!", // ✅ Fixed width to maintain alignment
                  textAlign: "center",
                  display: "inline-block",
                  cursor: hasVariations ? "pointer" : "default",
                  fontWeight: "normal",
                  marginRight: "6px",
                  fontFamily: "monospace",
                }}
                onClick={
                  hasVariations ? () => toggleLineExpansion(firstMove.id) : null
                }
              >
                {hasVariations
                  ? isCollapsed
                    ? "[+]"
                    : "[-]"
                  : "\u00A0\u00A0\u00A0"}
              </span>

              {/* ✅ Move List */}
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                {line.map((move, index) => {
                  const fullMoveNumber = Math.ceil(move.moveNumber / 2);
                  const isWhiteMove = move.moveNumber % 2 !== 0;
                  const isStartOfLine = index === 0;

                  let movePrefix = "";
                  if (isWhiteMove) {
                    movePrefix = `${fullMoveNumber}. `;
                  } else if (isStartOfLine) {
                    movePrefix = `${fullMoveNumber}.. `;
                  }

                  return (
                    <React.Fragment key={move.id}>
                      <span
                        ref={
                          selectedMoveId === move.id ? selectedMoveRef : null
                        }
                        style={{
                          paddingRight: "5px",
                          cursor: "pointer",
                          display: "inline",
                          fontWeight:
                            selectedMoveId === move.id ? "bold" : "normal",
                          textDecoration:
                            selectedMoveId === move.id ? "underline" : "none",
                          color:
                            showAnnotations && move.annotations
                              ? getCategoryColorBySymbol(move.annotations)
                              : getEvalColor(move.eval),
                        }}
                        onClick={() => handleMoveClick(move)}
                        onContextMenu={(event) => {
                          event.preventDefault();
                          handleMoveClick(move);
                        }}
                      >
                        {movePrefix}
                        {move.san}
                        {move.annotations && showAnnotations && (
                          <span
                            style={{
                              color: getCategoryColorBySymbol(move.annotations),
                              fontSize: "16px",
                              fontWeight: "bold",
                            }}
                          >
                            {move.annotations}
                          </span>
                        )}
                      </span>

                      {move.transpositionParent &&
                        moves[move.transpositionParent] && (
                          <span
                            style={{
                              paddingRight: "5px",
                              cursor: "pointer",
                              display: "inline",
                              color: "grey",
                            }}
                            onClick={() => {
                              const transpositionMove = {
                                ...moves[move.transpositionParent],
                                id: move.transpositionParent, // ✅ Inject the ID manually
                              };

                              handleMoveClick(transpositionMove);
                            }}
                          >
                            ↪T
                          </span>
                        )}
                      {move.eval && showEvaluations && (
                        <span
                          style={{
                            paddingLeft: "0px",
                            paddingRight: "4px",
                            color: "gray",
                            display: "inline-block",
                            wordBreak: "break-word",
                          }}
                        >
                          ({move.eval})
                        </span>
                      )}
                      {move.comment && showComments && (
                        <span
                          style={{
                            paddingLeft: "0px",
                            paddingRight: "8px",
                            color: "gray",
                            display: "inline-block",
                            wordBreak: "break-word",
                          }}
                        >
                          {`${move.comment}`}
                        </span>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    if (selectedMoveRef.current) {
      selectedMoveRef.current.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
      });
    }
  }, [selectedMoveId, showCard]);

  return (
    showCard && (
      <Card
        sx={{
          width: "100%",
          position: "relative",
          backgroundColor: colors.background[100], // Card background color
          color: colors.black[900], // Text color
          backgroundImage: "none",
          clipPath:
            "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
          "::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "transparent",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            clipPath:
              "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
            zIndex: -1,
            pointerEvents: "none",
          },
          p: 1,
        }}
      >
        <CardHeader
          avatar={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <KeyboardOptionKeyIcon />
            </Box>
          }
          sx={{ pt: 1, pb: 1 }}
          title={
            <Typography variant="h7" sx={{ fontWeight: "bold" }}>
              {repertoires[selectedRepertoireId].title}{" "}
              <span
                style={{
                  color: "gray",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                ({Object.keys(moves).length - 1} Moves)
              </span>
            </Typography>
          }
          action={
            <Box>
              <IconButton onClick={handleLinesMenuOpen}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={linesMenuAnchor}
                open={Boolean(linesMenuAnchor)}
                onClose={handleLinesMenuClose}
                sx={{
                  "& .MuiPaper-root": {
                    backgroundColor: colors.background[100],
                    backgroundImage: "none",
                  },
                  "& .MuiMenuItem-root": {
                    "&:hover": {
                      backgroundColor: colors.background[200],
                    },
                  },
                }}
              >
                <MenuItem onClick={() => handleDisplayMoveFormatChange("tree")}>
                  Display as Tree{" "}
                  {repertoires?.[selectedRepertoireId]?.repertoire_analysed && (
                    <>(Highlight Level)</>
                  )}
                  {displayMovesFormat === "tree" && (
                    <DoneIcon sx={{ ml: 1, fontSize: "14px" }} />
                  )}
                </MenuItem>

                {repertoires?.[selectedRepertoireId]?.repertoire_analysed && (
                  <MenuItem
                    onClick={() => handleDisplayMoveFormatChange("treeEngine")}
                  >
                    Display as Tree (Highlight Eval)
                    {displayMovesFormat === "treeEngine" && (
                      <DoneIcon sx={{ ml: 1, fontSize: "14px" }} />
                    )}
                  </MenuItem>
                )}

                <MenuItem onClick={() => handleDisplayMoveFormatChange("pgn")}>
                  Display as PGN
                  {displayMovesFormat === "pgn" && (
                    <DoneIcon sx={{ ml: 1, fontSize: "14px" }} />
                  )}
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleShowCommentsChange}>
                  {showComments ? "Hide Comments" : "Show Comments"}
                </MenuItem>
                <MenuItem onClick={handleShowAnnotationChange}>
                  {showAnnotations ? "Hide Annotations" : "Show Annotations"}
                </MenuItem>
                {repertoires?.[selectedRepertoireId]?.repertoire_analysed && (
                  <MenuItem onClick={handleShowEvaluationsChange}>
                    {showEvaluations ? "Hide Evaluations" : "Show Evaluations"}
                  </MenuItem>
                )}
              </Menu>
            </Box>
          }
        />
        <Divider />
        <CardContent sx={{ padding: "16px !important" }}>
          <Box sx={{ mt: 1 }}>
            {displayMovesFormat === "pgn"
              ? displayMoveLinesPGN()
              : displayMovesFormat === "treeEngine"
              ? displayMoveLinesTreeEngine()
              : displayMoveLinesTree()}
          </Box>
        </CardContent>
      </Card>
    )
  );
};

export default TrainingMovesCard;
