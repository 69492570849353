import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ColorModeContext, tokens } from "../../../../styles/theme";
import { logout } from "../../../../features/Authentication";

import LinearProgress from "@mui/material/LinearProgress";

import HandymanIcon from "@mui/icons-material/Handyman";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import SecuritySharpIcon from "@mui/icons-material/SecuritySharp";
import HomeSharpIcon from "@mui/icons-material/HomeSharp";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ArrowCircleUpSharpIcon from "@mui/icons-material/ArrowCircleUpSharp";
import ManageAccountsSharpIcon from "@mui/icons-material/ManageAccountsSharp";

import { getPlanIcon } from "./helper";

const RepertoireBuilderHeader = ({
  activePage,
  setActivePage,
  setSelectedMoveId,
  settings,
  userData,
  setSelectedUserGameId,
  setRunTour,
  setTourKey,
  totalGames,
  totalMoves,
  totalRepertoires,
  setIsComingSoonModalOpen,
  setIsReportBugModalOpen,
  setIsProfileModalOpen,
}) => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null); // State for menu anchor
  const open = Boolean(anchorEl); // Check if menu is open

  const handleOpenMenu = (event) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);

  const gameUsagePercentage = settings.limitGames
    ? Math.min((totalGames / settings.limitGames) * 100, 100)
    : 0;

  const moveUsagePercentage = settings.limitMoves
    ? Math.min((totalMoves / settings.limitMoves) * 100, 100)
    : 0;

  const repertoireUsagePercentage = settings.limitRepertoires
    ? Math.min((totalRepertoires / settings.limitRepertoires) * 100, 100)
    : 0;

  // Function to determine progress color
  const getProgressColor = (percentage) => {
    if (percentage < 50) return "#4caf50"; // Green
    if (percentage < 95) return "#ff9800"; // Orange
    return "#f44336"; // Red
  };

  // Define the menu items with icons
  const menuItems = [
    { label: "Builder", icon: <HandymanIcon sx={{ width: "20px" }} /> },
    { label: "Trainer", icon: <FitnessCenterIcon sx={{ width: "20px" }} /> },
    { label: "Games", icon: <ManageSearchIcon sx={{ width: "26px" }} /> },
    ...(settings.isAdmin
      ? [
          {
            label: "Admin",
            icon: (
              <SecuritySharpIcon sx={{ width: "26px", color: "#FFA500" }} />
            ),
          },
        ]
      : []),
  ];

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "8px",
        boxShadow: `0px 2px 4px ${colors.background[200]}`,
        background: colors.background[100],
      }}
    >
      {/* Logo and Title */}
      <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
        <img
          src={`${process.env.PUBLIC_URL}/img/logo-transparent${
            theme.palette.mode === "dark" ? "" : "-black"
          }.png`}
          alt="Logo"
          style={{ width: 40, height: 40, marginRight: 10 }}
        />
        <Typography variant="h5" fontWeight="bold">
          Repertoire Builder
        </Typography>
      </Box>

      {/* Tabs take up remaining space to push the icon button to the right */}
      <Box sx={{ flexGrow: 1, display: "flex", ml: 5 }}>
        <Tabs
          value={activePage}
          onChange={(event, newValue) => {
            setActivePage(newValue);
            setSelectedMoveId("root");
            setSelectedUserGameId(null);
          }}
          sx={{
            height: "20px",
            "& .MuiTab-root": {
              color: colors.black[900], // Text color for unselected tabs
              transition: "color 0.3s, border-color 0.3s", // Smooth transition for hover/focus effects
              minWidth: "50px", // Set tab width
              textAlign: "center", // Center align text or icon
              "&:hover": {
                color: colors.black[700], // Slightly lighter on hover
              },
            },
            "& .Mui-selected": {
              color: colors.black[900], // ✅ Fix: Sets active tab text color
              fontWeight: "bold", // ✅ Optional: Make selected text bold
            },
            "& .MuiTab-root.Mui-selected": {
              color: colors.black[900], // ✅ Ensure selected tab text color overrides MUI default
            },
            "& .MuiTabs-indicator": {
              backgroundColor: colors.green[900], // ✅ Active tab underline color
              height: "1.5px",
            },
          }}
        >
          {menuItems.map((item) => (
            <Tab
              key={item.label}
              value={item.label}
              icon={item.icon}
              iconPosition="start"
              label={item.label}
              sx={{
                pt: 1.5,
                textTransform: "none",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "120px", // ✅ Ensures consistent width
                width: "120px", // ✅ Ensures consistent width
                gap: 1,
                minHeight: "auto",
                transition: "color 0.2s ease-in-out",
                "& .MuiTab-iconWrapper": {
                  marginRight: 0, // ✅ Removes default right margin on icon
                },
              }}
            />
          ))}
        </Tabs>
      </Box>

      {/* Light/Dark Mode Toggle Button on the far right */}
      <Box>
        <Button
          variant="contained"
          onClick={() => setIsComingSoonModalOpen(true)}
          sx={{ mr: 1, textTransform: "none" }}
        >
          Coming Soon
        </Button>
        <Button
          variant="contained"
          onClick={() => setIsReportBugModalOpen(true)}
          sx={{ mr: 1, textTransform: "none" }}
        >
          Report Bug
        </Button>

        <Tooltip
          title={theme.palette.mode === "dark" ? "Light Mode" : "Dark Mode"}
        >
          <IconButton onClick={colorMode.toggleColorMode} color="inherit">
            {theme.palette.mode === "dark" ? (
              <LightModeOutlinedIcon />
            ) : (
              <DarkModeOutlinedIcon />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip title={"Show Help"}>
          <IconButton
            onClick={() => {
              setRunTour((prev) => {
                if (prev) {
                  return false; // ✅ If tour is running, turn it off
                } else {
                  setTourKey((prevKey) => prevKey + 1); // ✅ Restart tour from step 1
                  return true;
                }
              });
            }}
            color="inherit"
          >
            <HelpOutlineRoundedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="My Profile">
          <IconButton color="inherit" onClick={handleOpenMenu}>
            <AccountCircleOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Menu anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
          {/* Profile Info */}
          <Box sx={{ px: 2, py: 1, minWidth: 200 }}>
            <Typography variant="subtitle1" fontWeight="bold">
              {userData?.displayName || "Anonymous"}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {userData?.email || "No email"}
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Box sx={{ display: "flex", gap: 1 }}>
              <Button
                onClick={() => {
                  setIsProfileModalOpen(true);
                  handleCloseMenu();
                }}
                variant="contained"
                sx={{
                  color: "white",
                  backgroundColor: colors.green[100],
                  "&:hover": {
                    backgroundColor: colors.green[200],
                  },
                  textTransform: "none",
                }}
              >
                <ManageAccountsSharpIcon sx={{ mr: 0.5 }} />
                Edit Profile
              </Button>
              <Button
                onClick={() => {
                  handleCloseMenu();
                }}
                disabled={true}
                variant="contained"
                sx={{
                  color: "white",
                  backgroundColor: colors.green[100],
                  "&:hover": {
                    backgroundColor: colors.green[200],
                  },
                  textTransform: "none",
                }}
              >
                <ArrowCircleUpSharpIcon sx={{ mr: 0.5 }} />
                Upgrade
              </Button>
            </Box>
            <Divider sx={{ my: 1 }} />
            <Box sx={{ display: "flex", alignItems: "center", pt: 1, pb: 1 }}>
              {getPlanIcon(settings.plan)}
              <Typography variant="body2" sx={{ ml: 1 }}>
                <strong>{settings.label || "N/A"} Plan</strong>
              </Typography>
            </Box>

            {/* Moves Progress */}
            <Typography variant="body2">
              Repertoires:{" "}
              <strong>
                {totalRepertoires} / {settings.limitRepertoires || "N/A"}
              </strong>
            </Typography>
            <Box sx={{ width: "100%", mt: 0.5, pb: 1 }}>
              <LinearProgress
                variant="determinate"
                value={repertoireUsagePercentage}
                sx={{
                  height: 12,
                  borderRadius: 2,
                  backgroundColor: "#e0e0e0",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: getProgressColor(
                      repertoireUsagePercentage
                    ),
                  },
                }}
              />
            </Box>
            {/* Moves Progress */}
            <Typography variant="body2">
              Moves:{" "}
              <strong>
                {totalMoves} / {settings.limitMoves || "N/A"}
              </strong>
            </Typography>
            <Box sx={{ width: "100%", mt: 0.5, pb: 1 }}>
              <LinearProgress
                variant="determinate"
                value={moveUsagePercentage}
                sx={{
                  height: 12,
                  borderRadius: 2,
                  backgroundColor: "#e0e0e0",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: getProgressColor(moveUsagePercentage),
                  },
                }}
              />
            </Box>
            <Typography variant="body2">
              User Games:{" "}
              <strong>
                {totalGames} / {settings.limitGames || "N/A"}
              </strong>
            </Typography>
            <Box sx={{ width: "100%", mt: 0.5, pb: 1 }}>
              <LinearProgress
                variant="determinate"
                value={gameUsagePercentage}
                sx={{
                  height: 12,
                  borderRadius: 2,
                  backgroundColor: "#e0e0e0",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: getProgressColor(gameUsagePercentage),
                  },
                }}
              />
            </Box>

            <Divider sx={{ my: 1 }} />
          </Box>

          {/* Log Out Button */}
          <MenuItem
            onClick={() => {
              logout();
              navigate("/");
              handleCloseMenu();
            }}
            sx={{
              "&:hover": {
                backgroundColor: colors.background[200],
                color: colors.black[900],
                "& svg": {
                  color: colors.black[900],
                },
              },
            }}
          >
            <LogoutRoundedIcon fontSize="small" sx={{ mr: 1 }} />
            Log Out
          </MenuItem>

          {/* Return Home Button */}
          <MenuItem
            onClick={() => {
              navigate("/");
              handleCloseMenu();
            }}
            sx={{
              "&:hover": {
                backgroundColor: colors.background[200],
                color: colors.black[900],
                "& svg": {
                  color: colors.black[900],
                },
              },
            }}
          >
            <HomeSharpIcon fontSize="small" sx={{ mr: 1 }} />
            Chessboard Magic
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default RepertoireBuilderHeader;
