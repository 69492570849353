import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  forwardRef,
} from "react";
import { Chessboard } from "react-chessboard";
import { Chess } from "chess.js";
import {
  Box,
  Button,
  Card,
  Divider,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import SwapVertRoundedIcon from "@mui/icons-material/SwapVertRounded";
import Arrow from "../Components/arrow";
import { themeColors } from "../../../../styles/boardtheme";
import { tokens } from "../../../../styles/theme";
import { pieceSets } from "../../../../styles/pieceset";
import useCategories from "../Components/useCategories";
import useBoardHighlight from "../Components/useBoardHighlights";
import { v4 as uuidv4 } from "uuid";

import {
  getTimeControlIcon,
  formatDate,
  getResultIcon,
} from "../Components/helper";
import EngineBar from "../../../../components/EngineBar";

const ChessboardCardMyGames = forwardRef(
  (
    {
      userGames,
      setUserGames,
      moves,
      setMoves,
      selectedUserGameId,
      setSelectedUserGameId,
      selectedUserGameMoveId,
      setSelectedUserGameMoveId,
      userData,
      activePage,
      evaluation,
      evaluationOn,
    },
    ref
  ) => {
    const chessboardRef = useRef(null);
    const [game, setGame] = useState(new Chess());
    const [selectedUserGame, setSelectedUserGame] = useState(null); // New state for selected game

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const dragStartRef = useRef(null);
    const categories = useCategories();
    const highlightColors = useBoardHighlight(); // Get color mapping
    const [boardOrientation, setBoardOrientation] = useState("white");

    const [highlights, setHighlights] = useState([]);

    const [evalHeight, setEvalHeight] = useState(0);
    useEffect(() => {
      const updateEvalHeight = () => {
        if (chessboardRef.current) {
          setEvalHeight(chessboardRef.current.offsetWidth - 1);
        }
      };

      // Run once initially
      updateEvalHeight();

      // Add resize event listener
      window.addEventListener("resize", updateEvalHeight);

      // Cleanup listener on unmount
      return () => {
        window.removeEventListener("resize", updateEvalHeight);
      };
    }, [chessboardRef]);

    const getCategoryColorBySymbol = useCallback(
      (symbol) => {
        const allItems = Object.values(categories).flat(); // Flatten all category items
        const item = allItems.find((item) => item.symbol === symbol); // Find the matching symbol
        return item ? item.color : null; // Return the color if found, else null
      },
      [categories]
    );

    const getLastMoveSquare = useCallback(() => {
      const history = game.history({ verbose: true }); // Retrieve detailed move history
      if (history.length === 0) return null; // Return null if no moves exist
      return history[history.length - 1].to; // Return the destination square of the last move
    }, [game]);

    const renderAnnotation = useCallback(() => {
      if (!game || !chessboardRef?.current || !selectedUserGameMoveId) {
        return null;
      }

      const selectedMove = moves[selectedUserGameMoveId];

      if (!selectedMove || !selectedMove.annotations) {
        return null; // If no annotation, render nothing
      }

      const chessboardSize = chessboardRef.current.getBoundingClientRect(); // Get chessboard dimensions
      const squareSize = chessboardSize.width / 8; // Calculate square size

      const lastMoveSquare = getLastMoveSquare(); // Get the square of the last move
      if (!lastMoveSquare) return null;

      const { x, y } = getSquarePosition(lastMoveSquare);

      // Adjust coordinates based on board orientation
      const adjustedX = boardOrientation === "white" ? x : 1 - x;
      const adjustedY = boardOrientation === "white" ? y : 1 - y;

      const cx =
        chessboardSize.left + adjustedX * chessboardSize.width + squareSize / 2; // Circle x-coordinate
      const cy =
        chessboardSize.top + adjustedY * chessboardSize.height - squareSize / 2; // Circle y-coordinate

      return (
        <>
          <defs>
            <filter
              id="drop-shadow"
              x="-50%"
              y="-50%"
              width="200%"
              height="200%"
            >
              <feDropShadow
                dx="2"
                dy="2"
                stdDeviation="2"
                floodColor="black"
                floodOpacity="0.5"
              />
            </filter>
          </defs>
          <circle
            cx={`${((cx - chessboardSize.left) / chessboardSize.width) * 100}%`}
            cy={`${((cy - chessboardSize.top) / chessboardSize.height) * 100}%`}
            r="20"
            stroke="white"
            strokeWidth="1.5"
            renderAnnotation
            fill={getCategoryColorBySymbol(
              moves[selectedUserGameMoveId].annotations
            )}
            fillOpacity="0.7"
            filter="url(#drop-shadow)"
          />
          <text
            x={`${((cx - chessboardSize.left) / chessboardSize.width) * 100}%`}
            y={`${((cy - chessboardSize.top) / chessboardSize.height) * 100}%`}
            fill="white"
            fontSize="26px"
            textAnchor="middle"
            fontWeight="bold"
            dominantBaseline="middle"
          >
            {selectedMove.annotations}
          </text>
        </>
      );
    }, [
      moves,
      selectedUserGameMoveId,
      game,
      chessboardRef,
      boardOrientation,
      getLastMoveSquare,
      getCategoryColorBySymbol,
    ]);

    const [arrows, setArrows] = useState([]);

    const addMoveToLines = (moveSan) => {
      setMoves((prevMoves) => {
        if (!selectedUserGameMoveId) {
          return prevMoves;
        }

        // Clone moves object
        const newMoves = { ...prevMoves };

        // Get the selected move object
        let selectedMoveObject = newMoves[selectedUserGameMoveId];
        if (!selectedMoveObject) {
          return prevMoves;
        }

        // ✅ Check if the selected move has a transpositionParent and redirect
        const baseMoveId =
          selectedMoveObject.transpositionParent || selectedUserGameMoveId;
        let baseMoveObject = newMoves[baseMoveId];

        if (!baseMoveObject) {
          return prevMoves;
        }

        // Ensure variations array exists
        if (!Array.isArray(baseMoveObject.variations)) {
          baseMoveObject.variations = [];
        }

        // ✅ Check if the move already exists in `next` or `variations`
        const existingMoveEntry = Object.entries(newMoves).find(
          ([, move]) => move.san === moveSan && move.parent === baseMoveId
        );

        if (existingMoveEntry) {
          const [existingMoveId] = existingMoveEntry;
          setSelectedUserGameMoveId(existingMoveId); // Select the existing move
          return prevMoves;
        }

        // Step 1: Manually construct move sequence **including** the new move
        let moveSequence = [];
        let tempMoveId = baseMoveId;

        while (tempMoveId && newMoves[tempMoveId]) {
          const san = newMoves[tempMoveId].san;
          if (san && san.trim() !== "") {
            moveSequence.unshift(san);
          }
          tempMoveId = newMoves[tempMoveId].parent; // Move backwards to the parent
        }

        moveSequence.push(moveSan); // Add the new move

        // Step 2: Use chess.js to calculate the FEN
        let fen;
        try {
          const chess = new Chess();
          moveSequence.forEach((move) => {
            const result = chess.move(move);
            if (!result) {
              throw new Error(`Invalid move: ${move}`);
            }
          });
          fen = chess.fen(); // Get the updated FEN
          // use `fen` here as needed
        } catch (error) {
          return prevMoves; // Exit early if there's an error
        }
        // Step 3: Normalize FEN (remove move counters)
        const fenParts = fen.split(" ");
        const normalizedFen = fenParts.slice(0, 4).join(" "); // Remove last two parts (move counters)

        // Step 4: Check if another move already has this FEN
        const transposedMoveEntry = Object.entries(newMoves).find(
          ([, move]) =>
            move.fen?.split(" ").slice(0, 4).join(" ") === normalizedFen
        );

        const newMoveId = uuidv4();
        let transpositionParent = null;
        let nextMove = null;

        if (transposedMoveEntry) {
          const [transposedMoveId, transposedMove] = transposedMoveEntry;
          transpositionParent = transposedMoveId;
          nextMove = transposedMove.next;

          // Update `transposedMove.transpositionChildren` to reference the new move
          if (!transposedMove.transpositionChildren) {
            transposedMove.transpositionChildren = [newMoveId];
          } else if (
            !transposedMove.transpositionChildren.includes(newMoveId)
          ) {
            transposedMove.transpositionChildren.push(newMoveId);
          }

          newMoves[transposedMoveId] = transposedMove;
        }

        // Step 5: Create the new move object
        newMoves[newMoveId] = {
          san: moveSan,
          fen, // Store full FEN for reference
          next: nextMove, // Set next if a transpositionParent exists
          variations: [],
          parent: baseMoveId,
          transpositionParent, // If matched, this links to the existing position
        };

        // ✅ If `baseMoveObject.next` does not exist, set as `next`
        if (!baseMoveObject.next) {
          baseMoveObject.next = newMoveId;
        } else {
          // ✅ Ensure `variations` exists before using `.push()`
          if (!newMoves[baseMoveObject.next]?.variations) {
            newMoves[baseMoveObject.next].variations = [];
          }
          newMoves[baseMoveObject.next].variations.push(newMoveId);
        }

        // Save the updated parent move
        newMoves[baseMoveId] = baseMoveObject;
        // ✅ Update selected game in userGames
        setUserGames((prevUserGames) => {
          if (!selectedUserGameId) return prevUserGames; // Ensure a game is selected

          return prevUserGames.map((game) =>
            game.id === selectedUserGameId ? { ...game, moves: newMoves } : game
          );
        });

        setSelectedUserGameMoveId(newMoveId); // Update selected move
        return newMoves;
      });
    };

    // Expose the function to parent via ref
    useImperativeHandle(ref, () => ({
      addMoveToLines,
    }));

    const getMoveSequence = useCallback(() => {
      let currentMoveId = selectedUserGameMoveId;
      let movePath = [];

      while (currentMoveId && moves[currentMoveId]) {
        const san = moves[currentMoveId].san;
        if (san && san.trim() !== "") {
          movePath.unshift(san); // Insert at the beginning to maintain move order
        }
        currentMoveId = moves[currentMoveId].parent; // Move backwards to the parent
      }

      return movePath; // Return the sequence from root to selectedUserGameMoveId
    }, [selectedUserGameMoveId, moves]);

    // useEffect to update the game when selectedUserGameMoveId changes
    useEffect(() => {
      if (!selectedUserGameMoveId || !moves[selectedUserGameMoveId]) return;

      setGame(() => {
        const newGame = new Chess();
        const moveSequence = getMoveSequence();

        moveSequence.forEach((san) => {
          newGame.move(san); // Apply each move to reconstruct the game state
        });

        return newGame;
      });
    }, [selectedUserGameMoveId, moves, getMoveSequence]);

    const addHighlights = useCallback(
      (start, end, event) => {
        if (!selectedUserGameMoveId) return; // Ensure there's a selected move

        setMoves((prevMoves) => {
          // Clone moves state to avoid mutating the original state directly
          const newMoves = { ...prevMoves };

          // Find the selected move
          const nodeToUpdate = newMoves[selectedUserGameMoveId];

          if (!nodeToUpdate) return prevMoves; // Return unchanged state if move not found

          // Store highlight type instead of color
          let highlightType = "default";
          if (event?.shiftKey) highlightType = "shift";
          else if (event?.ctrlKey || event?.metaKey) highlightType = "ctrl";
          else if (event?.altKey) highlightType = "alt";

          if (start === end) {
            // **Handle Square Highlights**
            if (!nodeToUpdate.highlightSquares) {
              nodeToUpdate.highlightSquares = [];
            }

            const existingHighlightIndex =
              nodeToUpdate.highlightSquares.findIndex(
                (highlight) => highlight[0] === start
              );

            if (existingHighlightIndex >= 0) {
              // If the same color exists, remove it; otherwise, update the color
              if (
                nodeToUpdate.highlightSquares[existingHighlightIndex][1] ===
                highlightType
              ) {
                nodeToUpdate.highlightSquares.splice(existingHighlightIndex, 1);
              } else {
                nodeToUpdate.highlightSquares[existingHighlightIndex][1] =
                  highlightType;
              }
            } else {
              // Add new highlight
              nodeToUpdate.highlightSquares.push([start, highlightType]);
            }

            setHighlights([...nodeToUpdate.highlightSquares]); // Update UI state
          } else {
            // **Handle Arrows**
            if (!nodeToUpdate.arrows) {
              nodeToUpdate.arrows = [];
            }

            const existingIndex = nodeToUpdate.arrows.findIndex(
              (arrow) => arrow[0] === start && arrow[1] === end
            );

            if (existingIndex >= 0) {
              // If the same color exists, remove it; otherwise, update the color
              if (nodeToUpdate.arrows[existingIndex][2] === highlightType) {
                nodeToUpdate.arrows.splice(existingIndex, 1);
              } else {
                nodeToUpdate.arrows[existingIndex][2] = highlightType;
              }
            } else {
              // Add new arrow
              nodeToUpdate.arrows.push([start, end, highlightType]);
            }

            setArrows([...nodeToUpdate.arrows]); // Update UI state
          }

          return newMoves; // Return updated moves state
        });
      },
      [selectedUserGameMoveId, setMoves, setArrows, setHighlights]
    );

    const handleMove = (sourceSquare, targetSquare) => {
      try {
        const newGame = new Chess();
        newGame.loadPgn(game.pgn());

        const move = newGame.move({
          from: sourceSquare,
          to: targetSquare,
          promotion: "q",
        });

        if (!move) throw new Error("Illegal move");

        setGame(newGame);
        addMoveToLines(move.san); // Make sure this is running

        return true;
      } catch (error) {
        return false;
      }
    };

    const handleNext = useCallback(() => {
      if (!selectedUserGameMoveId) return; // No move selected

      let currentMove = moves[selectedUserGameMoveId];
      if (!currentMove) return; // Defensive check

      // ✅ If transpositionParent exists, use it as the reference move
      if (currentMove.transpositionParent) {
        currentMove = moves[currentMove.transpositionParent] || currentMove;
      }

      if (!currentMove.next) return; // No next move available

      const nextMoveId = currentMove.next;
      const nextMove = moves[nextMoveId];

      if (!nextMove) return; // Defensive check

      setGame((prevGame) => {
        const newGame = new Chess();
        newGame.loadPgn(prevGame.pgn()); // Load existing moves
        newGame.move(nextMove.san); // Play the next move
        return newGame;
      });

      setSelectedUserGameMoveId(nextMoveId); // Update selected move
    }, [selectedUserGameMoveId, moves, setGame, setSelectedUserGameMoveId]);

    const handleBack = useCallback(() => {
      if (!selectedUserGameMoveId || selectedUserGameMoveId === "root") return; // Do nothing if at the root

      const currentMove = moves[selectedUserGameMoveId];
      if (!currentMove || !currentMove.parent) return; // No parent = already at the start

      setGame((prevGame) => {
        const newGame = new Chess();
        newGame.loadPgn(prevGame.pgn()); // Load existing moves
        newGame.undo(); // Undo the last move
        return newGame;
      });

      setSelectedUserGameMoveId(currentMove.parent); // Move back to the parent move
    }, [selectedUserGameMoveId, moves, setGame, setSelectedUserGameMoveId]);

    const getSquarePosition = (square) => {
      const file = square.charCodeAt(0) - 97; // Convert file (a-h) to 0-7
      const rank = 8 - parseInt(square[1], 10); // Convert rank (1-8) to 7-0
      return { x: (file + 0.5) / 8, y: (rank + 0.5) / 8 }; // Normalize to percentages
    };

    const handleMouseDown = useCallback((event) => {
      if (event.button !== 2) return; // Only trigger on right-click
      const square = getSquareFromMouseEvent(event); // Get the square from the mouse event
      if (square) {
        dragStartRef.current = square; // Set the drag start square in the ref
      }
    }, []);

    /**
     * Handles the mouse up event to finalize a right-click action.
     * This action can add highlights or arrows depending on the starting and ending squares.
     *
     * @param {Event} event - The mouse up event.
     */
    const handleMouseUp = useCallback(
      (event) => {
        if (event.button !== 2) return; // Only trigger on right-click
        const square = getSquareFromMouseEvent(event); // Get the square where the mouse was released

        const dragStart = dragStartRef.current; // Retrieve the drag start square

        if (dragStart && square) {
          addHighlights(dragStart, square, event); // Add highlights or arrows
        }

        dragStartRef.current = null; // Clear the drag start reference
      },
      [addHighlights]
    );

    const getSquareFromMouseEvent = (event) => {
      const rect = chessboardRef.current.getBoundingClientRect(); // Get the chessboard dimensions
      const x = event.clientX - rect.left; // X position relative to the board
      const y = event.clientY - rect.top; // Y position relative to the board

      const file = Math.floor((x / rect.width) * 8); // Determine file (a-h) as 0-7
      const rank = Math.floor(8 - (y / rect.height) * 8); // Determine rank (1-8) as 7-0

      if (file >= 0 && file <= 7 && rank >= 0 && rank <= 7) {
        return String.fromCharCode(97 + file) + (rank + 1); // Convert to algebraic notation
      }
      return null; // Return null if outside the board
    };

    // Add and remove event listeners for chessboard interactions
    useEffect(() => {
      const chessboardElement = chessboardRef.current;

      if (chessboardElement) {
        chessboardElement.addEventListener("mousedown", handleMouseDown);
        chessboardElement.addEventListener("mouseup", handleMouseUp);

        return () => {
          chessboardElement.removeEventListener("mousedown", handleMouseDown);
          chessboardElement.removeEventListener("mouseup", handleMouseUp);
        };
      }
    }, [handleMouseUp, handleMouseDown]);

    useEffect(() => {
      const handleKeyDown = (event) => {
        switch (event.key) {
          case "ArrowLeft":
            handleBack();
            break;
          case "ArrowRight":
            handleNext();
            break;
          default:
            break;
        }
      };

      document.addEventListener("keydown", handleKeyDown);

      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }, [handleBack, handleNext, activePage]); // Add activePage as a dependency

    useEffect(() => {
      if (selectedUserGameMoveId) {
        setArrows(moves[selectedUserGameMoveId]?.arrows || []);
        setHighlights(moves[selectedUserGameMoveId]?.highlightSquares || []);
      } else {
        setArrows([]);
        setHighlights([]);
      }
    }, [moves, selectedUserGameMoveId]);

    useEffect(() => {
      // Find the game by ID instead of accessing it directly as a property
      const gameData = userGames.find((game) => game.id === selectedUserGameId);

      if (!selectedUserGameId || !userGames.length || !gameData) {
        setSelectedUserGame(null);
        setMoves({});
        return;
      }

      setSelectedUserGame(gameData); // ✅ Save it to state
      setMoves(gameData.moves || {}); // ✅ Set moves from the selected game
    }, [selectedUserGameId, userGames, setMoves]);

    useEffect(() => {
      if (!selectedUserGameId || !userGames) return; // ✅ Ensure valid data

      // Convert userGames (object) into an array and find the selected game
      const selectedGame = Object.entries(userGames).find(
        ([, game]) => game.id === selectedUserGameId
      )?.[1]; // Extract the game object from the entry

      if (selectedGame?.boardOrientation) {
        setBoardOrientation(selectedGame.boardOrientation);
      }
    }, [selectedUserGameId, userGames]); // ✅ Runs when game selection changes

    return (
      <Box sx={{ width: "100%", position: "relative" }}>
        {/* Chessboard */}
        <Card
          id="games-chessboardcard"
          sx={{
            position: "relative",
            backgroundColor: colors.background[100], // Card background color
            color: colors.black[900], // Text color
            backgroundImage: "none",
            width: "100%",
            clipPath:
              "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
            "::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "transparent",
              border: "1px solid rgba(0, 0, 0, 0.2)",
              clipPath:
                "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
              zIndex: -1,
              pointerEvents: "none",
            },
            p: 2,
            mb: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row", // Ensures EngineBar and Chessboard are side by side
              alignItems: "flex-start",
              width: "100%",
              minHeight: "100px",
              maxWidth: "500px", // Control the maximum width for the chessboard section
            }}
          >
            {evaluationOn && (
              <Box
                sx={{
                  width: "20px", // Fixed width for the engine bar
                  mr: 0.5,
                  backgroundColor: "#AA0000", // Example background color
                }}
              >
                <EngineBar
                  value={evaluation}
                  color={boardOrientation}
                  height={evalHeight}
                />
              </Box>
            )}
            <Box
              ref={chessboardRef}
              sx={{
                width: evaluationOn ? "calc(100% - 22px)" : "100%",
                padding: "0px", // No padding
              }}
            >
              <Chessboard
                position={game.fen()}
                onPieceDrop={handleMove}
                areArrowsAllowed={false}
                boardOrientation={boardOrientation}
                customLightSquareStyle={{
                  backgroundColor:
                    themeColors[userData?.theme || "White Stripe Theme"]
                      .lightSquare,
                  backgroundImage:
                    themeColors[userData?.theme || "White Stripe Theme"]
                      .lightSquarePattern,
                }}
                customDarkSquareStyle={{
                  backgroundColor:
                    themeColors[userData?.theme || "White Stripe Theme"]
                      .darkSquare,
                  backgroundImage:
                    themeColors[userData?.theme || "White Stripe Theme"]
                      .darkSquarePattern,
                }}
                customPieces={pieceSets[userData?.pieceset || "Wikipedia"]}
              />
            </Box>
          </Box>
          {/* SVG Layer for Arrows & Highlights */}
          <svg
            style={{
              position: "absolute",
              top: chessboardRef.current?.offsetTop || 0,
              left: chessboardRef.current?.offsetLeft || 0,
              width: chessboardRef.current?.offsetWidth || "100%",
              height: chessboardRef.current?.offsetHeight || "100%",
              pointerEvents: "none",
              overflow: "visible",
              zIndex: 500,
            }}
          >
            <defs>
              {arrows.map(([start, end, type], index) => (
                <marker
                  key={`arrowhead-${index}`}
                  id={`arrowhead-${index}`}
                  markerWidth="10"
                  markerHeight="7"
                  refX="10"
                  refY="3.5"
                  orient="auto"
                >
                  <polygon
                    points="0 0, 10 3.5, 0 7"
                    fill={highlightColors[type]}
                  />
                </marker>
              ))}
            </defs>
            {arrows.map(([start, end, type], index) => (
              <Arrow
                key={index}
                start={start}
                end={end}
                color={highlightColors[type]}
                markerId={`arrowhead-${index}`}
              />
            ))}
            {highlights.map(([square, type], index) => (
              <rect
                key={`highlight-${type}`}
                x={`${(square.charCodeAt(0) - 97) * 12.5}%`}
                y={`${(8 - parseInt(square[1], 10)) * 12.5}%`}
                width="12.5%"
                height="12.5%"
                rx="1%"
                ry="1%"
                stroke={highlightColors[type]}
                strokeWidth="3"
                fill="none"
                opacity="0.9"
              />
            ))}
            {renderAnnotation()}
          </svg>
          {/* Chessboard Buttons */}
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between" // ✅ Pushes "Close Game" to the right
            textAlign="left"
            sx={{ pt: 2, width: "100%" }}
          >
            {/* Left Side - Other Buttons */}
            <Box display="flex" flexDirection="row" alignItems="center">
              <Tooltip title="Reset the game">
                <span>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setSelectedUserGameMoveId("root");
                      setGame(new Chess());
                    }}
                    style={{ marginRight: 10 }}
                    disabled={selectedUserGameMoveId === "root"}
                    sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                  >
                    <ReplayIcon />
                  </Button>
                </span>
              </Tooltip>

              <Tooltip title="Go to previous move">
                <span>
                  <Button
                    variant="contained"
                    onClick={handleBack}
                    disabled={selectedUserGameMoveId === "root"}
                    style={{ marginRight: 10 }}
                    sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                  >
                    <KeyboardDoubleArrowLeftIcon />
                  </Button>
                </span>
              </Tooltip>

              <Tooltip title="Go to next move">
                <span>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    disabled={!moves[selectedUserGameMoveId]?.next}
                    style={{ marginRight: 10 }}
                    sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                  >
                    <KeyboardDoubleArrowRightIcon />
                  </Button>
                </span>
              </Tooltip>

              <Tooltip title="Toggle board orientation">
                <span>
                  <Button
                    variant="contained"
                    onClick={() =>
                      setBoardOrientation((prev) =>
                        prev === "white" ? "black" : "white"
                      )
                    }
                    sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                  >
                    <SwapVertRoundedIcon />
                  </Button>
                </span>
              </Tooltip>
            </Box>

            {/* Right Side - Close Game Button */}
            <Tooltip title="Close Game">
              <span>
                <Button
                  variant="contained"
                  onClick={() => setSelectedUserGameId(null)}
                  sx={{
                    color: "white",
                    minWidth: 40,
                    minHeight: 40,
                    backgroundColor: colors.red[500],
                    "&:hover": {
                      backgroundColor: colors.red[600],
                    },
                  }}
                >
                  Close Game
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Card>

        <Card
          sx={{
            position: "relative",
            backgroundColor: colors.background[100], // Card background color
            color: colors.black[900], // Text color
            backgroundImage: "none",
            width: "100%",
            minWidth: "100%",
            height: "fit-content",
            clipPath:
              "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
            "::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "transparent",
              border: "1px solid rgba(0, 0, 0, 0.2)",
              clipPath:
                "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
              zIndex: -1,
              pointerEvents: "none",
            },
            p: 2,
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ pt: 1, pb: 1 }}
            >
              {/* Left: Icon */}
              <Box sx={{ p: 0 }}>
                {getTimeControlIcon(selectedUserGame?.timeControlLabel)}
              </Box>

              {/* Right: Time Control & Date (Column Layout) */}
              <Stack>
                <Typography variant="h6">
                  {selectedUserGame?.timeControl} ⦁{" "}
                  {selectedUserGame?.timeControlLabel}
                </Typography>
                <Typography variant="body2" sx={{ color: "grey" }}>
                  {formatDate(selectedUserGame?.date)}
                </Typography>
              </Stack>
            </Stack>

            <Divider
              sx={{ backgroundColor: colors.green[100], height: "1.5px" }}
            />
            {/* Player Names & Results */}
            <Box sx={{ pt: 1, pb: 1 }}>
              <Typography>
                <span style={{ color: "#333333" }}>●</span>{" "}
                {selectedUserGame?.black} ({selectedUserGame?.blackElo}){" "}
                <Box
                  component="span"
                  sx={{
                    display: "inline-flex",
                    verticalAlign: "middle",
                    pb: "2px",
                  }}
                >
                  {getResultIcon("black", selectedUserGame?.result)}
                </Box>
              </Typography>

              <Typography>
                <span style={{ color: "#dddddd" }}>●</span>{" "}
                {selectedUserGame?.white} ({selectedUserGame?.whiteElo}){" "}
                <Box
                  component="span"
                  sx={{
                    display: "inline-flex",
                    verticalAlign: "middle",
                    pb: "2px",
                  }}
                >
                  {getResultIcon("white", selectedUserGame?.result)}
                </Box>
              </Typography>
            </Box>
            <Divider />
            <Typography variant="body2" sx={{ color: "grey", pt: 1 }}>
              {selectedUserGame?.openingName} ({selectedUserGame?.eco})
            </Typography>
            <Typography variant="body2" sx={{ color: "grey" }}>
              {selectedUserGame?.startingLine}
            </Typography>
          </Box>
        </Card>
      </Box>
    );
  }
);

export default ChessboardCardMyGames;
