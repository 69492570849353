import { useCallback } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../../../styles/theme";
import { useState } from "react";
import SettingsEthernetIcon from "@mui/icons-material/SettingsEthernet";
import { convertMoveTreeToPGN } from "./helper";

/**
 * DebugCard Component
 * Displays debug information for selected nodes and repertoire data.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.selectedNode - The selected node object.
 * @param {Object[]} props.lines - The repertoire data.
 */
function DebugCard({ moves, selectedMoveId }) {
  /**
   * Control the visibility of debug mode elements in the UI.
   */
  const [showDebugMode, setShowDebugMode] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [inputMoveId, setInputMoveId] = useState(""); // ✅ Stores user-entered move ID
  const [movePath, setMovePath] = useState([]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Filter moves based on search query
  const filteredMoves = Object.entries(moves).filter(([id, move]) => {
    return (
      id.includes(searchQuery) || // ✅ Matches Move ID
      (move.san && move.san.toLowerCase().includes(searchQuery.toLowerCase())) // ✅ Matches SAN
    );
  });

  // Function to get the move sequence from a given move ID
  const getMoveSequence = useCallback(() => {
    let currentMoveId = inputMoveId.trim(); // Use user-input ID
    let movePath = [];

    while (currentMoveId && moves[currentMoveId]) {
      const san = moves[currentMoveId].san;
      if (san.trim() !== "") {
        movePath.unshift(san); // Add move at the beginning
      }
      currentMoveId = moves[currentMoveId].parent; // Move backwards
    }

    setMovePath(movePath); // ✅ Update move path state
  }, [inputMoveId, moves]);

  return (
    <Card
      sx={{
        position: "relative", // Required for pseudo-element positioning
        backgroundColor: colors.background[100], // Card background color
        color: colors.black[900], // Text color
        backgroundImage: "none !important",
        clipPath:
          "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
        "::before": {
          content: '""', // Required for pseudo-element
          position: "absolute", // Position relative to the parent
          top: 0, // Align with the top of the parent
          left: 0, // Align with the left of the parent
          width: "100%", // Match the width of the parent
          height: "100%", // Match the height of the parent
          backgroundColor: "transparent", // Transparent background to show the card
          border: "1px solid rgba(0, 0, 0, 0.2)", // Red outline
          clipPath:
            "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
          zIndex: -1, // Place behind the card content
          pointerEvents: "none", // Ensure the outline does not interfere with interactions
        },
        p: 1, // Padding for the card content
      }}
    >
      <CardHeader
        avatar={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <SettingsEthernetIcon />
            {/* Replace `SomeIcon` with your desired icon */}
          </Box>
        }
        title={
          <Typography variant="h7" sx={{ fontWeight: "bold" }}>
            Debug Information
          </Typography>
        }
        action={
          <Switch
            checked={showDebugMode} // Toggle debug mode
            onChange={(event) => setShowDebugMode(event.target.checked)} // Update the debug mode state
            sx={{
              "& .MuiSwitch-switchBase": {
                color: "white", // Circle color when off
              },
              "& .MuiSwitch-switchBase.Mui-checked": {
                color: colors.green[100], // Circle color when on
              },
              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                backgroundColor: "grey", // Track color when on
              },
              "& .MuiSwitch-track": {
                backgroundColor: "grey", // Track color when off
              },
            }}
          />
        }
        sx={{ pt: 1, pb: 1 }}
      />
      {showDebugMode && (
        <>
          <Divider
            sx={{ backgroundColor: colors.green[100], height: "1.5px" }}
          />
          <CardContent
            sx={{
              padding: "16px !important", // Apply even padding to all sides
            }}
          >
            {/* Debug Selected Node */}
            <Box sx={{ mt: 0, mb: 2 }}>
              <Typography
                component="pre"
                sx={{
                  whiteSpace: "pre-wrap",
                  fontSize: "14px",
                }}
              >
                <Typography
                  component="span"
                  sx={{
                    fontWeight: "bold",
                    color: colors.green[100], // Blue color for the label
                    display: "block", // Make it a block element for separation
                  }}
                >
                  Selected Move Id
                </Typography>
                {selectedMoveId && moves[selectedMoveId] ? (
                  <span>{JSON.stringify(moves[selectedMoveId], null, 2)}</span>
                ) : (
                  "No node selected"
                )}
              </Typography>
            </Box>

            <Typography
              component="span"
              sx={{
                fontWeight: "bold",
                color: colors.green[100],
                display: "block",
              }}
            >
              Moves Path
            </Typography>

            {/* Input and Button for Move Path */}
            <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                placeholder="Enter Move ID"
                value={inputMoveId}
                onChange={(e) => setInputMoveId(e.target.value)}
                sx={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                  input: { color: "black" },
                }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={getMoveSequence}
              >
                Search
              </Button>
            </Box>

            {/* Display Move Path */}
            {movePath.length > 0 && (
              <Typography
                component="pre"
                sx={{
                  whiteSpace: "pre-wrap",
                  fontSize: "14px",
                }}
              >
                [{movePath.join(", ")}]
              </Typography>
            )}

            <Typography
              component="span"
              sx={{
                fontWeight: "bold",
                color: colors.green[100], // Blue color for the label
                display: "block", // Make it a block element for separation
              }}
            >
              PGN
            </Typography>
            {convertMoveTreeToPGN(moves)}

            {/* Debug Lines */}
            <Typography
              component="pre"
              sx={{
                whiteSpace: "pre-wrap",
                fontSize: "14px",
              }}
            >
              <Typography
                component="span"
                sx={{
                  fontWeight: "bold",
                  color: colors.green[100], // Blue color for the label
                  display: "block", // Make it a block element for separation
                }}
              >
                Repertoire Move Data
              </Typography>
              {/* Search Filter for Moves */}
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                placeholder="Filter by SAN or Move ID..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                sx={{
                  mb: 2,
                  backgroundColor: "white",
                  borderRadius: "5px",
                  input: { color: "black" },
                }}
              />
              {filteredMoves.length > 0
                ? JSON.stringify(Object.fromEntries(filteredMoves), null, 2)
                : "No matches found."}
            </Typography>
          </CardContent>
        </>
      )}
    </Card>
  );
}

export default DebugCard;
