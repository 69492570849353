import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Button,
  Stack,
  Typography,
  TextField,
  useTheme,
  Pagination,
} from "@mui/material";
import { Chessboard } from "react-chessboard";
import { tokens } from "../../../../styles/theme";
import { Chess } from "chess.js";
import { addRepertoire } from "../../../../config/firebaseDB";
import { v4 as uuidv4 } from "uuid"; // Generate unique IDs
import { pgnTemplates } from "../../../../data/pgnTemplates/pgnTemplates";

import { themeColors } from "../../../../styles/boardtheme";
import { pieceSets } from "../../../../styles/pieceset";

import LibraryAddSharpIcon from "@mui/icons-material/LibraryAddSharp";

import useDialogFocused from "./useDialogFocused";
import { useAlert } from "../../../../context/AlertProvider";

const ImportTemplateDialog = ({
  open,
  onClose,
  setRepertoires,
  setSelectedRepertoireId,
  setMoves,
  setSelectedMoveId,
  userData,
  settings,
  totalRepertoires,
  setTotalRepertoires,
  totalMoves,
  setTotalMoves,
}) => {
  const [pgnText, setPgnText] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [
    selectedTemplateBoardOrientation,
    setSelectedTemplateBoardOrientation,
  ] = useState(null);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const showAlert = useAlert();

  const userId = userData?.uid || "";
  const username = userData?.username || "Unknown";

  const colorMap = {
    R: "shift", // Red
    G: "ctrl", // Green
    Y: "alt", // Yellow
    B: "default", // Blue
  };

  const ITEMS_PER_PAGE = 5; // Display 8 items per page

  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to first page on new search
  };

  // Filter templates based on search query
  const filteredTemplates = pgnTemplates.filter((template) =>
    template.Name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Calculate total pages based on filtered results
  const totalPages = Math.ceil(filteredTemplates.length / ITEMS_PER_PAGE);

  // Get paginated items from filtered results
  const paginatedTemplates = filteredTemplates.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  // Handle pagination change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  /**
   * 🎯 Parses [%cal ...] into a structured array of arrows
   * @param {string} calString - The raw [%cal ...] annotation
   * @returns {Array} - Array of formatted arrows
   */
  const formatArrows = (calString) => {
    if (!calString.startsWith("[%cal")) return [];

    const arrowAnnotationsRaw = calString
      .replace("[%cal", "")
      .replace("]", "")
      .trim();

    const arrowAnnotations = arrowAnnotationsRaw.includes(",")
      ? arrowAnnotationsRaw.split(",")
      : [arrowAnnotationsRaw];

    return arrowAnnotations
      .map((arrow, index) => {
        if (arrow.length < 5) {
          return null;
        }

        const colorCode = arrow[0]; // First character is the color
        const startSquare = arrow.slice(1, 3); // Next two chars are start square
        const endSquare = arrow.slice(3, 5); // Next two chars are end square

        return [startSquare, endSquare, colorMap[colorCode] || "default"];
      })
      .filter(Boolean); // Remove any null values
  };

  /**
   * 🔳 Parses [%csl ...] into a structured array of highlighted squares
   * @param {string} cslString - The raw [%csl ...] annotation
   * @returns {Array} - Array of formatted highlight squares
   */
  const formatHighlights = (cslString) => {
    if (!cslString.startsWith("[%csl")) return [];

    const highlightAnnotationsRaw = cslString
      .replace("[%csl", "")
      .replace("]", "")
      .trim();

    const highlightAnnotations = highlightAnnotationsRaw.includes(",")
      ? highlightAnnotationsRaw.split(",")
      : [highlightAnnotationsRaw];

    return highlightAnnotations
      .map((highlight, index) => {
        if (highlight.length < 3) {
          return null;
        }

        const colorCode = highlight[0]; // First character is the color
        const square = highlight.slice(1, 3); // Next two chars are the square

        return [square, colorMap[colorCode] || "default"];
      })
      .filter(Boolean); // Remove any null values
  };

  const processPGN = (pgn) => {
    let title = selectedTemplate.Name;
    let description = selectedTemplate.Description;

    pgn = pgn.replace(/\[(?!%cal|%csl)[^\]]*\]\s*/g, ""); // Remove metadata, but keep [%cal] and [%csl]

    let commentMap = {};
    let commentIndex = 0;

    // ✅ Extract and group comments by move
    pgn = pgn.replace(/\{([^{}]*)\}/g, (match, comment) => {
      let key = `COMMENT_${commentIndex++}`;
      if (!commentMap[key]) {
        commentMap[key] = [];
      }
      commentMap[key].push(comment.trim()); // Store all comments as an array
      return key; // Replace comment with a reference key
    });

    pgn = pgn.replace(/\$\d+/g, ""); // Remove NAGs
    pgn = pgn.replace(/\d+\.\s*/g, ""); // Remove move numbers
    pgn = pgn.replace(/[^a-zA-Z0-9+#\-()_ \s]/g, ""); // Remove unwanted characters

    const tokens = pgn
      .replace(/([()])/g, " $1 ")
      .trim()
      .split(/\s+/)
      .filter(Boolean);

    // ✅ Build move tree with multiple comments, arrows & highlights
    const buildMoveTree = (moves, index = 0, parent = null) => {
      let moveTree = {};
      let currentMoveId = parent || "root";
      let lastMainlineMove = parent;
      let variationParent = parent;
      let lastMoveId = null; // Track the last processed move
      let accumulatedComments = []; // Collect comments for the last move

      while (index < moves.length) {
        let move = moves[index];

        if (move === "(") {
          let depth = 1;
          let variationMoves = [];
          index++;

          while (index < moves.length && depth > 0) {
            if (moves[index] === "(") depth++;
            else if (moves[index] === ")") depth--;

            if (depth > 0) variationMoves.push(moves[index]);
            index++;
          }

          if (variationMoves.length > 0) {
            let variationTree = buildMoveTree(
              variationMoves,
              0,
              variationParent
            );
            let variationRootId = Object.keys(variationTree)[0];

            if (variationRootId && moveTree[lastMainlineMove]) {
              moveTree[lastMainlineMove].variations.push(variationRootId);
            }

            moveTree = { ...moveTree, ...variationTree };
          }
          continue;
        }

        if (move.startsWith("COMMENT_")) {
          if (commentMap[move]) {
            accumulatedComments.push(...commentMap[move]); // Store comments for the next move
          }
          index++;
          continue;
        }

        if (!move.trim() || move === "..") {
          index++;
          continue;
        }

        // ✅ If there's a last move, assign its comments *before creating the next move*
        if (lastMoveId && accumulatedComments.length > 0) {
          let firstComment = null;
          let arrowsData = null;
          let highlightsData = null;

          accumulatedComments.forEach((comment) => {
            // Check if the comment contains [%cal] (arrows)
            const calMatch = comment.match(/\[%cal[^\]]*\]/);
            if (calMatch) {
              arrowsData = formatArrows(calMatch[0]); // Convert to structured data
              comment = comment.replace(calMatch[0], "").trim(); // Remove [%cal] from comment
            }

            // Check if the comment contains [%csl] (highlight squares)
            const cslMatch = comment.match(/\[%csl[^\]]*\]/);
            if (cslMatch) {
              highlightsData = formatHighlights(cslMatch[0]); // Convert to structured data
              comment = comment.replace(cslMatch[0], "").trim(); // Remove [%csl] from comment
            }

            // If it's pure text (not empty after cleanup), treat it as a regular comment
            if (comment.length > 0) {
              firstComment = firstComment
                ? firstComment + " " + comment
                : comment;
            }
          });

          // Assign processed values to moveTree
          moveTree[lastMoveId].comment = firstComment || null;
          moveTree[lastMoveId].arrows = arrowsData || null;
          moveTree[lastMoveId].highlightSquares = highlightsData || null;

          // Reset for the next move
          accumulatedComments = [];
        }

        const moveId = uuidv4();
        let moveData = {
          id: moveId,
          san: move,
          next: null,
          variations: [],
          parent: currentMoveId,
          comments: null, // Will be set before processing next move
          arrows: null,
          highlightSquares: [],
        };

        if (currentMoveId !== "root" && moveTree[currentMoveId]) {
          moveTree[currentMoveId].next = moveId;
        }

        moveTree[moveId] = moveData;
        lastMoveId = moveId; // Update last processed move
        variationParent = lastMainlineMove;
        currentMoveId = moveId;
        lastMainlineMove = moveId;

        index++;
      }

      // ✅ Assign any remaining comments to the last move
      if (lastMoveId && accumulatedComments.length > 0) {
        let firstComment = null;
        let arrowsData = null;
        let highlightsData = null;

        accumulatedComments.forEach((comment) => {
          // Check if the comment contains [%cal] (arrows)
          const calMatch = comment.match(/\[%cal[^\]]*\]/);
          if (calMatch) {
            arrowsData = formatArrows(calMatch[0]); // Convert to structured data
            comment = comment.replace(calMatch[0], "").trim(); // Remove [%cal] from comment
          }

          // Check if the comment contains [%csl] (highlight squares)
          const cslMatch = comment.match(/\[%csl[^\]]*\]/);
          if (cslMatch) {
            highlightsData = formatHighlights(cslMatch[0]); // Convert to structured data
            comment = comment.replace(cslMatch[0], "").trim(); // Remove [%csl] from comment
          }

          // If it's pure text (not empty after cleanup), treat it as a regular comment
          if (comment.length > 0) {
            firstComment = firstComment
              ? firstComment + " " + comment
              : comment;
          }
        });

        // Assign processed values to moveTree
        moveTree[lastMoveId].comment = firstComment || null;
        moveTree[lastMoveId].arrows = arrowsData || null;
        moveTree[lastMoveId].highlightSquares = highlightsData || null;
      }

      return moveTree;
    };

    const moves = buildMoveTree(tokens);

    return {
      id: uuidv4(),
      title: title,
      description: description,
      author: username,
      boardOrientation: "white",
      visibility: "Private",
      allowCopy: "No",
      createdAt: Date.now(),
      order: 9999,
      moves: {
        root: {
          san: "",
          next: Object.keys(moves)[0] || null,
          variations: [],
          parent: null,
        },
        ...moves,
      },
    };
  };

  const handleTemplateSelect = (template) => {
    setSelectedTemplate(template);
    setSelectedTemplateBoardOrientation(template.Orientation);
    setPgnText(template.Pgn); // Set the PGN for import
  };

  /** Handle PGN Import */
  const handleImport = async () => {
    if (totalRepertoires >= settings.limitRepertoires) {
      showAlert(
        `Maximum Number of Repertoires Exceeded (${settings.limitRepertoires}). Please upgrade to have more repertoires.`,
        "error"
      );
      return;
    }

    if (!pgnText.trim()) {
      return;
    }

    try {
      const chess = new Chess();
      let formattedPGN = pgnText.trim();

      // Attempt to load PGN into chess.js
      chess.loadPgn(formattedPGN);

      // Process PGN into a structured repertoire
      const newRepertoire = processPGN(formattedPGN);
      newRepertoire.boardOrientation = selectedTemplateBoardOrientation;

      const moves = newRepertoire.moves;
      const moveCount = Object.keys(moves).length;

      if (totalMoves + moveCount >= settings.limitMoves) {
        showAlert(
          `Importing this repertoire would exceed the total allowed moves (${settings.limitMoves}). Please upgrade to proceed.`,
          "error"
        );
        return;
      }

      // Save to Firebase
      const newRepertoireId = newRepertoire.id;
      await addRepertoire(userId, newRepertoire);

      /*await set(
        ref(rt, `users/${userId}/repertoires/${newRepertoireId}`),
        newRepertoire
      );*/

      // Update State: Add repertoire, set as selected, set moves
      setRepertoires((prev) => ({
        ...prev,
        [newRepertoireId]: newRepertoire,
      }));

      setSelectedRepertoireId(newRepertoireId);
      setMoves(newRepertoire.moves);
      setSelectedMoveId("root");

      setTotalRepertoires((prevCount) => prevCount + 1);
      setTotalMoves((prevCount) => prevCount + moveCount);

      onClose(); // Close the dialog
    } catch (error) {}
  };

  return (
    <Dialog
      sx={{
        backgroundImage: "none !important",
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.8)", // Darker background with 80% opacity
        },
        "& .MuiPaper-root": {
          position: "relative", // Required for pseudo-element positioning
          backgroundColor: colors.background[100], // Card background color
          backgroundImage: "none",
          color: colors.black[900], // Text color
          border: `0.5px solid #222222`, // Red outline
          clipPath:
            "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Clipping path
          "::before": {
            content: '""', // Required for pseudo-element
            position: "absolute", // Position relative to the parent
            top: 0, // Align with the top of the parent
            left: 0, // Align with the left of the parent
            width: "100%", // Match the width of the parent
            height: "100%", // Match the height of the parent
            backgroundColor: "transparent", // Transparent background to show the card
            clipPath:
              "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)", // Match the clipping path
            zIndex: -1, // Place behind the card content
            pointerEvents: "none", // Ensure the outline does not interfere with interactions
          },
          p: 1, // Padding for the card content
        },
      }}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      {useDialogFocused(open)}
      <DialogTitle>
        <Stack direction="row" alignItems="center" spacing={1}>
          <LibraryAddSharpIcon />
          <Typography variant="h6">Import Template</Typography>
        </Stack>
      </DialogTitle>

      <Divider sx={{ backgroundColor: colors.green[100], height: "1.5px" }} />
      <DialogContent>
        <Stack direction="row" spacing={2} sx={{ mt: 2, height: "400px" }}>
          {/* Left: Template List with Search and Pagination */}
          <Stack
            sx={{
              width: "50%",
              overflowY: "auto",
              maxHeight: "400px",
              padding: "8px",
              gap: 1,
            }}
          >
            {/* Search Bar */}
            <TextField
              label="Search Template"
              variant="outlined"
              fullWidth
              value={searchQuery}
              onChange={handleSearchChange}
              sx={{ mb: 1 }}
              autoComplete="off"
            />

            {/* Template Buttons */}
            {paginatedTemplates.length > 0 ? (
              paginatedTemplates.map((template, index) => (
                <Button
                  key={index}
                  sx={{
                    textAlign: "left",
                    width: "100%",
                    justifyContent: "flex-start",
                    textTransform: "none",
                    color: colors.black[900],
                    border: `1px solid ${colors.background[200]}`,
                    backgroundColor:
                      selectedTemplate?.Name === template.Name
                        ? colors.background[200]
                        : colors.background[100],
                    "&:hover": { backgroundColor: colors.background[200] },
                  }}
                  onClick={() => handleTemplateSelect(template)}
                >
                  <Stack>
                    <Typography variant="body">{template.Name}</Typography>
                  </Stack>
                </Button>
              ))
            ) : (
              <Typography variant="body2" color="gray">
                No templates found.
              </Typography>
            )}

            {/* Pagination Controls */}
            {totalPages > 1 && paginatedTemplates.length > 0 && (
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                sx={{ mt: 2, alignSelf: "center" }}
              />
            )}
          </Stack>

          {/* Right: Description & Preview */}
          <Stack sx={{ width: "50%" }}>
            {selectedTemplate ? (
              <>
                <Typography variant="body" sx={{ fontWeight: "bold" }}>
                  {selectedTemplate.Name}
                </Typography>
                <Typography variant="caption" color="gray">
                  {selectedTemplate.Orientation.charAt(0).toUpperCase() +
                    selectedTemplate.Orientation.slice(1)}{" "}
                  Repertoire By {selectedTemplate.Author} |{" "}
                  {selectedTemplate.Moves} Moves
                </Typography>
                <Box sx={{ maxWidth: "100%", overflow: "auto", mt: 1 }}>
                  {/* Chessboard Div Acting Like an Image */}
                  <Box
                    sx={{
                      width: "200px",
                      height: "200px",
                      float: "left",
                      marginRight: "15px",
                      marginBottom: "10px",
                    }}
                  >
                    <Chessboard
                      position={selectedTemplate.Fen}
                      boardOrientation={selectedTemplate.Orientation}
                      areArrowsAllowed={false}
                      arePiecesDraggable={false}
                      customLightSquareStyle={{
                        backgroundColor:
                          themeColors[userData?.theme || "White Stripe Theme"]
                            .lightSquare,
                      }}
                      customDarkSquareStyle={{
                        backgroundColor:
                          themeColors[userData?.theme || "White Stripe Theme"]
                            .darkSquare,
                      }}
                      customPieces={
                        pieceSets[userData?.pieceset || "Wikipedia"]
                      }
                    />
                  </Box>

                  {/* Text that wraps around the Chessboard */}
                  <Typography variant="body2">
                    {selectedTemplate.Description}
                  </Typography>
                </Box>
              </>
            ) : (
              <Typography variant="body2" sx={{ mt: 1, color: "gray" }}>
                Select a repertoire to preview its details.
              </Typography>
            )}
          </Stack>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ mt: 1 }}>
        <Button
          onClick={handleImport}
          sx={{
            color: "white",
            backgroundColor: colors.green[100],
            "&:hover": {
              backgroundColor: colors.green[200],
            },
          }}
          variant="contained"
          startIcon={<LibraryAddSharpIcon />}
        >
          Import
        </Button>
        <Button onClick={onClose} variant="contained">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportTemplateDialog;
