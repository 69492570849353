import React from "react";
import BulletIcon from "../../../../icons/BulletIcon";
import BoltIcon from "../../../../icons/BoltIcon";
import RapidIcon from "../../../../icons/RapidIcon";
import ClassicalIcon from "../../../../icons/ClassicalIcon";
import DailyIcon from "../../../../icons/DailyIcon";

import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";

import GrassIcon from "@mui/icons-material/Grass";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import VerifiedUserTwoToneIcon from "@mui/icons-material/VerifiedUserTwoTone";
import VerifiedTwoToneIcon from "@mui/icons-material/VerifiedTwoTone";

/**
 * Returns the corresponding icon for a given time control label.
 * @param {string} timeControlLabel - The time control label.
 * @returns {JSX.Element | null} - The corresponding icon component.
 */
export const getTimeControlIcon = (timeControlLabel) => {
  const iconProps = {
    sx: { verticalAlign: "middle", height: "40px", width: "40px" },
  };

  switch (timeControlLabel) {
    case "UltraBullet":
    case "Bullet":
      return <BulletIcon {...iconProps} />;
    case "Blitz":
      return <BoltIcon {...iconProps} />;
    case "Rapid":
      return <RapidIcon {...iconProps} />;
    case "Classical":
      return <ClassicalIcon {...iconProps} />;
    case "Correspondence":
      return <DailyIcon {...iconProps} />;
    default:
      return null; // No icon for unknown labels
  }
};

/**
 * Formats a date into a readable format with an ordinal suffix.
 * @param {string | number | Date} dateInput - The date input.
 * @returns {string} - The formatted date string.
 */
export const formatDate = (dateInput) => {
  const date = new Date(dateInput);

  if (isNaN(date.getTime())) return "Invalid Date"; // Handle invalid input

  const day = date.getDate();
  const month = date.toLocaleString("en-US", { month: "long" });
  const year = date.getFullYear();

  // Function to add ordinal suffix (st, nd, rd, th)
  const getOrdinalSuffix = (num) => {
    if (num >= 11 && num <= 13) return "th";
    switch (num % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
};

/**
 * Formats the chess game result into a readable string.
 * @param {string} result - The result in PGN format (e.g., "1-0", "0-1", "1/2-1/2").
 * @returns {string} - The formatted result string.
 */
export const formatResult = (result) => {
  switch (result) {
    case "1-0":
      return "White Won";
    case "0-1":
      return "Black Won";
    case "1/2-1/2":
      return "Draw";
    case "*":
      return "Game Incomplete";
    default:
      return "Unknown Result";
  }
};

export const getResultIcon = (player, result) => {
  if (result === "1-0")
    return player === "white" ? (
      <DoneRoundedIcon fontSize="small" color="success" />
    ) : (
      <CloseRoundedIcon fontSize="small" color="error" />
    );
  if (result === "0-1")
    return player === "black" ? (
      <DoneRoundedIcon fontSize="small" color="success" />
    ) : (
      <CloseRoundedIcon fontSize="small" color="error" />
    );
  if (result === "1/2-1/2")
    return <RemoveRoundedIcon fontSize="small" color="disabled" />; // Using LooksOneIcon as a ½ alternative
  return null;
};

export const getEvalColor = (evaluation) => {
  // Convert mate evaluations to +/-5
  let numericEval = 0;

  if (typeof evaluation === "string" && evaluation.startsWith("M")) {
    numericEval = 5; // M (mate for White) → Max positive eval
  } else if (typeof evaluation === "string" && evaluation.startsWith("-M")) {
    numericEval = -5; // -M (mate for Black) → Max negative eval
  } else {
    numericEval = Math.max(-5, Math.min(5, evaluation)); // Clamp within range
  }

  if (numericEval <= 0.25 && numericEval >= -0.25) {
    return "rgb(160, 160, 160)"; // Neutral grey with better contrast
  }

  // Convert evaluation to a -1 to 1 scale for interpolation
  const normalizedEval = numericEval / 5;

  let r, g, b;

  if (normalizedEval < 0) {
    // Transition from Red (-5) to Grey (0)
    const factor = normalizedEval + 1; // Convert range from [-1, 0] to [0, 1]
    r = 200;
    g = Math.round(80 * factor + 80); // Interpolates from 80 to 160
    b = Math.round(80 * factor + 80); // Interpolates from 80 to 160
  } else {
    // Transition from Grey (0) to Green (5)
    const factor = normalizedEval; // Range [0, 1]
    r = Math.round(80 * (1 - factor) + 80); // Interpolates from 160 to 80
    g = 180; // Slightly reduced green for contrast
    b = Math.round(80 * (1 - factor) + 80); // Interpolates from 160 to 80
  }

  return `rgb(${r}, ${g}, ${b})`;
};

export const convertMoveTreeToPGN = (moves) => {
  const root = moves.root.next;
  if (!root) return ""; // Empty move tree

  let pgn = "";
  let moveNumber = 1;

  const processMove = (
    moveId,
    variation = false,
    variationMoveNumber = null
  ) => {
    let move = moves[moveId];
    if (!move) return "";

    let moveText = "";

    let currentMoveNumber =
      variationMoveNumber !== null ? variationMoveNumber : moveNumber;
    let pgnMoveNumber = Math.ceil(currentMoveNumber / 2);
    let isWhiteMove = currentMoveNumber % 2 !== 0; // Odd = White, Even = Black

    if (isWhiteMove || variation) {
      moveText += pgnMoveNumber + (isWhiteMove ? ". " : "... ");
    }

    moveText += move.san;

    let annotations = [];

    if (move.comment) {
      annotations.push(`{ ${move.comment.trim()} }`);
    }

    if (annotations.length > 0) {
      moveText += " " + annotations.join(" ");
    }

    if (move.variations && move.variations.length > 0) {
      for (const variationId of move.variations) {
        moveText +=
          " (" + processMove(variationId, true, currentMoveNumber) + ")";
      }
    }

    if (move.next) {
      moveText += " " + processMove(move.next, false, currentMoveNumber + 1);
    }

    return moveText;
  };

  pgn = processMove(root);
  return pgn.trim();
};

export const getPlanIcon = (plan) => {
  switch (plan) {
    case "pro":
      return (
        <VerifiedUserTwoToneIcon sx={{ color: "#FFA500", width: "30px" }} />
      );
    case "basic":
      return <VerifiedTwoToneIcon sx={{ color: "#D3D3D3", width: "30px" }} />;
    case "free":
      return <GrassIcon sx={{ color: "#188A7D", width: "30px" }} />;
    case "vip":
      return <AllInclusiveIcon sx={{ color: "#FFA500", width: "30px" }} />;
    default:
      return <GrassIcon sx={{ color: "#188A7D", width: "30px" }} />;
  }
};

/*
  const reverseColorMap = {
    shift: "R", // Red
    ctrl: "G", // Green
    alt: "Y", // Yellow
    default: "B", // Blue
  };

  const formatArrowsToPGN = (arrows) => {
    if (!arrows || arrows.length === 0) return "";

    const arrowStrings = arrows.map(([from, to, key]) => {
      let pgnColor = reverseColorMap[key] || "B"; // Default to Blue if not found
      return `${pgnColor}${from}${to}`;
    });

    return `[%cal ${arrowStrings.join(", ")}]`;
  };

  const formatHighlightsToPGN = (highlights) => {
    if (!highlights || highlights.length === 0) return "";

    const highlightStrings = highlights.map((entry) => {
      // Support both object-based and array-based highlights
      const square = Array.isArray(entry) ? entry[0] : entry.square;
      const colorKey = Array.isArray(entry) ? entry[1] : entry.color;

      let pgnColor = reverseColorMap[colorKey] || "B"; // Default to Blue if unknown
      return `${pgnColor}${square}`;
    });

    return `[%csl ${highlightStrings.join(", ")}]`;
  };*/
