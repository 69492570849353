const tourSteps = {
  Builder: [
    {
      target: "#builder-chessboardcard",
      content:
        "Use the chessboard to play through moves and build your repertoire step by step.",
    },
    {
      target: "#builder-repertoirecard",
      content:
        "Manage and access all your repertoires here. Select a repertoire to edit or explore different opening lines.",
    },
    {
      target: "#builder-repertoirecard-add",
      content:
        "Click here to add a new repertoire and start building your opening lines.",
    },
    {
      target: "#builder-repertoirecard-import",
      content:
        "Import a repertoire by pasting a PGN, uploading a PGN file, or searching for a public Lichess study.",
    },
    {
      target: "#builder-repertoirecard-template",
      content:
        "Get started quickly by selecting one of our prebuilt starter templates.",
    },
    {
      target: "#builder-repertoirecard-wizard",
      content:
        "Kick-start your repertoire with the Repertoire Wizard. Set up a position on the board and automatically generate opening moves for White or Black using top Stockfish moves.",
    },
    {
      target: "#builder-movescard",
      content:
        "Explore your repertoire with the moves display. Click on a move to navigate through your repertoire, or right-click to open the moves menu for additional options. You can also use your left and right arrow keys to move through the game quickly",
    },
    {
      target: "#builder-movescard-menu",
      content:
        "Customize your view by toggling display options, changing the move list format, or showing and hiding comments and annotations.",
    },
    {
      target: "#builder-movescard-comment",
      content:
        "Add comments to each move to annotate your repertoire and enhance your understanding of different positions.",
    },
    {
      target: "#builder-movescard-annotation",
      content:
        "Add chess annotations to each move for further clarity and deeper analysis of your repertoire.",
    },
    {
      target: "#builder-movescard-opening",
      content:
        "View opening details related to the current position on the board, including name, ECO code, and typical move sequences.",
    },
    {
      target: "#builder-movescard-analysis",
      content:
        "Run analysis over your entire repertoire to evaluate each move. Once completed, you can toggle the view to highlight evaluations, providing deeper insights into your repertoire.",
    },
    {
      target: "#builder-movescard-export",
      content:
        "Copy the PGN of your repertoire to share, analyze, or import it elsewhere.",
    },
    {
      target: "#builder-enginecard",
      content:
        "View the Stockfish evaluation for the current board position. Adjust the depth and the number of lines to refine the analysis. Click on the line to immediately add the move to your repertoire.",
    },
    {
      target: "#builder-masterscard",
      content:
        "Explore the top moves played from this position by both masters and Lichess players. Filter Lichess data by rating range and time control to see how different players handle it. You can also browse and play through the top master games for deeper insights. Click on any move to instantly add it to your repertoire",
    },
  ],
  Trainer: [
    {
      target: "#trainer",
      content:
        "The Trainer allows you to practice your repertoire by playing through your moves and reinforcing your opening knowledge.",
    },
    {
      target: "#trainer-type",
      content:
        "Choose between Position Trainer, which selects a random position from your repertoire for you to analyze, or Repertoire Trainer, where you play through your repertoire while the system selects a random move from it.",
    },
    {
      target: "#trainer-repertoire",
      content:
        "Select from your available repertoires to train and reinforce your opening knowledge.",
    },
    {
      target: "#trainer-color",
      content:
        "Choose whether to practice as White or Black to focus on your repertoire from either side.",
    },
    {
      target: "#trainer-start",
      content:
        "Click 'Train' to begin your training journey, or 'Quit' to return and review your repertoire.",
    },
  ],
  Games: [
    {
      target: "#games",
      content:
        "Import and manage your games here. Analyze your past games and compare them with your repertoire.",
    },
    {
      target: "#games-import",
      content:
        "Import your games by pasting a PGN, uploading a PGN file, or fetching games from Chess.com or Lichess.org.",
    },
    {
      target: "#games-delete",
      content:
        "Delete all your imported games to keep your database clean and organized.",
    },
    {
      target: "#games-filters",
      content:
        "Filter your imported games by date, time control, opening, or other criteria to quickly find the games you need.",
    },
    {
      target: "#games-pagination",
      content:
        "Navigate efficiently through your games using pagination. Adjust the number of games displayed per page for better control. Click on any game to explore it in more depth, view move-by-move analysis, and see how it compares to your repertoire.",
    },
    {
      target: "#games-usernames",
      content:
        "To compare your games against your repertoire, enter one or more usernames to identify your games. Once added, you'll see your repertoires along with your games, wins, losses, and performance percentages. Click on the Repertoire Card to filter your games.",
    },
  ],
  GameOpen: [
    {
      target: "#games-chessboardcard",
      content:
        "Use the chessboard to play through moves and build your repertoire step by step.",
    },
    {
      target: "#games-movescard",
      content:
        "Explore your repertoire with the moves display. Click on a move to navigate through your repertoire, or right-click to open the moves menu for additional options. You can also use your left and right arrow keys to move through the game quickly",
    },
    {
      target: "#games-movescard-menu",
      content:
        "Customize your view by toggling display options, changing the move list format, or showing and hiding comments and annotations.",
    },
    {
      target: "#games-movescard-comment",
      content:
        "Add comments to each move to annotate your repertoire and enhance your understanding of different positions.",
    },
    {
      target: "#games-movescard-annotation",
      content:
        "Add chess annotations to each move for further clarity and deeper analysis of your repertoire.",
    },
    {
      target: "#games-movescard-opening",
      content:
        "View opening deta,ils related to the current position on the board, including name, ECO code, and typical move sequences.",
    },
    {
      target: "#games-movescard-analysis",
      content:
        "Run analysis over your entire game to evaluate each move. Once completed, you can toggle the view to highlight evaluations and see the game progression according to Stockfish, gaining deeper insights into your play.",
    },
    {
      target: "#games-movescard-export",
      content:
        "Copy the PGN of your repertoire to share, analyze, or import it elsewhere.",
    },
    {
      target: "#games-repertoirecard",
      content:
        "Compare your game against your repertoire to identify deviations and reinforce your opening knowledge.",
    },
    {
      target: "#builder-enginecard",
      content:
        "View the Stockfish evaluation for the current board position. Adjust the depth and the number of lines to refine the analysis. Click on the line to immediately add the move to your repertoire.",
    },
    {
      target: "#builder-masterscard",
      content:
        "Explore the top moves played from this position by both masters and Lichess players. Filter Lichess data by rating range and time control to see how different players handle it. You can also browse and play through the top master games for deeper insights. Click on any move to instantly add it to your repertoire",
    },
    {
      target: "#games-gamedatacard",
      content:
        "Edit your game data here, including metadata such as game result, players, date, and custom tags.",
    },
  ],
};

export default tourSteps;
