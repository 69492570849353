import React, { useEffect } from "react";
import { useTheme } from "@mui/material";
import Joyride from "react-joyride";
import tourSteps from "./tour";
import { tokens } from "../../../../styles/theme";

const ApplicationTour = ({
  runTour,
  setRunTour,
  activePage,
  selectedUserGameId,
  tourKey,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Reset tour when the page changes
  useEffect(() => {
    setRunTour((prev) => {
      if (prev) {
        return false;
      }
      return prev;
    });
  }, [activePage, setRunTour]);

  return (
    <Joyride
      key={tourKey} // Forces Joyride to restart fresh from Step 1
      steps={(
        tourSteps[
          activePage === "Games" && selectedUserGameId ? "GameOpen" : activePage
        ] || []
      ).map((step, index) => ({
        ...step,
        disableBeacon: true, // ✅ Only first step opens automatically
        disableOverlay: false, // ✅ Allow interaction with the page
        spotlightClicks: false, // ✅ Allow clicking inside highlighted elements
      }))}
      run={runTour}
      showSkipButton
      showProgress
      continuous={true} // ✅ Users can explore freely
      disableCloseOnEsc={false} // ✅ Allow ESC key to close
      disableOverlayClose={true} // ✅ Allow clicking outside tooltip to close
      locale={{
        last: "Close",
        next: "Next",
        skip: "Skip",
        back: "Back",
      }}
      callback={(data) => {
        // ✅ Detect if the user pressed "X", ESC, or clicked outside
        if (
          ["finished", "skipped", "close", "error:target_not_found"].includes(
            data.status
          ) ||
          data.action === "close"
        ) {
          setRunTour(false); // ✅ Forcefully stop the tour
        }
      }}
      styles={{
        options: {
          primaryColor: colors.red[500], // ✅ Button color
          textColor: "#414141",
          backgroundColor: "#FFFFFF",
          arrowColor: "#FFFFFF",
        },
        buttonNext: {
          backgroundColor: colors.green[100],
          color: "#FFFFFF",
        },
        buttonBack: {
          color: colors.black[500],
        },
        buttonSkip: {
          color: colors.black[500],
        },
      }}
    />
  );
};

export default ApplicationTour;
