import React, { useEffect, useCallback, useRef, useState } from "react";
import { Chessboard } from "react-chessboard";
import { Chess } from "chess.js";
import {
  Box,
  Button,
  Card,
  MenuItem,
  Select,
  useTheme,
  Fade,
  Alert,
  Tooltip,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ReplayIcon from "@mui/icons-material/Replay";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import SwapVertRoundedIcon from "@mui/icons-material/SwapVertRounded";
import { themeColors } from "../../../../styles/boardtheme";
import { pieceSets } from "../../../../styles/pieceset";
import { tokens } from "../../../../styles/theme";

import TrainingMovesCard from "./trainerMovesCard";

const RepertoireTrainerCard = ({
  moves,
  selectedMoveId,
  setSelectedMoveId,
  userData,
  repertoires,
  selectedRepertoireId,
  setSelectedRepertoireId,
  activePage,
  result,
  setResult,
  quit,
  setQuit,
  trainingMode,
  setTrainingMode,
}) => {
  const chessboardRef = useRef(null);
  const [game, setGame] = useState(new Chess());
  const [showAlert, setShowAlert] = useState(false);
  const [boardOrientation, setBoardOrientation] = useState(
    repertoires[selectedRepertoireId]?.boardOrientation
  );
  const [requiredMove, setRequiredMove] = useState(null);
  const [trainingStarted, setTrainingStarted] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [showCard, setShowCard] = useState(false);

  const handleTrainClick = () => {
    if (!selectedRepertoireId || !moves["root"]) return;

    const firstMoveId = moves["root"].next;
    if (firstMoveId) {
      setRequiredMove(firstMoveId);
      setTrainingStarted(true);
      setShowAlert(false);
      setShowCard(false);
      setLastMove({ from: null, to: null });
      setGame(new Chess());
    }

    if (boardOrientation === "black") {
      makeApplicationMove(firstMoveId);
    }
  };

  const handleMove = (sourceSquare, targetSquare) => {
    try {
      const newGame = new Chess();
      newGame.loadPgn(game.pgn());

      const move = newGame.move({
        from: sourceSquare,
        to: targetSquare,
        promotion: "q",
      });

      if (!move || !requiredMove) {
        setResult("incorrect");
        setShowAlert(true);
        return false;
      }

      // Get the required move and variations clearly
      const possibleMoves = [
        moves[requiredMove],
        ...(moves[requiredMove]?.variations || []).map((v) => moves[v]),
      ].filter(Boolean);

      // Find the move ID matching the played SAN
      const matchedMove = possibleMoves.find((m) => m.san === move.san);

      if (!matchedMove) {
        setResult("incorrect");
        setShowAlert(true);
        return false;
      }

      setGame(newGame);
      setResult("correct");
      setShowAlert(true);
      setLastMove({ from: sourceSquare, to: targetSquare });
      setSelectedMoveId(matchedMove.id);

      if (matchedMove.next) {
        setRequiredMove(matchedMove.next);
        makeApplicationMove(matchedMove.next);
      } else {
        setResult("completed");
        setShowCard(true);
        setShowAlert(true);
      }

      return true;
    } catch (error) {
      setResult("incorrect");
      setShowAlert(true);
      return false;
    }
  };

  const handleSkipMove = () => {
    if (!requiredMove || !moves[requiredMove]) return;

    const skippedMove = moves[requiredMove];

    setGame((prevGame) => {
      const newGame = new Chess();
      newGame.loadPgn(prevGame.pgn());
      const moveResult = newGame.move(skippedMove.san);

      if (!moveResult) return prevGame;

      const history = newGame.history({ verbose: true });
      if (history.length > 0) {
        const lastMove = history[history.length - 1];
        setLastMove({ from: lastMove.from, to: lastMove.to });
      }

      setSelectedMoveId(requiredMove);
      return newGame;
    });

    if (skippedMove.next) {
      setRequiredMove(skippedMove.next);

      setTimeout(() => {
        makeApplicationMove(skippedMove.next);
      }, 500); // short 0.5-second delay before AI plays
    } else {
      setResult("completed");
      setShowCard(true);
    }
  };

  const makeApplicationMove = (currentMoveId) => {
    // Delay AI move before execution
    setTimeout(() => {
      const moveOptions = [
        moves[currentMoveId]?.id,
        ...(moves[currentMoveId]?.variations || []),
      ].filter(Boolean);

      if (moveOptions.length === 0) {
        return;
      }

      const randomMoveId =
        moveOptions[Math.floor(Math.random() * moveOptions.length)];

      if (!moves[randomMoveId]) {
        return;
      }

      // Directly modify the current game state
      setGame((prevGame) => {
        const newGame = new Chess();
        newGame.loadPgn(prevGame.pgn()); // Preserve existing game state

        const moveResult = newGame.move(moves[randomMoveId].san);

        if (!moveResult) {
          return prevGame; // Don't update if move is invalid
        }

        // Get the last move after the move is applied
        const history = newGame.history({ verbose: true });
        if (history.length > 0) {
          const lastMove = history[history.length - 1]; // Get the last move object
          setLastMove({ from: lastMove.from, to: lastMove.to }); // Correctly set lastMove
        }

        setSelectedMoveId(randomMoveId);

        return newGame;
      });

      if (moves[randomMoveId].next == null) {
        setResult("completed");
        setShowCard(true);
        setShowAlert(true);
        return;
      }

      setRequiredMove(moves[randomMoveId].next); // Set next move for user
    }, 1000); // 1s delay before AI move is made
  };

  const getMoveSequence = useCallback(() => {
    let currentMoveId = selectedMoveId;
    let movePath = [];

    while (currentMoveId && moves[currentMoveId]) {
      const san = moves[currentMoveId].san;
      if (san && san.trim() !== "") {
        movePath.unshift(san); // Insert at the beginning to maintain move order
      }
      currentMoveId = moves[currentMoveId].parent; // Move backwards to the parent
    }

    return movePath; // Return the sequence from root to selectedMoveId
  }, [selectedMoveId, moves]);

  const handleRepertoireChange = (event) => {
    const selectedId = event.target.value;

    setSelectedRepertoireId(selectedId);
    setResult(null);
    setQuit(false);

    const repertoire = repertoires[selectedId];
    if (repertoire) {
      setBoardOrientation(repertoire.boardOrientation || "white");
    }
    setSelectedMoveId("root");
  };

  const [lastMove, setLastMove] = useState({ from: null, to: null });

  const getSquareStyles = () => {
    const styles = {}; // Initialize an empty object for square styles

    if (lastMove.from) {
      styles[lastMove.from] = { backgroundColor: "rgba(173, 216, 230, 0.5)" }; // Light blue for from-square
    }
    if (lastMove.to) {
      styles[lastMove.to] = { backgroundColor: "rgba(173, 216, 230, 0.5)" }; // Light green for to-square
    }

    return styles; // Return the styles object
  };

  const handleNext = useCallback(() => {
    if (result === "incorrect" || result === null) return;

    if (!selectedMoveId) return; // No move selected

    const currentMove = moves[selectedMoveId];
    if (!currentMove || !currentMove.next) return; // No next move available

    const nextMoveId = currentMove.next;
    const nextMove = moves[nextMoveId];

    if (!nextMove) return; // Defensive check

    setGame((prevGame) => {
      const newGame = new Chess();
      newGame.loadPgn(prevGame.pgn()); // Load existing moves
      newGame.move(nextMove.san); // Play the next move
      return newGame;
    });

    setSelectedMoveId(nextMoveId); // Update selected move
  }, [selectedMoveId, result, moves, setGame, setSelectedMoveId]);

  const handleQuit = () => {
    setResult("quit");
    setQuit(false);
    setShowCard(true);
    setShowAlert(false);
  };

  const handleBack = useCallback(() => {
    if (result === "incorrect" || result === null) return;
    if (!selectedMoveId || selectedMoveId === "root") return; // Do nothing if at the root

    const currentMove = moves[selectedMoveId];
    if (!currentMove || !currentMove.parent) return; // No parent = already at the start

    setGame((prevGame) => {
      const newGame = new Chess();
      newGame.loadPgn(prevGame.pgn()); // Load existing moves
      newGame.undo(); // Undo the last move
      return newGame;
    });

    setSelectedMoveId(currentMove.parent); // Move back to the parent move
  }, [selectedMoveId, result, moves, setGame, setSelectedMoveId]);

  useEffect(() => {
    if (showAlert && result !== "completed") {
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 2000); // Alert fades out after 3 seconds for other cases

      return () => clearTimeout(timer); // Cleanup in case component unmounts
    }
  }, [showAlert, result]); // Dependency includes result
  // useEffect to update the game when selectedMoveId changes
  useEffect(() => {
    if (!selectedMoveId || !moves[selectedMoveId]) return;

    setGame(() => {
      const newGame = new Chess();
      const moveSequence = getMoveSequence();

      moveSequence.forEach((san) => {
        newGame.move(san); // Apply each move to reconstruct the game state
      });

      // Capture the last move (from & to squares)
      const history = newGame.history({ verbose: true });
      if (history.length > 0) {
        const lastMove = history.slice(-1)[0]; // Get the last move
        setLastMove({ from: lastMove.from, to: lastMove.to });
      } else {
        setLastMove({ from: null, to: null }); // Reset when no moves exist
      }

      return newGame;
    });
  }, [selectedMoveId, moves, getMoveSequence]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (activePage !== "Trainer") return; // Only handle events when activePage is "Trainer"

      switch (event.key) {
        case "ArrowLeft":
          handleBack();
          break;
        case "ArrowRight":
          handleNext();
          break;
        default:
          break;
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleBack, handleNext, activePage]);

  useEffect(() => {
    setGame(new Chess());
    setLastMove({ from: null, to: null });
  }, []);

  return (
    <Box display="flex" flexDirection="row" sx={{ p: 2, gap: 2 }}>
      <Box
        sx={{
          p: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "35%",
          minWidth: "400px",
          mb: 10,
        }}
      >
        <Card
          sx={{
            position: "relative",
            backgroundColor: colors.background[100], // Card background color
            color: colors.black[900], // Text color
            backgroundImage: "none",
            width: "100%",
            clipPath:
              "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
            "::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "transparent",
              border: "1px solid rgba(0, 0, 0, 0.2)",
              clipPath:
                "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
              zIndex: -1,
              pointerEvents: "none",
            },
            p: 2,
            mb: 2,
          }}
        >
          <Box ref={chessboardRef}>
            <Chessboard
              position={game.fen()}
              onPieceDrop={handleMove}
              areArrowsAllowed={true}
              boardOrientation={boardOrientation}
              customSquareStyles={getSquareStyles()}
              customLightSquareStyle={{
                backgroundColor:
                  themeColors[userData?.theme || "White Stripe Theme"]
                    .lightSquare,
                backgroundImage:
                  themeColors[userData?.theme || "White Stripe Theme"]
                    .lightSquarePattern,
              }}
              customDarkSquareStyle={{
                backgroundColor:
                  themeColors[userData?.theme || "White Stripe Theme"]
                    .darkSquare,
                backgroundImage:
                  themeColors[userData?.theme || "White Stripe Theme"]
                    .darkSquarePattern,
              }}
              customPieces={pieceSets[userData?.pieceset || "Wikipedia"]}
            />
          </Box>
          {result !== "incorrect" && result !== null && (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
              justifyContent="flex-start"
              textAlign="left"
              sx={{ pt: 2, width: "100%" }}
            >
              <Tooltip title="Reset the game">
                <span>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setSelectedMoveId("root");
                      setGame(new Chess());
                    }}
                    style={{ marginRight: 10 }}
                    disabled={selectedMoveId === "root"}
                    sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                  >
                    <ReplayIcon />
                  </Button>
                </span>
              </Tooltip>

              <Tooltip title="Go to previous move">
                <span>
                  <Button
                    variant="contained"
                    onClick={handleBack}
                    disabled={selectedMoveId === "root"}
                    style={{ marginRight: 10 }}
                    sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                  >
                    <KeyboardDoubleArrowLeftIcon />
                  </Button>
                </span>
              </Tooltip>

              <Tooltip title="Go to next move">
                <span>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    disabled={moves[selectedMoveId]?.next === null}
                    style={{ marginRight: 10 }}
                    sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                  >
                    <KeyboardDoubleArrowRightIcon />
                  </Button>
                </span>
              </Tooltip>

              <Tooltip title="Toggle board orientation">
                <span>
                  <Button
                    variant="contained"
                    onClick={() =>
                      setBoardOrientation((prev) =>
                        prev === "white" ? "black" : "white"
                      )
                    }
                    sx={{ padding: 0, minWidth: 40, minHeight: 40 }}
                  >
                    <SwapVertRoundedIcon />
                  </Button>
                </span>
              </Tooltip>
            </Box>
          )}
        </Card>

        <Card
          id="trainer"
          sx={{
            position: "relative",
            backgroundColor: colors.background[100], // Card background color
            color: colors.black[900], // Text color
            backgroundImage: "none",
            width: "100%",
            clipPath:
              "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
            "::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "transparent",
              border: "1px solid rgba(0, 0, 0, 0.2)",
              clipPath:
                "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
              zIndex: -1,
              pointerEvents: "none",
            },
            p: 2,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Select
              id="trainer-type"
              value={trainingMode}
              onChange={(e) => {
                setTrainingMode(e.target.value);
                setResult(null);
              }}
              fullWidth
              variant="outlined"
            >
              <MenuItem value="positionTrainer">Position Trainer</MenuItem>
              <MenuItem value="repertoireTrainer">Repertoire Trainer</MenuItem>
            </Select>
            <Select
              id="trainer-repertoire"
              sx={{ width: "100%" }}
              value={selectedRepertoireId}
              onChange={handleRepertoireChange}
            >
              {Object.entries(repertoires)
                .sort(([, a], [, b]) => (a.order || 0) - (b.order || 0))
                .map(([repId, repData]) => (
                  <MenuItem key={repId} value={repId}>
                    {repData.title}
                  </MenuItem>
                ))}
            </Select>
          </Box>

          <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
            <Select
              id="trainer-color"
              value={boardOrientation}
              onChange={(e) => setBoardOrientation(e.target.value)}
            >
              <MenuItem value="white">White</MenuItem>
              <MenuItem value="black">Black</MenuItem>
            </Select>
            <Button
              id="trainer-start"
              variant="contained"
              color="secondary"
              onClick={handleTrainClick}
              disabled={!selectedRepertoireId}
              startIcon={<PlayArrowIcon />}
              sx={{
                backgroundColor: colors.green[100],
                "&:hover": {
                  backgroundColor: colors.green[200],
                },
              }}
            >
              Train
            </Button>

            {trainingStarted && !showCard && (
              <>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleQuit}
                  startIcon={<CancelOutlinedIcon />}
                  sx={{
                    backgroundColor: colors.red[500],
                    "&:hover": {
                      backgroundColor: colors.red[600],
                    },
                  }}
                >
                  Quit
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  onClick={handleSkipMove}
                  sx={{
                    backgroundColor: colors.orange[500],
                    "&:hover": {
                      backgroundColor: colors.orange[600],
                    },
                  }}
                >
                  Skip Move
                </Button>
              </>
            )}
          </Box>
        </Card>
      </Box>
      <Box
        sx={{
          p: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "65%",
          mb: 10,
        }}
      >
        {showAlert && (
          <Fade in={showAlert} timeout={500} mountOnEnter unmountOnExit>
            <Alert
              variant="outlined"
              sx={{ width: "100%", mb: 2 }}
              severity={
                result === "correct"
                  ? "success"
                  : result === "completed"
                  ? "info"
                  : "error"
              }
              onClose={() => setShowAlert(false)}
            >
              {result === "correct"
                ? "Correct Move!"
                : result === "incorrect"
                ? "Incorrect Move!"
                : "You have reached the end of the line!"}
            </Alert>
          </Fade>
        )}
        <TrainingMovesCard
          moves={moves}
          showCard={showCard}
          repertoires={repertoires}
          selectedRepertoireId={selectedRepertoireId}
          selectedMoveId={selectedMoveId}
          setSelectedMoveId={setSelectedMoveId}
        />
      </Box>
    </Box>
  );
};

export default RepertoireTrainerCard;
