import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Box,
  Tab,
  Tabs,
  TextField,
  Typography,
  Tooltip,
  useTheme,
} from "@mui/material";
import { tokens } from "../../../../styles/theme";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardOptionKeyIcon from "@mui/icons-material/KeyboardOptionKey";
import CommentIcon from "@mui/icons-material/Comment";
import DoneIcon from "@mui/icons-material/Done";
import TroubleshootSharpIcon from "@mui/icons-material/TroubleshootSharp";
import ReplaySharpIcon from "@mui/icons-material/ReplaySharp";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import DownloadSharpIcon from "@mui/icons-material/DownloadSharp";

import useCategories from "../Components/useCategories";
import useLevelColours from "../Components/useLevelColours";

import { runUserGameAnalysis } from "../Components/runRepertoireAnalysis";
import MyGamesAnalysisLineChart from "./myGamesAnalysisLineChart";

import { getEvalColor } from "../Components/helper";

import OpeningCard from "../Components/openingCard";
import PGNCard from "../Components/PGNCard";

const MovesCardMyGames = ({
  moves,
  setMoves,
  selectedMoveId,
  setSelectedMoveId,
  selectedUserGameId,
  setTasks,
  tasksRef,
  userGames,
  setUserGames,
  userData,
}) => {
  const categories = useCategories();
  const [showComments, setShowComments] = useState(true);
  const [showEvaluations, setShowEvaluations] = useState(false);
  const [linesMenuAnchor, setLinesMenuAnchor] = useState(null);
  const [collapsedLines, setCollapsedLines] = useState(new Set()); // ✅ Track only collapsed lines
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const selectedMoveRef = useRef(null);
  const [depth, setDepth] = useState(12);

  const toggleLineExpansion = (moveId) => {
    setCollapsedLines((prev) => {
      const newExpanded = new Set(prev);
      if (newExpanded.has(moveId)) {
        newExpanded.delete(moveId); // Collapse line
      } else {
        newExpanded.add(moveId); // Expand line
      }
      return newExpanded;
    });
  };

  const handleShowCommentsChange = () => {
    setShowComments((prevShowComments) => !prevShowComments);
    handleLinesMenuClose();
  };

  const handleLinesMenuOpen = (event) => {
    setLinesMenuAnchor(event.currentTarget);
  };

  const handleLinesMenuClose = () => {
    setLinesMenuAnchor(null);
  };

  const [tabValue, setTabValue] = useState(0);

  const [moveMenuAnchor, setMoveMenuAnchor] = useState(null);

  const handleRightClick = (event, move) => {
    event.preventDefault(); // Prevent the default context menu from appearing
    setSelectedMoveId(move); // Track the move associated with the context menu
    handleMoveClick(move); // Handle the click action for the move
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue); // Update the state to reflect the new active tab
  };

  const [comment, setComment] = useState("");

  const handleCommentChange = (event) => {
    const newComment = event.target.value;
    setComment(newComment); // Update the comment field state

    if (selectedMoveId) {
      setMoves((prevMoves) => ({
        ...prevMoves,
        [selectedMoveId]: {
          ...prevMoves[selectedMoveId], // Preserve existing move data
          comment: newComment, // Update the comment field
        },
      }));
    }
  };

  // State for comments and annotations
  const [annotation, setAnnotation] = useState("");
  const [showAnnotations, setShowAnnotations] = useState(true);
  const handleAnnotationChange = (newSymbol) => {
    setAnnotation((prevAnnotation) => {
      const updatedAnnotation = prevAnnotation === newSymbol ? "" : newSymbol; // Toggle annotation

      if (selectedMoveId) {
        setMoves((prevMoves) => {
          return {
            ...prevMoves,
            [selectedMoveId]: {
              ...prevMoves[selectedMoveId], // Preserve existing move data
              annotations: updatedAnnotation, // Update the annotation field
            },
          };
        });
      }

      return updatedAnnotation; // Update state with the new annotation value
    });
  };

  const handleShowAnnotationChange = () => {
    setShowAnnotations((prevShowAnnotations) => !prevShowAnnotations);
    handleLinesMenuClose();
  };

  const [displayMovesFormat, setDisplayMovesFormat] = useState("tree");
  const handleDisplayMoveFormatChange = (format) => {
    setDisplayMovesFormat(format);
    handleLinesMenuClose();
  };

  const handleShowEvaluationsChange = () => {
    setShowEvaluations((prevShowEvaluations) => !prevShowEvaluations);
    handleLinesMenuClose();
  };

  const getCategoryColorBySymbol = useCallback(
    (symbol) => {
      const allItems = Object.values(categories).flat(); // Flatten all category items
      const item = allItems.find((item) => item.symbol === symbol); // Find the matching symbol
      return item ? item.color : null; // Return the color if found, else null
    },
    [categories]
  );

  const handleMoveClick = (node) => {
    if (!node || !node.id) return;

    // Update the selected move ID
    setSelectedMoveId(node.id);

    // Update the comment and annotation fields based on the selected move
    setComment(node.comment || "");
    setAnnotation(node.annotations || "");
  };

  const handleMoveMenuOpen = (event, move) => {
    event.preventDefault(); // Prevent the default right-click behavior (e.g., browser's context menu)
    setMoveMenuAnchor(event.currentTarget); // Set the anchor element for the move menu
    setSelectedMoveId(move); // Track the currently selected move
  };

  const levelColors = useLevelColours();

  const buildMoveLines = (
    moveId = "root",
    level = 0,
    lines = [],
    currentLine = null,
    moveNumber = 1, // ✅ Ensure move numbers start from 1
    inheritedMoveNumber = null
  ) => {
    if (!moveId || !moves[moveId]) return lines;

    // ✅ Skip "root" and start from root.next
    if (moveId === "root" && moves[moveId].next) {
      return buildMoveLines(moves[moveId].next, level, lines, [], 1);
    }

    const node = moves[moveId];

    // ✅ Initialize `currentLine` if it's null (first move)
    if (!currentLine) {
      currentLine = [];
    }

    // ✅ Assign correct move number (inherit from parent for variations)
    const assignedMoveNumber = inheritedMoveNumber ?? moveNumber;

    // ✅ Store the move number in the move object
    const moveData = {
      ...node,
      id: moveId, // ✅ Ensure move ID is stored
      level,
      moveNumber: assignedMoveNumber, // ✅ Correct move numbering
    };

    currentLine.push(moveData);

    // ✅ If variations exist, push the current line and process variations separately
    if (node.variations && node.variations.length > 0) {
      lines.push([...currentLine]); // Save the current line before processing variations

      node.variations.forEach((variationId) => {
        buildMoveLines(
          variationId,
          level + 1,
          lines,
          [], // Start a new indented line
          moveNumber, // ✅ Maintain move number for variations
          assignedMoveNumber // ✅ Inherit move number for variations
        );
      });

      currentLine = []; // Reset current line after variations
    }

    // ✅ If the move has a `next`, continue in the same line and increment move number after a White move
    if (node.next) {
      return buildMoveLines(
        node.next,
        level,
        lines,
        currentLine,
        moveNumber + 1, // ✅ Increment after White moves only
        null // ✅ Reset inherited move number for mainline moves
      );
    }

    // ✅ If no more moves, push the last collected line (if not empty)
    if (currentLine.length > 0) {
      lines.push(currentLine);
    }

    return lines;
  };

  const displayMoveLinesPGN = () => {
    const root = moves.root.next;
    if (!root) return null; // Empty move tree

    let moveNumber = 1;

    const processMove = (
      moveId,
      variation = false,
      variationMoveNumber = null
    ) => {
      let move = moves[moveId];
      if (!move) return null;
      move = { ...move, id: moveId };
      let elements = [];

      let currentMoveNumber =
        variationMoveNumber !== null ? variationMoveNumber : moveNumber;
      let pgnMoveNumber = Math.ceil(currentMoveNumber / 2);
      let isWhiteMove = currentMoveNumber % 2 !== 0; // Odd = White, Even = Black

      let movePrefix =
        isWhiteMove || variation
          ? `${pgnMoveNumber}${isWhiteMove ? ". " : "... "}`
          : "";

      elements.push(
        <React.Fragment key={move.id}>
          <span
            ref={selectedMoveId === move.id ? selectedMoveRef : null}
            style={{
              padding: "3px",
              cursor: "pointer",
              display: "inline",
              fontWeight: selectedMoveId === move.id ? "bold" : "normal",
              textDecoration: selectedMoveId === move.id ? "underline" : "none",
              color:
                showAnnotations && getCategoryColorBySymbol(move.annotations),
            }}
            onClick={() => handleMoveClick(move)}
            onContextMenu={(event) => {
              event.preventDefault();
              handleMoveClick(move);
              handleMoveMenuOpen(event, move);
              handleRightClick(event, move);
            }}
          >
            {movePrefix}
            {move.san}
            {move.annotations && showAnnotations && (
              <span
                style={{
                  color: getCategoryColorBySymbol(move.annotations),
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {move.annotations}
              </span>
            )}
          </span>
          {move.transpositionParent && moves[move.transpositionParent] && (
            <span
              key={`${move.id}-transposition`}
              style={{
                paddingLeft: "0px",
                paddingRight: "4px",
                cursor: "pointer",
                display: "inline",
                color: "grey",
              }}
              onClick={() => {
                const transpositionMove = {
                  ...moves[move.transpositionParent],
                  id: move.transpositionParent, // ✅ Inject the ID manually
                };
                handleMoveClick(transpositionMove);
              }}
            >
              ↪T
            </span>
          )}

          {move.eval && showEvaluations && (
            <span
              style={{
                paddingLeft: "0px",
                paddingRight: "4px",
                color: "gray",
                display: "inline-block",
                wordBreak: "break-word",
              }}
            >
              {move.eval}
            </span>
          )}
          {move.comment && showComments && (
            <span
              style={{
                padding: "3px",
                color: "gray",
                display: "inline-block",
                wordBreak: "break-word",
              }}
            >
              {move.comment}
            </span>
          )}
        </React.Fragment>
      );

      if (move.variations && move.variations.length > 0) {
        for (const [index, variationId] of move.variations.entries()) {
          elements.push(
            <React.Fragment key={`${move.id}-var-${index}`}>
              {" ("}
              {processMove(variationId, true, currentMoveNumber)}
              {") "}
            </React.Fragment>
          );
        }
      }

      if (move.next) {
        elements.push(
          <React.Fragment key={`${move.id}-next`}>
            {" "}
            {processMove(move.next, false, currentMoveNumber + 1)}
          </React.Fragment>
        );
      }

      return elements;
    };

    return <>{processMove(root)}</>;
  };

  const displayMoveLinesTree = () => {
    const lines = buildMoveLines(); // Get structured move lines
    let visible = true; // Track whether lines should be shown based on parents
    let lastLevel = 0;

    return (
      <div
        className="scrollable-container"
        style={{ height: "400px", overflowY: "auto" }}
      >
        {lines.map((line, lineIndex) => {
          const firstMove = line[0]; // First move in this line
          const nextLine = lines[lineIndex + 1]; // Look ahead to the next line
          const hasVariations = nextLine && nextLine[0].level > firstMove.level;
          const isCollapsed = collapsedLines.has(firstMove.id);

          // ** Visibility Logic **
          if (firstMove.level === 0) {
            visible = true; // Reset visibility when reaching a top-level move
          }

          if (firstMove.level <= lastLevel) {
            visible = true;
          }

          if (!visible) {
            return null; // Skip rendering if hidden by a collapsed parent
          }

          // ✅ Reverse the logic: Assume expanded unless explicitly collapsed
          if (hasVariations && isCollapsed) {
            visible = false;
          }

          lastLevel = firstMove.level;

          return (
            <div
              key={lineIndex}
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginLeft: `${firstMove.level * 20}px`,
                color: levelColors[firstMove.level % levelColors.length],
              }}
            >
              {/* ✅ Move Toggle */}
              <span
                style={{
                  width: "30px important!", // ✅ Fixed width to maintain alignment
                  textAlign: "center",
                  display: "inline-block",
                  cursor: hasVariations ? "pointer" : "default",
                  fontWeight: "normal",
                  marginRight: "6px",
                  fontFamily: "monospace",
                }}
                onClick={
                  hasVariations ? () => toggleLineExpansion(firstMove.id) : null
                }
              >
                {hasVariations
                  ? isCollapsed
                    ? "[+]"
                    : "[-]"
                  : "\u00A0\u00A0\u00A0"}
              </span>

              {/* ✅ Move List */}
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                {line.map((move, index) => {
                  const fullMoveNumber = Math.ceil(move.moveNumber / 2);
                  const isWhiteMove = move.moveNumber % 2 !== 0;
                  const isStartOfLine = index === 0;

                  let movePrefix = "";
                  if (isWhiteMove) {
                    movePrefix = `${fullMoveNumber}. `;
                  } else if (isStartOfLine) {
                    movePrefix = `${fullMoveNumber}.. `;
                  }

                  return (
                    <React.Fragment key={move.id}>
                      <span
                        ref={
                          selectedMoveId === move.id ? selectedMoveRef : null
                        }
                        style={{
                          paddingRight: "5px",
                          cursor: "pointer",
                          display: "inline",
                          fontWeight:
                            selectedMoveId === move.id ? "bold" : "normal",
                          textDecoration:
                            selectedMoveId === move.id ? "underline" : "none",
                          color:
                            showAnnotations &&
                            getCategoryColorBySymbol(move.annotations),
                        }}
                        onClick={() => handleMoveClick(move)}
                        onContextMenu={(event) => {
                          event.preventDefault();
                          handleMoveClick(move);
                          handleMoveMenuOpen(event, move);
                          handleRightClick(event, move);
                        }}
                      >
                        {movePrefix}
                        {move.san}
                        {move.annotations && showAnnotations && (
                          <span
                            style={{
                              color: getCategoryColorBySymbol(move.annotations),
                              fontSize: "16px",
                              fontWeight: "bold",
                            }}
                          >
                            {move.annotations}
                          </span>
                        )}
                      </span>

                      {move.transpositionParent &&
                        moves[move.transpositionParent] && (
                          <span
                            style={{
                              paddingRight: "5px",
                              cursor: "pointer",
                              display: "inline",
                              color: "grey",
                            }}
                            onClick={() => {
                              const transpositionMove = {
                                ...moves[move.transpositionParent],
                                id: move.transpositionParent, // ✅ Inject the ID manually
                              };

                              handleMoveClick(transpositionMove);
                            }}
                          >
                            ↪T
                          </span>
                        )}
                      {move.eval && showEvaluations && (
                        <span
                          style={{
                            paddingLeft: "0px",
                            paddingRight: "4px",
                            color: "gray",
                            display: "inline-block",
                            wordBreak: "break-word",
                          }}
                        >
                          ({move.eval})
                        </span>
                      )}
                      {move.comment && showComments && (
                        <span
                          style={{
                            paddingLeft: "0px",
                            paddingRight: "8px",
                            color: "gray",
                            display: "inline-block",
                            wordBreak: "break-word",
                          }}
                        >
                          {`${move.comment}`}
                        </span>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const displayMoveLinesTreeEngine = () => {
    const lines = buildMoveLines(); // Get structured move lines
    let visible = true; // Track whether lines should be shown based on parents
    let lastLevel = 0;

    return (
      <div
        className="scrollable-container"
        style={{ height: "400px", overflowY: "auto" }}
      >
        {lines.map((line, lineIndex) => {
          const firstMove = line[0]; // First move in this line
          const nextLine = lines[lineIndex + 1]; // Look ahead to the next line
          const hasVariations = nextLine && nextLine[0].level > firstMove.level;
          const isCollapsed = collapsedLines.has(firstMove.id);

          // ** Visibility Logic **
          if (firstMove.level === 0) {
            visible = true; // Reset visibility when reaching a top-level move
          }

          if (firstMove.level <= lastLevel) {
            visible = true;
          }

          if (!visible) {
            return null; // Skip rendering if hidden by a collapsed parent
          }

          // ✅ Reverse the logic: Assume expanded unless explicitly collapsed
          if (hasVariations && isCollapsed) {
            visible = false;
          }

          lastLevel = firstMove.level;

          return (
            <div
              key={lineIndex}
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginLeft: `${firstMove.level * 20}px`,
              }}
            >
              {/* ✅ Move Toggle */}
              <span
                style={{
                  width: "30px important!", // ✅ Fixed width to maintain alignment
                  textAlign: "center",
                  display: "inline-block",
                  cursor: hasVariations ? "pointer" : "default",
                  fontWeight: "normal",
                  marginRight: "6px",
                  fontFamily: "monospace",
                }}
                onClick={
                  hasVariations ? () => toggleLineExpansion(firstMove.id) : null
                }
              >
                {hasVariations
                  ? isCollapsed
                    ? "[+]"
                    : "[-]"
                  : "\u00A0\u00A0\u00A0"}
              </span>

              {/* ✅ Move List */}
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                {line.map((move, index) => {
                  const fullMoveNumber = Math.ceil(move.moveNumber / 2);
                  const isWhiteMove = move.moveNumber % 2 !== 0;
                  const isStartOfLine = index === 0;

                  let movePrefix = "";
                  if (isWhiteMove) {
                    movePrefix = `${fullMoveNumber}. `;
                  } else if (isStartOfLine) {
                    movePrefix = `${fullMoveNumber}.. `;
                  }

                  return (
                    <React.Fragment key={move.id}>
                      <span
                        ref={
                          selectedMoveId === move.id ? selectedMoveRef : null
                        }
                        style={{
                          paddingRight: "5px",
                          cursor: "pointer",
                          display: "inline",
                          fontWeight:
                            selectedMoveId === move.id ? "bold" : "normal",
                          textDecoration:
                            selectedMoveId === move.id ? "underline" : "none",
                          color:
                            showAnnotations && move.annotations
                              ? getCategoryColorBySymbol(move.annotations)
                              : getEvalColor(move.eval),
                        }}
                        onClick={() => handleMoveClick(move)}
                        onContextMenu={(event) => {
                          event.preventDefault();
                          handleMoveClick(move);
                          handleMoveMenuOpen(event, move);
                          handleRightClick(event, move);
                        }}
                      >
                        {movePrefix}
                        {move.san}
                        {move.annotations && showAnnotations && (
                          <span
                            style={{
                              color: getCategoryColorBySymbol(move.annotations),
                              fontSize: "16px",
                              fontWeight: "bold",
                            }}
                          >
                            {move.annotations}
                          </span>
                        )}
                      </span>

                      {move.transpositionParent &&
                        moves[move.transpositionParent] && (
                          <span
                            style={{
                              paddingRight: "5px",
                              cursor: "pointer",
                              display: "inline",
                              color: "grey",
                            }}
                            onClick={() => {
                              const transpositionMove = {
                                ...moves[move.transpositionParent],
                                id: move.transpositionParent, // ✅ Inject the ID manually
                              };

                              handleMoveClick(transpositionMove);
                            }}
                          >
                            ↪T
                          </span>
                        )}
                      {move.eval && showEvaluations && (
                        <span
                          style={{
                            paddingLeft: "0px",
                            paddingRight: "4px",
                            color: "gray",
                            display: "inline-block",
                            wordBreak: "break-word",
                          }}
                        >
                          ({move.eval})
                        </span>
                      )}
                      {move.comment && showComments && (
                        <span
                          style={{
                            paddingLeft: "0px",
                            paddingRight: "8px",
                            color: "gray",
                            display: "inline-block",
                            wordBreak: "break-word",
                          }}
                        >
                          {`${move.comment}`}
                        </span>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const handleDeleteHighlights = () => {
    if (!selectedMoveId || !moves[selectedMoveId]) {
      return;
    }

    setMoves((prevMoves) => {
      if (!prevMoves[selectedMoveId]) return prevMoves;

      const updatedMoves = {
        ...prevMoves,
        [selectedMoveId]: {
          ...prevMoves[selectedMoveId],
          highlightSquares: [], // Clear all highlights
        },
      };

      return updatedMoves;
    });
  };

  const handleDeleteComments = () => {
    if (!selectedMoveId || !moves[selectedMoveId]) {
      return;
    }

    setMoves((prevMoves) => {
      if (!prevMoves[selectedMoveId]) return prevMoves;

      const updatedMoves = {
        ...prevMoves,
        [selectedMoveId]: {
          ...prevMoves[selectedMoveId],
          comment: "", // Remove comment
        },
      };

      return updatedMoves;
    });

    setComment(""); // Clear local comment state
  };

  const handleDeleteArrows = () => {
    if (!selectedMoveId || !moves[selectedMoveId]) {
      return;
    }

    setMoves((prevMoves) => {
      if (!prevMoves[selectedMoveId]) return prevMoves;

      const updatedMoves = {
        ...prevMoves,
        [selectedMoveId]: {
          ...prevMoves[selectedMoveId],
          arrows: [], // Clear all arrows
        },
      };

      return updatedMoves;
    });
  };

  const handleDeleteAnnotation = () => {
    if (!selectedMoveId || !moves[selectedMoveId]) {
      return;
    }

    setMoves((prevMoves) => {
      if (!prevMoves[selectedMoveId]) return prevMoves;

      const updatedMoves = {
        ...prevMoves,
        [selectedMoveId]: {
          ...prevMoves[selectedMoveId],
          annotations: null,
        },
      };

      return updatedMoves;
    });

    setAnnotation("");
  };

  const handleDeleteMove = () => {
    if (!selectedMoveId || !moves[selectedMoveId]) {
      return;
    }

    const parentMoveId = moves[selectedMoveId].parent || null;
    const selectedMove = moves[selectedMoveId];

    const hasVariations =
      selectedMove.variations && selectedMove.variations.length > 0;
    const newMainMoveId = hasVariations ? selectedMove.variations[0] : null; // First variation becomes new mainline

    setMoves((prevMoves) => {
      const updatedMoves = { ...prevMoves };

      if (parentMoveId && updatedMoves[parentMoveId]?.next) {
        const parentNextId = updatedMoves[parentMoveId].next;

        if (updatedMoves[parentNextId]?.variations) {
          updatedMoves[parentNextId].variations = updatedMoves[
            parentNextId
          ].variations.filter((id) => id !== selectedMoveId);
        }
      }

      // ✅ If deleting from the mainline, update parent's next
      if (parentMoveId && updatedMoves[parentMoveId]?.next === selectedMoveId) {
        updatedMoves[parentMoveId] = {
          ...updatedMoves[parentMoveId],
          next: hasVariations ? newMainMoveId : null, // Promote first variation or set null
        };
      }

      // ✅ Promote first variation while keeping its variations intact
      if (hasVariations && newMainMoveId) {
        updatedMoves[newMainMoveId] = {
          ...updatedMoves[newMainMoveId],
          variations: selectedMove.variations.filter(
            (id) => id !== newMainMoveId
          ), // Keep remaining variations
          parent: parentMoveId, // Ensure correct parent
        };
      }

      // ✅ Remove the deleted move from `selectedMove.parent.next.variations`
      if (parentMoveId && updatedMoves[parentMoveId]?.next) {
        const parentNextId = updatedMoves[parentMoveId].next;
        if (updatedMoves[parentNextId]?.variations) {
          updatedMoves[parentNextId] = {
            ...updatedMoves[parentNextId],
            variations: updatedMoves[parentNextId].variations.filter(
              (id) => id !== selectedMoveId
            ),
          };
        }
      }

      // ✅ Delete `next` and its variations recursively (BUT NOT `selectedMove.variations`)
      const moveIdsToDelete = new Set();
      const collectMoveIdsToDelete = (moveId) => {
        if (!moveId || moveIdsToDelete.has(moveId) || !updatedMoves[moveId])
          return;
        moveIdsToDelete.add(moveId);

        const move = updatedMoves[moveId];
        if (move.next) collectMoveIdsToDelete(move.next); // ✅ Delete next moves
        if (move.variations) move.variations.forEach(collectMoveIdsToDelete); // ✅ Delete variations of next (NOT selectedMove.variations)
      };

      // ✅ Start deleting from `selectedMove.next`
      if (selectedMove.next) collectMoveIdsToDelete(selectedMove.next);

      // ✅ Finally, delete the selected move itself
      moveIdsToDelete.add(selectedMoveId);

      // ✅ Delete collected moves
      moveIdsToDelete.forEach((moveId) => {
        delete updatedMoves[moveId];
      });

      // ✅ Select correct move after deletion
      let newSelectedMove = hasVariations ? newMainMoveId : parentMoveId;
      setSelectedMoveId(newSelectedMove);

      return updatedMoves;
    });
  };

  const handlePromoteVariation = () => {
    if (!selectedMoveId || !moves[selectedMoveId]) {
      return;
    }

    const parentMoveId = moves[selectedMoveId].parent;
    if (!parentMoveId || !moves[parentMoveId]) {
      return;
    }

    const parentMove = moves[parentMoveId];
    const nextMoveId = parentMove.next;
    if (
      !nextMoveId ||
      !moves[nextMoveId] ||
      !Array.isArray(moves[nextMoveId].variations)
    ) {
      return;
    }

    const variations = moves[nextMoveId].variations;

    const index = variations.indexOf(selectedMoveId);
    if (index === -1) {
      return;
    }

    setMoves((prevMoves) => {
      const updatedMoves = { ...prevMoves };

      if (index === 0) {
        const selectedMove = { ...updatedMoves[selectedMoveId] };
        const previousMainMove = { ...updatedMoves[nextMoveId] };

        // Step 1: Move selectedMove into the main line by updating parent's `next`
        updatedMoves[parentMoveId] = {
          ...updatedMoves[parentMoveId],
          next: selectedMoveId, // Set selected move as the new main move
        };

        // Step 2: Copy variations from previousMainMove to selectedMove
        updatedMoves[selectedMoveId] = {
          ...selectedMove,
          variations: previousMainMove.variations.map((id) =>
            id === selectedMoveId ? nextMoveId : id
          ), // Ensure we replace selectedMoveId with previousMainMoveId in variations
        };

        // Step 3: Remove variations from previousMainMove
        updatedMoves[nextMoveId] = {
          ...previousMainMove,
          variations: [], // Clear variations
        };
      } else {
        // Case 2: Swap with the previous variation
        const newVariations = [...variations];
        [newVariations[index - 1], newVariations[index]] = [
          newVariations[index],
          newVariations[index - 1],
        ];

        updatedMoves[nextMoveId] = {
          ...updatedMoves[nextMoveId],
          variations: newVariations,
        };
      }

      return updatedMoves;
    });
  };

  const handleMoveMenuClose = () => {
    setMoveMenuAnchor(null); // Clear the anchor element, effectively closing the menu
  };

  useEffect(() => {
    if (selectedMoveRef.current) {
      selectedMoveRef.current.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
      });
    }
  }, [selectedMoveId]);

  // Sync comment and annotation with the selected move
  useEffect(() => {
    if (selectedMoveId && !moves[selectedMoveId]) {
      setComment(""); // Clear comment
      setAnnotation(""); // Clear annotation
    } else if (selectedMoveId) {
      setComment(moves[selectedMoveId].comment || "");
      setAnnotation(moves[selectedMoveId].annotations || "");
    }
  }, [selectedMoveId, moves]);

  return (
    <Card
      id="games-movescard"
      sx={{
        position: "relative",
        backgroundColor: colors.background[100], // Card background color
        color: colors.black[900], // Text color
        backgroundImage: "none",
        clipPath:
          "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
        "::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "transparent",
          border: "1px solid rgba(0, 0, 0, 0.2)",
          clipPath:
            "polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)",
          zIndex: -1,
          pointerEvents: "none",
        },
        p: 1,
      }}
    >
      <CardHeader
        avatar={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <KeyboardOptionKeyIcon />
          </Box>
        }
        sx={{ pt: 1, pb: 1 }}
        title={
          <Typography variant="h7" sx={{ fontWeight: "bold" }}>
            Game{" "}
            <span
              style={{ color: "gray", fontSize: "16px", fontWeight: "normal" }}
            >
              ({Object.keys(moves).length - 1} Moves)
            </span>
          </Typography>
        }
        action={
          <Box id="games-movescard-menu">
            <IconButton onClick={handleLinesMenuOpen}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={linesMenuAnchor}
              open={Boolean(linesMenuAnchor)}
              onClose={handleLinesMenuClose}
            >
              <MenuItem onClick={() => handleDisplayMoveFormatChange("tree")}>
                Display as Tree{" "}
                {userGames?.find((g) => g.id === selectedUserGameId)
                  .game_analysed && <>(Highlight Level)</>}
                {displayMovesFormat === "tree" && (
                  <DoneIcon sx={{ ml: 1, fontSize: "14px" }} />
                )}
              </MenuItem>
              {userGames?.find((g) => g.id === selectedUserGameId)
                .game_analysed && (
                <MenuItem
                  onClick={() => handleDisplayMoveFormatChange("treeEngine")}
                >
                  Display as Tree (Highlight Eval)
                  {displayMovesFormat === "treeEngine" && (
                    <DoneIcon sx={{ ml: 1, fontSize: "14px" }} />
                  )}
                </MenuItem>
              )}

              <MenuItem onClick={() => handleDisplayMoveFormatChange("pgn")}>
                Display as PGN
                {displayMovesFormat === "pgn" && (
                  <DoneIcon sx={{ ml: 1, fontSize: "14px" }} />
                )}
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleShowCommentsChange}>
                {showComments ? "Hide Comments" : "Show Comments"}
              </MenuItem>
              <MenuItem onClick={handleShowAnnotationChange}>
                {showAnnotations ? "Hide Annotations" : "Show Annotations"}
              </MenuItem>

              {userGames?.find((g) => g.id === selectedUserGameId)
                .game_analysed && (
                <MenuItem onClick={handleShowEvaluationsChange}>
                  {showEvaluations ? "Hide Evaluations" : "Show Evaluations"}
                </MenuItem>
              )}
            </Menu>
          </Box>
        }
      />

      <Divider sx={{ backgroundColor: colors.green[100], height: "1.5px" }} />

      <CardContent sx={{ padding: "16px !important" }}>
        <Box sx={{ mt: 1 }}>
          {displayMovesFormat === "pgn"
            ? displayMoveLinesPGN()
            : displayMovesFormat === "treeEngine"
            ? displayMoveLinesTreeEngine()
            : displayMoveLinesTree()}
        </Box>

        <Menu
          anchorEl={moveMenuAnchor}
          open={Boolean(moveMenuAnchor)}
          onClose={handleMoveMenuClose}
        >
          <MenuItem
            onClick={() => {
              handleDeleteMove();
              handleMoveMenuClose();
            }}
          >
            Delete Move
          </MenuItem>
          <Divider
            sx={{
              margin: 0, // Set margin to 0
              padding: 0, // Set padding to 0
              "&.MuiDivider-root": {
                margin: 0, // Ensure root styles are also overridden
                padding: 0, // Ensure padding is overridden
              },
            }}
          />
          <MenuItem
            onClick={() => {
              handlePromoteVariation();
              handleMoveMenuClose();
            }}
          >
            Promote Variation
          </MenuItem>

          <Divider
            sx={{
              margin: 0, // Set margin to 0
              padding: 0, // Set padding to 0
              "&.MuiDivider-root": {
                margin: 0, // Ensure root styles are also overridden
                padding: 0, // Ensure padding is overridden
              },
            }}
          />
          <MenuItem
            onClick={() => {
              handleDeleteArrows();
              handleMoveMenuClose();
            }}
          >
            Delete Arrows
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleDeleteAnnotation();
              handleMoveMenuClose();
            }}
          >
            Delete Annotation
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleDeleteComments();
              handleMoveMenuClose();
            }}
          >
            Delete Comments
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleDeleteHighlights();
              handleMoveMenuClose();
            }}
          >
            Delete Highlights
          </MenuItem>
        </Menu>
        <Divider sx={{ mt: 2 }} />
        <Box sx={{ width: "100%" }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="custom styled tabs"
            sx={{
              height: "20px",
              "& .MuiTab-root": {
                color: colors.black[900], // Text color for unselected tabs
                transition: "color 0.3s, border-color 0.3s", // Smooth transition for hover/focus effects
                minWidth: "50px", // Set tab width
                textAlign: "center", // Center align text or icon
                "&:hover": {
                  color: colors.black[700], // Slightly lighter on hover
                },
              },
              "& .Mui-selected": {
                color: colors.black[900], // ✅ Fix: Sets active tab text color
                fontWeight: "bold", // ✅ Optional: Make selected text bold
              },
              "& .MuiTab-root.Mui-selected": {
                color: colors.black[900], // ✅ Ensure selected tab text color overrides MUI default
              },
              "& .MuiTabs-indicator": {
                backgroundColor: colors.green[900], // ✅ Active tab underline color
                height: "1.5px",
              },
              mb: 1,
              mt: 1,
            }}
          >
            <Tab
              id="games-movescard-comment"
              icon={
                <Box
                  sx={{
                    width: "20px", // Set desired width
                    height: "20px", // Set desired height
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CommentIcon />
                </Box>
              }
              aria-label="Comment"
              sx={{
                fontSize: "10px", // Adjust font size for the icon label
              }}
            />
            <Tab
              id="games-movescard-annotation"
              label="!?"
              sx={{
                fontSize: "22px", // Larger font size for text

                textTransform: "none", // Prevent uppercase transformation
              }}
            />
            <Tooltip title="View Openings">
              <Tab
                id="games-movescard-opening"
                icon={
                  <Box
                    sx={{
                      width: "20px", // Set desired width
                      height: "20px", // Set desired height
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <LocalLibraryOutlinedIcon />
                  </Box>
                }
                aria-label="Analysis"
                sx={{
                  fontSize: "10px", // Adjust font size for the icon label
                }}
              />
            </Tooltip>
            <Tab
              id="games-movescard-analysis"
              icon={
                <Box
                  sx={{
                    width: "20px", // Set desired width
                    height: "20px", // Set desired height
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TroubleshootSharpIcon />
                </Box>
              }
              aria-label="Analysis"
              sx={{
                fontSize: "10px", // Adjust font size for the icon label
              }}
            />
            <Tooltip title="Export PGN">
              <Tab
                id="games-movescard-export"
                icon={
                  <Box
                    sx={{
                      width: "20px", // Set desired width
                      height: "20px", // Set desired height
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <DownloadSharpIcon />
                  </Box>
                }
                aria-label="Analysis"
                sx={{
                  fontSize: "10px", // Adjust font size for the icon label
                }}
              />
            </Tooltip>
          </Tabs>

          {/* Comment Tab Panels */}
          <Box role="tabpanel" hidden={tabValue !== 0}>
            {tabValue === 0 && (
              <Box sx={{ width: "100%", pt: 1 }}>
                {selectedMoveId === "root" ? (
                  <Typography
                    sx={{
                      color: "gray", // Text color for the message
                      fontSize: "16px", // Font size for the message
                      textAlign: "center", // Center-align the message
                      width: "100%", // Take the full width of the panel
                      margin: "20px 0", // Add some vertical spacing
                    }}
                  >
                    Please make a move before adding comments.
                  </Typography>
                ) : (
                  <TextField
                    value={comment}
                    onChange={handleCommentChange}
                    multiline
                    rows={4} // Set the number of visible rows
                    placeholder="Add comments..."
                    variant="filled"
                    fullWidth
                    sx={{
                      pt: 0,
                      "& .MuiFilledInput-root": {
                        paddingTop: "8px", // Adjust as needed
                        paddingBottom: "8px",
                      },
                      "& .MuiInputBase-inputMultiline": {
                        paddingTop: "0px !important", // Override excessive padding inside input
                        paddingBottom: "0px !important",
                      },
                    }}
                  />
                )}
              </Box>
            )}
          </Box>
          {/* Annotate Tab Panel */}
          <Box role="tabpanel" hidden={tabValue !== 1}>
            {tabValue === 1 && (
              <Box sx={{ display: "flex", gap: 0, width: "100%", pt: 1 }}>
                {selectedMoveId === "root" ? (
                  <Typography
                    sx={{
                      color: "gray", // Text color for the message
                      fontSize: "16px", // Font size for the message
                      textAlign: "center", // Center-align the message
                      width: "100%", // Take the full width of the panel
                      margin: "20px 0", // Add some vertical spacing
                    }}
                  >
                    Please make a move before adding annotations.
                  </Typography>
                ) : (
                  Object.values(categories).map((category, colIndex) => (
                    <Box key={colIndex} sx={{ flex: 1 }}>
                      {category.map((item, index) => (
                        <Button
                          key={index}
                          fullWidth
                          variant="menu"
                          sx={{
                            justifyContent: "flex-start",
                            textTransform: "none", // Prevent text from transforming to uppercase
                            p: 0,
                            height: "30px", // Adjust button height
                            alignItems: "stretch", // Stretch content to fill height
                            backgroundColor: colors.background[200], // Highlight in blue if matched
                          }}
                          onClick={() => handleAnnotationChange(item.symbol)}
                        >
                          <Box
                            sx={{
                              backgroundColor:
                                annotation === item.symbol
                                  ? getCategoryColorBySymbol(item.symbol)
                                  : colors.background[100], // Highlight in blue if matched
                              color:
                                annotation === item.symbol
                                  ? "white"
                                  : colors.black[900], // White text on blue background
                              fontSize: "14px",
                              fontWeight: "bold",
                              width: "30px", // Fixed width for the symbol area
                              display: "flex",
                              alignItems: "center", // Center the symbol vertically
                              justifyContent: "center", // Center the symbol horizontally
                            }}
                          >
                            {item.symbol}
                          </Box>
                          <Typography
                            sx={{
                              color:
                                annotation === item.symbol
                                  ? getCategoryColorBySymbol(item.symbol)
                                  : colors.black[900], // Highlight in blue if matched
                              fontSize: "10px", // Reduced font size for description
                              paddingLeft: "8px", // Add spacing between symbol and description
                              display: "flex",
                              alignItems: "center", // Align text vertically
                            }}
                          >
                            {item.description}
                          </Typography>
                        </Button>
                      ))}
                    </Box>
                  ))
                )}
              </Box>
            )}
          </Box>

          <Box role="tabpanel" hidden={tabValue !== 2}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                pb: 1,
                pt: 1,
              }}
            >
              <OpeningCard moves={moves} selectedMoveId={selectedMoveId} />
            </Box>
          </Box>

          <Box role="tabpanel" hidden={tabValue !== 3}>
            {(() => {
              const selectedGame = userGames.find(
                (g) => g.id === selectedUserGameId
              );

              return (
                <>
                  {!selectedGame?.game_analysed && (
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px", // Add some spacing below the text
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            marginRight: "5px",
                          }}
                        >
                          Stockfish running at depth{" "}
                        </Typography>
                        <Box
                          sx={{
                            display: "inline-block",
                            cursor: "pointer",
                            borderBottom: `1px dashed ${colors.black[300]}`, // Indicate editable text
                          }}
                        >
                          <input
                            type="number"
                            value={depth}
                            onChange={(e) => setDepth(Number(e.target.value))}
                            style={{
                              background: "transparent",
                              border: "none",
                              textAlign: "center",
                              width: "40px",
                              outline: "none",
                              color: colors.black[900],
                            }}
                          />
                        </Box>
                      </Box>
                      <Button
                        variant="contained"
                        sx={{
                          color: "white",
                          backgroundColor: colors.green[100],
                          "&:hover": {
                            backgroundColor: colors.green[200],
                          },
                          textTransform: "none",
                        }}
                        onClick={() => {
                          runUserGameAnalysis(
                            selectedUserGameId,
                            tasksRef,
                            setTasks,
                            userGames,
                            setUserGames,
                            userData,
                            depth
                          );
                        }}
                      >
                        <TroubleshootSharpIcon
                          sx={{ width: "30px", p: 0, mr: 1 }}
                        />
                        Analyze Game
                      </Button>
                    </Box>
                  )}

                  {selectedGame?.game_analysed && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      {/* Line Chart at the Top */}
                      <MyGamesAnalysisLineChart moves={moves} />

                      {/* Button and Last Analyzed Timestamp in a Row */}
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between", // Moves second Box to the right
                          mt: 1,
                          width: "100%", // Ensures full width usage
                        }}
                      >
                        {/* Left-aligned Box */}
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            variant="body2"
                            sx={{
                              marginRight: "5px",
                            }}
                          >
                            Stockfish depth{" "}
                          </Typography>
                          <Box
                            sx={{
                              display: "inline-block",
                              cursor: "pointer",
                              borderBottom: `1px dashed ${colors.black[300]}`, // Indicate editable text
                            }}
                          >
                            <input
                              type="number"
                              value={depth}
                              onChange={(e) => setDepth(Number(e.target.value))}
                              style={{
                                background: "transparent",
                                border: "none",
                                textAlign: "center",
                                width: "40px",
                                outline: "none",
                                color: colors.black[900],
                              }}
                            />
                          </Box>
                          <Tooltip title="Reanalyze Game">
                            <IconButton
                              color="primary"
                              onClick={() => {
                                runUserGameAnalysis(
                                  selectedUserGameId,
                                  tasksRef,
                                  setTasks,
                                  userGames,
                                  setUserGames,
                                  userData
                                );
                              }}
                              sx={{ mr: 0 }}
                            >
                              <ReplaySharpIcon sx={{ color: "grey" }} />
                            </IconButton>
                          </Tooltip>
                        </Box>

                        <Typography variant="body2" sx={{ color: "grey" }}>
                          Last analyzed:{" "}
                          {new Date(
                            selectedGame.game_analysed_datetime
                          ).toLocaleDateString()}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </>
              );
            })()}
          </Box>

          <Box role="tabpanel" hidden={tabValue !== 4}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                pb: 1,
                pt: 1,
              }}
            >
              <PGNCard moves={moves} />
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default MovesCardMyGames;
